import React from 'react';
import autoBind from 'react-autobind';

import * as Components from '../Components.js';
//import * as Generation from '../Generation.js';
import * as Helpers from '../Helpers.js';

//import {DateObject} from "react-multi-date-picker";

import SimpleMdeReact from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";


//import the Darwin API classes
import API from '../API.js';

import ImageClose from '../images/Icons/Close.svg'
import ImageTrash from '../images/Icons/Trash.svg'
import ImageMoveUp from '../images/Icons/MoveUp.svg'
import ImageMoveDown from '../images/Icons/MoveDown.svg'

import ImageToolbar from '../images/Icons/Toolbar.svg'
import ImageHeader from '../images/Icons/Header.svg'
import ImageText from '../images/Icons/Text.svg'
import ImageImage from '../images/Icons/Image.svg'
import ImageVideo from '../images/Icons/Video.svg'

// import ImageProfile from '../images/Icons/UserGray.svg'
// import ImageLoading from '../images/Icons/loading.svg'
//import ImageCheckmark from '../images/Icons/Checkmark.svg'
//import ImageUncheckmark from '../images/Icons/Uncheckmark.svg'

export class ManageBlog extends React.Component {

  constructor(props) {
    super(props)

    let data = {}

    this.state = {
      loading: false,
      data: data,
      forceCheck: false,
      shakeButton: false,
      changesMade: false, //have any changes been made

      view: "Home",
      subview: "Home",
      prompt: false,

      articles: [],
      filter: "All", //the filter to apply
      sort: "Published", //the sort to apply
      sortDirection: "DESC", //DESC or ASC
      limit: 30, //the number of results per page.
      page: 0, //the page of results.
      pageMax: 0, //the max page.
      search: "", //the search parameter.
      resultsCount: 0, //the number of results.
    }
    autoBind(this)
  }

  componentDidMount() {
    //calculate the view
    this.calculateView()
  }

  componentDidUpdate(prevProps) {
    if (this.props.render !== prevProps.render) {
      //reload the home view.
      this.calculateView()
    }
  }

  calculateView() {
    //set the data
    if (this.state.view === "Home") {
      //load the abilities for this user.
      this.getArticles()
    }
  }

  /*
  * Submits the form
  */
  submitForm() {
    let results = this.checkForm(true)
    if (results.valid) {
      switch (this.state.view) {
        case "Home":
          this.changeSearch(results.data)
          break;
        default:
          console.error("Unhandled submitForm", this.state.view)
          break;
      }
    } else {
      console.log("Form not valid to submit")
      this.setState({
        forceCheck: true
      })
      //Shake the submit button as it is invalid to submit
      this.shakeTheButton()
    }
  }

  /*
  Checks the form to make sure it is valid.
  Returns {valid:Bool, data:{}}
  */
  checkForm(print = false) {
    //1) Make sure we have all of the data.
    let valid = true
    let requiredFields = []
    let requiredIf = []
    let requiredSometimes = [] //if set, then don't remove it from the data.
    let optionals = [] //the optional fields apart from the requiredFields.
    switch (this.state.view) {
      case "Home":
        requiredFields = []
        optionals = ["search"]
        requiredIf = []
        requiredSometimes = []
        break;
      default:
        break;
    }
    requiredFields.forEach((element) => {
      if (this.state.data[element] === undefined || this.state.data[element].value === undefined) {
        //the field is not filled out
        if (print) {
          console.log("required not filled out: ", element)
        }
        valid = false
      }
    })
    requiredIf.forEach((condition) => {
      if (this.state.data[condition.field] === undefined || this.state.data[condition.field].value === undefined) {
        //the condition field is not filled out
        if (print) {
          console.log("condition field is not filled out: ", condition)
        }
        valid = false
      } else if (condition.values !== undefined && condition.values.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          if (print) {
            console.log("rif field is required and is not filled out: ", condition)
          }
          valid = false
        }
      } else if (condition.not !== undefined && !condition.not.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          if (print) {
            console.log("rif field is required with not and is not filled out: ", condition)
          }
          valid = false
        }
      }
    })
    //2) Make sure every data element is valid.
    for (let key in this.state.data) {
      let forget = false
      //Make sure it is not a requiredSometimes
      if (!requiredSometimes.includes(key)) {
        // Check the requiredIf conditions
        for (let i = 0; i < requiredIf.length; i = i + 1) {
          let rif = requiredIf[i]
          if (rif.require === key) {
            if (!rif.values.includes(this.state.data[rif.field].value)) {
              if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                forget = true
              }
            }
            break
          }
        }
      }
      //Make sure this is a field we are looking for.
      if (optionals.includes(key) || requiredFields.includes(key)) {
        if (!forget && this.state.data[key].valid === false) {
          if (print) {
            console.log("data not valid", key)
          }
          valid = false
        }
      }
    }

    //3) If valid, return the data, else force check the fields for display.
    if (valid) {
      let data = {}
      for (let key in this.state.data) {
        let forget = false
        //Make sure it is not a requiredSometimes
        if (!requiredSometimes.includes(key)) {
          for (let i = 0; i < requiredIf.length; i = i + 1) {
            let rif = requiredIf[i]
            if (rif.require === key) {
              if (!rif.values.includes(this.state.data[rif.field].value)) {
                if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                  forget = true
                }
              }
              break
            }
          }
        }
        if (!forget) {
          if (optionals.includes(key) || requiredFields.includes(key)) {
            if (this.state.data[key].value !== false && this.state.data[key].value.toString().length > 0) {
              data[key] = this.state.data[key].value
              if (typeof data[key] === 'string') {
                data[key] = data[key].trim()
              }
            }
          }
        }
      }
      return {
        valid: true,
        data: data
      }
    } else {
      return {
        valid: false,
        data: "Form not valid to submit"
      }
    }
  }

  /*
  * Called when data in an input form changes.
  * This will update the state.data param with the
  * name and new value of the form element.
  */
  formChanged(name, value, valid) {
    //console.log(name, value, valid);
    this.setState((prevState) => {
      let d = prevState.data
      d[name] = {
        value: value,
        valid: valid
      }
      if (prevState.view === "Create" && name === "type") {
        let defaults = Helpers.getScrapingConfigs(value)
        //select the first default.
        let config = defaults[0]
        for (let key in config) {
          d[key] = {
            value: config[key],
            valid: true
          }
        }
      } else if (prevState.view === "Create" && name === "config") {
        //get the config
        let defaults = Helpers.getScrapingConfigs(d["type"]["value"])
        let config = false
        for (let i = 0; i < defaults.length; i = i + 1) {
          if (defaults[i]["config"] === value) {
            config = defaults[i]
            break
          }
        }
        if (config !== false) {
          for (let key in config) {
            d[key] = {
              value: config[key],
              valid: true
            }
          }
        }
      }
      return {
        data: d,
        forceCheck: false,
        changesMade: true,
      }
    }, () => {
      //check to see if the form is valid to submit
      let res = this.checkForm()
      this.setState({
        valid: res.valid
      })
    })
  }

  /*
  Shakes the button and then removes the class.
  */
  shakeTheButton() {
    this.setState({
      shakeButton: true
    }, () => {
      setTimeout(() => {
        this.setState({
          shakeButton: false
        })
      }, 1000)
    })
  }

  /*
  We have selected an article.
  */
  articleSelected(articleID, clickType, event) {
    //go to this web scraping's page.
    if (clickType === "rightclick") {
      //open a new tab
      event.preventDefault()
      console.log(articleID, clickType, event)
      window.open("/article/" + articleID, "_blank", "noreferrer")
    } else {
      //go to this article's page.
      this.props.changeView("ManageBlogArticle", articleID)
    }
  }

  /*
  Changes the filter that is applied to the requests.
  */
  changeFilter(newFilter) {
    this.setState({
      filter: newFilter,
      page: 0,
    }, () => {
      this.getArticles()
    })
  }

  /*
  Changes the page of results we are viewing.
  */
  changePage(newPage) {
    this.setState({
      page: newPage
    }, () => {
      this.getArticles()
    })
  }

  /*
  Changes the sort that is applied to the requests.
  */
  changeSort(newSort) {
    if (!["Title", "Author", "Created", "Published"].includes(newSort)) {
      console.log("No Sort for ", newSort)
      return
    }
    this.setState((oldState) => {
      let newSortDirection = "DESC"
      if (oldState.sort === newSort) {
        //switch the direction
        newSortDirection = oldState.sortDirection
        if (newSortDirection === "DESC") {
          newSortDirection = "ASC"
        } else {
          newSortDirection = "DESC"
        }
      }
      return {
        sort: newSort,
        sortDirection: newSortDirection,
        page: 0
      }
    }, () => {
      this.getArticles()
    })
  }

  /*
  Changes the search that is applied to the requests.
  */
  changeSearch(data) {
    console.log(data)
    this.setState(() => {
      return {
        search: data.search || "",
        page: 0
      }
    }, () => {
      this.getArticles()
    })
  }

  /*
  Get the list of articles.
  */
  getArticles() {
    this.setState({
      loading: true
    }, () => {

      let data = {
        filter: this.state.filter,
        sort: this.state.sort,
        sortDirection: this.state.sortDirection,
        limit: this.state.limit,
        page: this.state.page,
        //positiveBalance: this.state.positiveBalance === true ? 1 : 0,
      }
      if (this.state.search.length > 0) {
        data.search = this.state.search
      }
      console.log("search data", data)

      API.callDarwinAPI("GET", "articles", data, async (result) => {
        console.log("articles", result)
        if ("error" in result) {
          //Change the view to 404
          this.props.showPopup("Error", "Couldn't get the articles", "Please try again. If this continues to occur, reach out to support@geoji.com")
          this.setState({
            loading: false
          })
          return
        }
        let pageMax = Math.ceil((result.data.totalCount * 1.0) / this.state.limit) - 1

        //format the articles
        let uu = result.data.articles
        for (let i = 0; i < uu.length; i = i + 1) {
          uu[i].creationDate = Helpers.formatDateObjectShort(uu[i].creationTimestamp)
          uu[i].publishedDate = (uu[i].publishedTimestamp && uu[i].publishedTimestamp.length > 0) ? Helpers.formatDateObjectShort(uu[i].publishedTimestamp) : "Draft"
        }
        this.setState({
          loading: false,
          articles: uu,
          pageMax: pageMax,
          resultsCount: result.data.totalCount,
        })
      })
    })
  }

  /*
  Changes the toggle for one of the filters and the re-searches for users.
  */
  onToggle(key) {
    this.setState((old) => {
      let obj = {}
      obj[key] = !old[key]
      console.log("obj", obj)
      if (key === "positiveBalance") {
        if (obj[key] === false) {
          //revert sort to the name field.
          obj.sort = "Title"
          obj.sortDirection = "ASC"
        } /*else {
          obj.sort = "Balance"
          obj.sortDirection = "DESC"
        }*/
      }
      return obj
    }, () => {
      this.getArticles()
    })
  }

  /*
  Creates a brand new unpublished article for us.
  */
  createArticle() {
    this.setState({
      loading: true
    }, () => {
      let data = {}
      API.callDarwinAPI("POST", "article", data, async (result) => {
        //console.log("article/" + this.props.tertiaryView, result)
        if ("error" in result) {
          //Change the view to 404
          this.props.showPopup("Error", "Couldn't create the article", "Please try again. If this continues to occur, reach out to support@geoji.com")
          this.setState({
            loading: false
          })
          return
        }
        //reload the articles.
        this.getArticles()
      })
    })
  }

  render() {

    return (
      <div className="WebScrapings">
        {/* Manage Users */}
        { this.state.view === "Home" && this.props.userInfo && this.props.userInfo.user &&
          <div className="FormBlock">
            <div className="WebScrapingsTitle">
              Blog Articles
            </div>
            { this.state.loading &&
              <div className="FormLoader" style={{height:"168px"}}>
                <Components.LoadingIndicator color="dark" />
              </div>
            }
            { !this.state.loading &&
              <div className="WebScrapingsContent">
                <div className="InputDiv">
                  <Components.InputBottomLine type="text" name="search" placeholder="" validation="text" required="false"
                    title="Search by name or author" maximum={100}
                    value={this.state.data.search ? this.state.data.search.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                  <Components.GeojiButton type="Dark" onClick={this.createArticle} style={{"margin": "12px"}}>
                    New Article
                  </Components.GeojiButton>
                </div>
                {/* Filters, not in use right now. */}
                {/*<div className="WebScrapingsFilters">
                  <Components.SettingsToggle name="Only Creators" value={this.state.onlyCreators === true} onToggle={this.onToggle.bind(this, "onlyCreators")} />
                  <Components.SettingsToggle name="Positive Balance" value={this.state.positiveBalance === true} onToggle={this.onToggle.bind(this, "positiveBalance")} />
                  <Components.SettingsToggle name="Contacts" value={this.state.contacts === true} onToggle={this.onToggle.bind(this, "contacts")} />
                </div>*/}
                <Components.Table
                  tableInfo={Helpers.getManageBlogArticlesTableInfo(this.state.articles)}
                  noData={Helpers.getText("emptyArticles")}
                  data={this.state.articles}
                  onClick={this.articleSelected}
                  onClickKey={"id"}
                  onHeaderClick={this.changeSort}
                  sort={this.state.sort}
                  sortDirection={this.state.sortDirection}
                  >
                  <div className="TableTop">
                    <div className="TableTopDate">
                      <Components.PageIndicator page={this.state.page} pageMax={this.state.pageMax} pageSelected={this.changePage} count={this.state.resultsCount} />
                    </div>
                  </div>
                </Components.Table>
              </div>
            }
          </div>
        }
      </div>
    )
  }
}

export class ManageBlogArticle extends React.Component {

  constructor(props) {
    super(props)

    let data = {}
    console.log(this.props)

    this.state = {
      loading: false,
      data: data,
      forceCheck: false,
      shakeButton: false,
      changesMade: false, //have any changes been made

      view: "Home",
      subview: "Home",
      prompt: false,

      article: false,
      saving: false,
      blocks: [],
      showToolBars: false,
    }

    this.mdeOptions = {
      toolbar: ["bold", "italic", "|", "link", "|", "preview", "side-by-side", "fullscreen", "|", "undo", "redo"]
    }

    autoBind(this)
  }

  componentDidMount() {
    //calculate the view
    this.calculateView()
  }

  componentDidUpdate(prevProps) {
    if (this.props.render !== prevProps.render) {
      //reload the home view.
      this.calculateView()
    }
  }

  calculateView() {
    //set the data
    if (this.state.view === "Home") {
      //load the abilities for this user.
      this.getArticle()
    }
  }

  /*
  * Submits the form
  */
  submitForm() {
    let results = this.checkForm(true)
    if (results.valid) {
      switch (this.state.view) {
        case "Home":
          this.saveArticleSettings(results.data)
          break;
        default:
          console.error("Unhandled submitForm", this.state.view)
          break;
      }
    } else {
      console.log("Form not valid to submit")
      this.setState({
        forceCheck: true
      })
      //Shake the submit button as it is invalid to submit
      this.shakeTheButton()
    }
  }

  /*
  Checks the form to make sure it is valid.
  Returns {valid:Bool, data:{}}
  */
  checkForm(print = false) {
    //1) Make sure we have all of the data.
    let valid = true
    let requiredFields = []
    let requiredIf = []
    let requiredSometimes = [] //if set, then don't remove it from the data.
    let optionals = [] //the optional fields apart from the requiredFields.
    switch (this.state.view) {
      case "Home":
        requiredFields = ["title", "url", "subtitle"]
        optionals = ["imageURL"]
        requiredIf = []
        requiredSometimes = []
        break;
      default:
        break;
    }
    requiredFields.forEach((element) => {
      if (this.state.data[element] === undefined || this.state.data[element].value === undefined) {
        //the field is not filled out
        if (print) {
          console.log("required not filled out: ", element)
        }
        valid = false
      }
    })
    requiredIf.forEach((condition) => {
      if (this.state.data[condition.field] === undefined || this.state.data[condition.field].value === undefined) {
        //the condition field is not filled out
        if (print) {
          console.log("condition field is not filled out: ", condition)
        }
        valid = false
      } else if (condition.values !== undefined && condition.values.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          if (print) {
            console.log("rif field is required and is not filled out: ", condition)
          }
          valid = false
        }
      } else if (condition.not !== undefined && !condition.not.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          if (print) {
            console.log("rif field is required with not and is not filled out: ", condition)
          }
          valid = false
        }
      }
    })
    //2) Make sure every data element is valid.
    for (let key in this.state.data) {
      let forget = false
      //Make sure it is not a requiredSometimes
      if (!requiredSometimes.includes(key)) {
        // Check the requiredIf conditions
        for (let i = 0; i < requiredIf.length; i = i + 1) {
          let rif = requiredIf[i]
          if (rif.require === key) {
            if (!rif.values.includes(this.state.data[rif.field].value)) {
              if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                forget = true
              }
            }
            break
          }
        }
      }
      //Make sure this is a field we are looking for.
      if (optionals.includes(key) || requiredFields.includes(key)) {
        if (!forget && this.state.data[key].valid === false) {
          if (print) {
            console.log("data not valid", key)
          }
          valid = false
        }
      }
    }

    //3) If valid, return the data, else force check the fields for display.
    if (valid) {
      let data = {}
      for (let key in this.state.data) {
        let forget = false
        //Make sure it is not a requiredSometimes
        if (!requiredSometimes.includes(key)) {
          for (let i = 0; i < requiredIf.length; i = i + 1) {
            let rif = requiredIf[i]
            if (rif.require === key) {
              if (!rif.values.includes(this.state.data[rif.field].value)) {
                if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                  forget = true
                }
              }
              break
            }
          }
        }
        if (!forget) {
          if (optionals.includes(key) || requiredFields.includes(key)) {
            //console.log("value", this.state.data[key])
            if ((this.state.data[key].value !== false && this.state.data[key].value !== null) && this.state.data[key].value.toString().length > 0) {
              data[key] = this.state.data[key].value
              if (typeof data[key] === 'string') {
                data[key] = data[key].trim()
              }
            }
          }
        }
      }
      return {
        valid: true,
        data: data
      }
    } else {
      return {
        valid: false,
        data: "Form not valid to submit"
      }
    }
  }

  /*
  * Called when data in an input form changes.
  * This will update the state.data param with the
  * name and new value of the form element.
  */
  formChanged(name, value, valid) {
    this.setState((prevState) => {
      let d = prevState.data
      d[name] = {
        value: value,
        valid: valid
      }
      //check to see if the form is valid to submit
      return {
        data: d,
        forceCheck: false,
        changesMade: true,
      }
    }, () => {
      //check to see if the form is valid to submit
      let res = this.checkForm()
      this.setState({
        valid: res.valid
      }, () => {
        /*if (name === "title" || name === "details") {
          this.searchEmojis()
        } else if (name === "emoji") {
          this.searchLikeEmojis()
        }*/
      })
    })
  }

  /*
  Shakes the button and then removes the class.
  */
  shakeTheButton() {
    this.setState({
      shakeButton: true
    }, () => {
      setTimeout(() => {
        this.setState({
          shakeButton: false
        })
      }, 1000)
    })
  }

  /*
  Get the article.
  */
  getArticle() {
    this.setState({
      loading: true
    }, () => {
      let data = {
      }
      API.callDarwinAPI("GET", "article/" + this.props.tertiaryView, data, async (result) => {
        //console.log("article/" + this.props.tertiaryView, result)
        if ("error" in result) {
          //Change the view to 404
          this.props.showPopup("Error", "Couldn't get the article", "Please try again. If this continues to occur, reach out to support@geoji.com")
          this.setState({
            loading: false
          })
          return
        }
        console.log("article", result.data.article)
        let newArticle = result.data.article
        newArticle.creationDate = Helpers.formatDateObjectShort(newArticle.creationTimestamp)
        newArticle.publishedDate = (newArticle.publishedTimestamp && newArticle.publishedTimestamp.length > 0) ? Helpers.formatDateObjectShort(newArticle.publishedTimestamp) : "Draft"

        if (newArticle.publishedDate === "Draft") {
          newArticle.published = "0"
        } else {
          newArticle.published = "1"
        }

        //Set the data here that can be updated.
        let newData = {
          title: {
            value: result.data.article.title || "",
            valid: true
          },
          url: {
            value: result.data.article.url || "",
            valid: true
          },
          subtitle: {
            value: result.data.article.subtitle || "",
            valid: true
          },
          imageURL: {
            value: result.data.article.imageURL || "",
            valid: true
          }
        }

        let newBlocks = []
        try {
          newBlocks = JSON.parse(result.data.article.content)
        } catch (error) {
          console.log("invalid json content", error)
          //probably empty
          newBlocks = []
        }

        this.setState({
          loading: false,
          article: newArticle,
          data: newData,
          blocks: newBlocks,
        })
      })
    })
  }

  onToggle(dataName) {

    this.setState((old) => {
      let newArticle = old.article
      newArticle[dataName] = newArticle[dataName] === "1" ? "0" : "1"
      return {
        article: newArticle,
        saving: true
      }
    }, () => {
      //Now save the update in the API.
      let data = {
      }
      data[dataName] = this.state.article[dataName]
      API.callDarwinAPI("PUT", "article/" + this.props.tertiaryView, data, async (result) => {
        console.log("PUT article/" + this.props.tertiaryView, data, result)
        if ("error" in result) {
          //Change the view to 404
          this.props.showPopup("Error", "Couldn't update the article", "Please try again. If this continues to occur, reach out to support@geoji.com")
          this.setState({
            saving: false
          })
          return
        }
        this.setState({
          saving: false
        })
      })
    })
  }

  saveArticleSettings(data) {

    //console.log("save article settings", data).
    if (!data.imageURL) {
      data.imageURL = ""
    }
    //add in the blog content.
    let blocks = []
    for (let i = 0; i < this.state.blocks.length; i = i + 1) {
      if (this.state.blocks[i].content && this.state.blocks[i].content.length > 0) {
        blocks.push(this.state.blocks[i])
      }
    }
    data.content = JSON.stringify(blocks)

    this.setState({
      saving: true,
    }, () => {
      API.callDarwinAPI("PUT", "article/" + this.props.tertiaryView, data, async (result) => {
        console.log("PUT artiicle/" + this.props.tertiaryView, data, result)
        if ("error" in result) {
          //Change the view to 404
          this.props.showPopup("Error", "Couldn't update the article", "Please try again. If this continues to occur, reach out to support@geoji.com")
          this.setState({
            saving: false,
          })
          return
        }
        this.setState({
          saving: false,
        }, () => {
          this.getArticle()
        })
      })
    })
  }

  viewArticleLink() {
    //opens the article in the browser.
    let link = "https://geoji.com/blog/" + this.state.article.url
    window.open(link, "_blank").focus()
  }

  copyToClipboard(data) {
    Helpers.copyToClipboard(data)
  }

  deleteArticle() {
    //confirm with a prompt.
    this.props.showConfirmation("Are you sure you want to delete this article?", "This action cannot be undone.", "Delete", "Cancel", (shouldDelete) => {
      if (shouldDelete) {
        //Call the API to delete the article, then go back.
        this.setState({
          loading: true
        }, () => {
          let data = {}
          API.callDarwinAPI("DELETE", "article/" + this.props.tertiaryView, data, async (result) => {
            //console.log("article/" + this.props.tertiaryView, result)
            if ("error" in result) {
              //Change the view to 404
              this.props.showPopup("Error", "Couldn't delete the article", "Please try again. If this continues to occur, reach out to support@geoji.com")
              this.setState({
                loading: false
              })
              return
            }
            //go back to the articles page.
            this.props.changeView("ManageBlog")
          })
        })
      }
    })
  }

  createBlock(blockType) {
    this.setState((old) => {
      let newID = ""
      while (true) {
        let found = false
        newID = Helpers.randomString(8)
        for (let i = 0; i < old.blocks.length; i = i + 1) {
          if (old.blocks[i].id === newID) {
            found = true;
            break;
          }
        }
        if (!found) {
          break;
        }
      }
      let newBlock = {
        id: newID,
        type: blockType,
        content: ""
      }

      let newBlocks = old.blocks.slice()
      newBlocks.push(newBlock)

      return {
        blocks: newBlocks
      }
    })
  }

  deleteBlock(block) {
    this.setState((old) => {
      let newBlocks = old.blocks.filter(bb => bb.id !== block.id)
      return {
        blocks: newBlocks
      }
    })
  }

  moveBlockUp(block) {
    this.setState((old) => {
      let indi = 0
      for (let i = 0; i < old.blocks.length; i = i + 1) {
        if (old.blocks[i].id === block.id) {
          indi = i
          break;
        }
      }

      let newBlocks = Helpers.arrayMove(old.blocks, indi, Math.max(indi - 1, 0))

      return {
        blocks: newBlocks
      }
    })
  }

  moveBlockDown(block) {
    this.setState((old) => {
      let indi = 0
      for (let i = 0; i < old.blocks.length; i = i + 1) {
        if (old.blocks[i].id === block.id) {
          indi = i
          break;
        }
      }

      let newBlocks = Helpers.arrayMove(old.blocks, indi, Math.min(indi + 1, old.blocks.length - 1))

      return {
        blocks: newBlocks
      }
    })
  }

  render() {

    return (
      <div className="ManageArticle">
        {/* Manage Article */}
        { this.state.view === "Home" && this.props.userInfo && this.props.userInfo.user &&
          <div className="FormBlock">
            <div className="ManageArticleTitle">
              <Components.DualImage image={ImageClose} onClick={this.props.changeView.bind(this, "ManageBlog")} />
              {(this.state.article && this.state.article.title && this.state.article.title.length > 0) ? this.state.article.title : "Article"}
            </div>
            { this.state.loading &&
              <div className="FormLoader" style={{height:"168px"}}>
                <Components.LoadingIndicator color="dark" />
              </div>
            }
            { !this.state.loading && this.state.article &&
              <div className="ManageArticleContent">
                {/* Article Information */}
                <div className="ManageArticleContentTitle">
                  Article Metadata
                </div>

                <div className="ManageArticleMetadataSettings">
                  {/* Author Info */}
                  <div className="AuthorBlockHeader">
                    Author
                  </div>
                  <div className="AuthorBlock">
                    <div className="AuthorBlockImage">
                      <img src={(this.state.article && this.state.article.author && this.state.article.author.profilePicture) || ""} alt="Profile" />
                    </div>
                    <div className="AuthorBlockName">
                      {(this.state.article && this.state.article.author && this.state.article.author.name) || ""}
                    </div>
                  </div>

                  {/* URL Tag */}
                  <div className="InputDiv">
                    <Components.InputBottomLine type="text" name="url" placeholder="" validation="alphanumeric-" required="true"
                      title="url"
                      minimum={1} maximum={256}
                      value={this.state.data.url ? this.state.data.url.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                  </div>

                  {/* Publish toggle */}
                  <Components.SettingsToggle name="Published" value={this.state.article && this.state.article.published === "1"} onToggle={this.onToggle.bind(this, "published")} />
                  <br/>

                  {/* Save Button */}
                  <Components.GeojiButton type="Dark" onClick={this.submitForm} shake={this.state.shakeButton} style={{"margin": "12px 12px"}}>
                    Save Changes
                  </Components.GeojiButton>
                  <br/>

                  {/* Delete Article */}
                  <Components.GeojiButton type="Delete" onClick={this.deleteArticle} style={{"margin": "12px 12px"}}>
                    Delete Article
                  </Components.GeojiButton>
                  <br/>
                </div>

                <div className="BlogEditor">
                  {/* Top Bar with Preview Buttons */}
                  <div className="BlogEditorTop">
                    <Components.GeojiButton type="Dark" onClick={this.submitForm} style={{"margin": "12px"}}>
                      Save Changes
                    </Components.GeojiButton>
                    <Components.GeojiButton type="Theme" onClick={this.viewArticleLink} style={{"margin": "12px"}}>
                      Preview
                    </Components.GeojiButton>
                  </div>

                  {/* The Content for the article */}
                  <div className="BlogEditorContent">
                    {/* Content Title */}
                    <div className="BlogEditorContentTitle">
                      <Components.InputBottomLine type="textarea" name="title" placeholder="Title" validation="text" required="true"
                        minimum={1} maximum={256}
                        value={this.state.data.title ? this.state.data.title.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                    </div>
                    {/* Content Subtitle */}
                    <div className="BlogEditorContentSubtitle">
                      <Components.InputBottomLine type="textarea" name="subtitle" placeholder="1-2 sentence overview" validation="text" required="true"
                        minimum={1} maximum={256}
                        value={this.state.data.subtitle ? this.state.data.subtitle.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                    </div>
                    {/* Author */}
                    <div className="BlogEditorContentAuthor">
                      <div className="BlogEditorContentAuthorImage">
                        <img src={(this.state.article && this.state.article.author && this.state.article.author.profilePicture) || ""} alt="Profile" />
                      </div>
                      <div className="BlogEditorContentAuthorContent">
                        <div className="BlogEditorContentAuthorContentName">
                          {(this.state.article && this.state.article.author && this.state.article.author.name) || ""}
                        </div>
                        <div className="BlogEditorContentAuthorContentPublished">
                          {(this.state.article && this.state.article.publishedDate) || "Draft"}
                        </div>
                      </div>
                    </div>
                    {/* Content Image */}
                    <div className="BlogEditorContentMainImage">
                      <Components.InputBottomLine type="file" name="imageURL" placeholder="" validation="file" required="false"
                        APIName="userFiles" APIUploadName="userFiles" showPopup={this.props.showPopup} maxSize={1920}
                        value={this.state.data.imageURL ? this.state.data.imageURL.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                    </div>

                    {/* Content Blocks */}
                    <div className="BlogEditorContentBlocks">
                      {/* Show the blocks that we currently have */}
                      { this.state.blocks.map((block, i) => (
                        <div key={"block_" + block.id} className="BlogEditorContentBlock">
                          {/* Toolbar */}
                          { this.state.showToolBars &&
                            <div className="BlogEditorContentBlockToolbar">
                              <div className="BlogEditorContentBlockToolbarItem BlogEditorContentBlockToolbarDelete" onClick={this.deleteBlock.bind(this, block)}>
                                <img src={ImageTrash} alt="Trash" />
                              </div>
                              <div className="BlogEditorContentBlockToolbarItem BlogEditorContentBlockToolbarMoveUp" onClick={this.moveBlockUp.bind(this, block)}>
                                <img src={ImageMoveUp} alt="Move Up" />
                              </div>
                              <div className="BlogEditorContentBlockToolbarItem BlogEditorContentBlockToolbarMoveDown" onClick={this.moveBlockDown.bind(this, block)}>
                                <img src={ImageMoveDown} alt="Move Down" />
                              </div>
                            </div>
                          }

                          {/* Content Editor */}
                          { block.type === "Header" &&
                            <div className="BlogEditorContentBlockHeader">
                              <Components.InputBottomLine type="textarea" name="content" placeholder="Header" validation="text" required="false"
                                value={block.content} onChange={(name, value, valid) => {
                                  this.setState((old) => {
                                    for (let i = 0; i < old.blocks.length; i = i + 1) {
                                      if (old.blocks[i].id === block.id) {
                                        //found the block
                                        old.blocks[i].content = value
                                      }
                                    }
                                    return {
                                      blocks: old.blocks
                                    }
                                  })
                                }} />
                            </div>
                          }
                          { block.type === "Text" &&
                            <div className="BlogEditorContentBlockText">
                              <SimpleMdeReact
                                options={this.mdeOptions}
                                value={block.content}
                                onChange={(value) => {
                                  this.setState((old) => {
                                    for (let i = 0; i < old.blocks.length; i = i + 1) {
                                      if (old.blocks[i].id === block.id) {
                                        //found the block
                                        old.blocks[i].content = value
                                      }
                                    }
                                    return {
                                      blocks: old.blocks
                                    }
                                  })
                                }}
                              />
                            </div>
                          }
                          { block.type === "Image" &&
                            <div className="BlogEditorContentBlockImage">
                              <Components.InputBottomLine type="file" name="content" placeholder="" validation="file" required="false"
                                APIName="userFiles" APIUploadName="userFiles" showPopup={this.props.showPopup} maxSize={1920}
                                value={block.content} onChange={(name, value, valid) => {
                                  this.setState((old) => {
                                    for (let i = 0; i < old.blocks.length; i = i + 1) {
                                      if (old.blocks[i].id === block.id) {
                                        //found the block
                                        old.blocks[i].content = value
                                      }
                                    }
                                    return {
                                      blocks: old.blocks
                                    }
                                  })
                                }} />
                            </div>
                          }
                          { block.type === "Video" &&
                            <div className="BlogEditorContentBlockVideo">
                              <Components.InputBottomLine type="text" name="content" placeholder="Video URL" validation="url" required="false"
                                value={block.content} onChange={(name, value, valid) => {
                                  this.setState((old) => {
                                    for (let i = 0; i < old.blocks.length; i = i + 1) {
                                      if (old.blocks[i].id === block.id) {
                                        //found the block
                                        old.blocks[i].content = value
                                      }
                                    }
                                    return {
                                      blocks: old.blocks
                                    }
                                  })
                                }} />
                            </div>
                          }
                        </div>
                      ))}

                      {/* Show the list of blocks we can add */}
                      <div className="BlogEditorContentBlocksToolbar">
                        <div className="BlogEditorContentBlocksToolbarItem BlogEditorContentBlocksToolbarShow" onClick={() => {
                          this.setState((old) => {
                            return {
                              showToolBars: !old.showToolBars
                            }
                          })
                        }}>
                          <img src={ImageToolbar} alt="Toolbar" />
                        </div>
                        <div className="BlogEditorContentBlocksToolbarItem BlogEditorContentBlocksToolbarHeader" onClick={this.createBlock.bind(this, "Header")}>
                          <img src={ImageHeader} alt="Header" />
                        </div>
                        <div className="BlogEditorContentBlocksToolbarItem BlogEditorContentBlocksToolbarText" onClick={this.createBlock.bind(this, "Text")}>
                          <img src={ImageText} alt="Text" />
                        </div>
                        <div className="BlogEditorContentBlocksToolbarItem BlogEditorContentBlocksToolbarImage" onClick={this.createBlock.bind(this, "Image")}>
                          <img src={ImageImage} alt="Create" />
                        </div>
                        <div className="BlogEditorContentBlocksToolbarItem BlogEditorContentBlocksToolbarVideo" onClick={this.createBlock.bind(this, "Video")}>
                          <img src={ImageVideo} alt="Video" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="BlogEditorTop">
                    <Components.GeojiButton type="Dark" onClick={this.submitForm} style={{"margin": "12px"}}>
                      Save Changes
                    </Components.GeojiButton>
                    <Components.GeojiButton type="Theme" onClick={this.viewArticleLink} style={{"margin": "12px"}}>
                      Preview
                    </Components.GeojiButton>
                  </div>
                </div>
              </div>
            }
            { !this.state.loading && !this.state.article &&
              <div className="ManageArticleContent">
                <div className="ManageArticleContentError">
                  We could not find this article
                </div>
              </div>
            }
          </div>
        }
      </div>
    )
  }
}
