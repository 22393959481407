import React from 'react';
import autoBind from 'react-autobind';
//import ReactPlayer from 'react-player'
// import Vimeo from '@u-wave/react-vimeo';
// import uuidv4 from 'uuid/v4';
// import * as tus from "tus-js-client"
// import Lottie from 'lottie-web';

import * as Components from '../Components.js';
//import * as Generation from '../Generation.js';
import * as Helpers from '../Helpers.js';

//import the Darwin API classes
import API from '../API.js';

//import * as ExampleData from '../data/exampleData.json'
// import * as Instructor from './Instructor.js';

// import ImageYogaMat from '../images/Floww/YogaMat.jpg'
// import ImageDefault2 from '../images/Floww/Default2.jpg'
// import ImageClassDefault from '../images/Floww/NewClass.jpg'


//import ImageUpload from '../images/Icons/Upload.svg'
// import ImagePaintBucketWhite from '../images/Icons/PaintBucketWhite.svg'
// import ImagePaintBucketBlack from '../images/Icons/PaintBucketBlack.svg'
// import ImageAngleTopRight from '../images/Icons/AngleTopRight.svg'
// import ImageAngleTopLeft from '../images/Icons/AngleTopLeft.svg'
// import ImageAngleBottomRight from '../images/Icons/AngleBottomRight.svg'
// import ImageAngleBottomLeft from '../images/Icons/AngleBottomLeft.svg'

// import ImagePayPal from '../images/Icons/PayPal.svg'
// import ImageVenmo from '../images/Icons/Venmo.svg'

export class Profile extends React.Component {

  constructor(props) {
    super(props)

    let data = {}

    //set the user info
    if (this.props.userInfo && this.props.userInfo.user) {
      let items = ["name"]
      items.forEach((item, i) => {
        if (this.props.userInfo.user[item]) {
          data[item] = {
            value: this.props.userInfo.user[item],
            valid: true
          }
        }
      });
    }
    console.log(data)

    this.state = {
      loading: false,
      data: data,
      forceCheck: false,
      shakeButton: false,
      changesMade: false, //have any changes been made

      view: "Home",
      subview: "Home",
      prompt: false,
    }
    autoBind(this)
  }

  componentDidMount() {
    //calculate the view
    this.calculateView()
  }

  componentDidUpdate(prevProps) {
    if (this.props.render !== prevProps.render) {
      //reload the home view.
      this.calculateView()
    }
  }

  calculateView() {
    //set the data
  }

  /*
  Logs out the user and takes them to the login view.
  */
  logout() {
    API.logout()
  }

  /*
  Updates this user's information
  */
  updateUserInfo(data) {
    //console.log("Update User Info", data)
    //1) Call the API to update this user's profile information.
    this.setState({
      loading: true
    }, () => {
      //2) Re-get this user's information.
      API.callDarwinAPI("PUT", "userProfile", data, (result) => {
        if ("error" in result) {
          this.setState({
            loading: false
          }, () => {
            this.props.showPopup("Error", "Couldn't update your profile", result.error)
          })
          return
        }
        this.setState({
          loading: false,
          valid: false,
          forceCheck: false,
          shakeButton: false
        }, () => {
          this.props.homeAndReloadUserInfo()
        })
      })
    })
  }

  /*
  * Submits the form
  */
  submitForm() {
    let results = this.checkForm(true)
    if (results.valid) {
      switch (this.state.view) {
        case "Home":
          this.updateUserInfo(results.data)
          break;
        default:
          console.error("Unhandled submitForm", this.state.view)
          break;
      }
    } else {
      console.log("Form not valid to submit")
      this.setState({
        forceCheck: true
      })
      //Shake the submit button as it is invalid to submit
      this.shakeTheButton()
    }
  }

  /*
  Checks the form to make sure it is valid.
  Returns {valid:Bool, data:{}}
  */
  checkForm(print = false) {
    //1) Make sure we have all of the data.
    let valid = true
    let requiredFields = []
    let requiredIf = []
    let requiredSometimes = [] //if set, then don't remove it from the data.
    let optionals = [] //the optional fields apart from the requiredFields.
    switch (this.state.view) {
      case "Home":
        requiredFields = []
        optionals = ["name"]
        requiredIf = []
        requiredSometimes = []
        break;
      default:
        break;
    }
    requiredFields.forEach((element) => {
      if (this.state.data[element] === undefined || this.state.data[element].value === undefined) {
        //the field is not filled out
        if (print) {
          console.log("required not filled out: ", element)
        }
        valid = false
      }
    })
    requiredIf.forEach((condition) => {
      if (this.state.data[condition.field] === undefined || this.state.data[condition.field].value === undefined) {
        //the condition field is not filled out
        if (print) {
          console.log("condition field is not filled out: ", condition)
        }
        valid = false
      } else if (condition.values !== undefined && condition.values.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          if (print) {
            console.log("rif field is required and is not filled out: ", condition)
          }
          valid = false
        }
      } else if (condition.not !== undefined && !condition.not.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          if (print) {
            console.log("rif field is required with not and is not filled out: ", condition)
          }
          valid = false
        }
      }
    })
    //2) Make sure every data element is valid.
    for (let key in this.state.data) {
      let forget = false
      //Make sure it is not a requiredSometimes
      if (!requiredSometimes.includes(key)) {
        // Check the requiredIf conditions
        for (let i = 0; i < requiredIf.length; i = i + 1) {
          let rif = requiredIf[i]
          if (rif.require === key) {
            if (!rif.values.includes(this.state.data[rif.field].value)) {
              if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                forget = true
              }
            }
            break
          }
        }
      }
      //Make sure this is a field we are looking for.
      if (optionals.includes(key) || requiredFields.includes(key)) {
        if (!forget && this.state.data[key].valid === false) {
          if (print) {
            console.log("data not valid", key)
          }
          valid = false
        }
      }
    }

    //3) If valid, return the data, else force check the fields for display.
    if (valid) {
      let data = {}
      for (let key in this.state.data) {
        let forget = false
        //Make sure it is not a requiredSometimes
        if (!requiredSometimes.includes(key)) {
          for (let i = 0; i < requiredIf.length; i = i + 1) {
            let rif = requiredIf[i]
            if (rif.require === key) {
              if (!rif.values.includes(this.state.data[rif.field].value)) {
                if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                  forget = true
                }
              }
              break
            }
          }
        }
        if (!forget) {
          if (optionals.includes(key) || requiredFields.includes(key)) {
            if (this.state.data[key].value !== false && this.state.data[key].value.toString().length > 0) {
              data[key] = this.state.data[key].value
              if (typeof data[key] === 'string') {
                data[key] = data[key].trim()
              }
            }
          }
        }
      }
      return {
        valid: true,
        data: data
      }
    } else {
      return {
        valid: false,
        data: "Form not valid to submit"
      }
    }
  }

  /*
  * Called when data in an input form changes.
  * This will update the state.data param with the
  * name and new value of the form element.
  */
  formChanged(name, value, valid) {
    //console.log(name, value, valid);
    this.setState((prevState) => {
      let d = prevState.data
      d[name] = {
        value: value,
        valid: valid
      }
      //check to see if the form is valid to submit
      return {
        data: d,
        forceCheck: false,
        changesMade: true,
      }
    }, () => {
      let res = this.checkForm()
      this.setState({
        valid: res.valid
      })
    })
  }

  /*
  Shakes the button and then removes the class.
  */
  shakeTheButton() {
    this.setState({
      shakeButton: true
    }, () => {
      setTimeout(() => {
        this.setState({
          shakeButton: false
        })
      }, 1000)
    })
  }

  render() {

    return (
      <div className="Profile">
        {/* User Profile */}
        { this.state.view === "Home" && this.props.userInfo && this.props.userInfo.user &&
          <div className="FormBlock">
            <div className="ProfileFormTitle">
              Your Profile
            </div>
            { this.state.loading &&
              <div className="FormLoader" style={{height:"168px"}}>
                <Components.LoadingIndicator color="dark" />
              </div>
            }
            { !this.state.loading &&
              <span>
                <div className="FormBlockInput">
                  <Components.InputBottomLine type="text" name="name" placeholder="" validation="text" required="false" title="Full Name" validateTyping={false}
                    value={this.state.data.name ? this.state.data.name.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                </div>
                <Components.GeojiButton type={this.state.valid ? "Dark" : "Gray"} onClick={this.submitForm} shake={this.state.shakeButton} style={{marginTop:"30px"}}>
                  Save Changes
                </Components.GeojiButton>
                <br/>
                <div className="FormBlockLinks" style={{marginTop:"50px"}}>
                  <div className="FormBlockLink" onClick={this.logout}>
                    Logout
                  </div>
                </div>
                <div className="AccountVersionNumber">
                  { "v " + Helpers.getAppVersion() }
                </div>
              </span>
            }
          </div>
        }
      </div>
    )
  }
}
