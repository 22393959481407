import React from 'react';
import autoBind from 'react-autobind';

import * as Components from '../Components.js';
//import * as Generation from '../Generation.js';
import * as Helpers from '../Helpers.js';

//import {DateObject} from "react-multi-date-picker";


//import the Darwin API classes
import API from '../API.js';

// import ImageClose from '../images/Icons/Close.svg'
// import ImageProfile from '../images/Icons/UserGray.svg'
// import ImageLoading from '../images/Icons/loading.svg'
//import ImageCheckmark from '../images/Icons/Checkmark.svg'
//import ImageUncheckmark from '../images/Icons/Uncheckmark.svg'

export class ManageGeojis extends React.Component {

  constructor(props) {
    super(props)

    let data = {}

    this.state = {
      loading: false,
      data: data,
      forceCheck: false,
      shakeButton: false,
      changesMade: false, //have any changes been made

      view: "Home",
      subview: "Home",
      prompt: false,

      geojis: [],
      filter: "All", //the filter to apply
      sort: "Created", //the sort to apply
      sortDirection: "DESC", //DESC or ASC
      limit: 30, //the number of results per page.
      page: 0, //the page of results.
      pageMax: 0, //the max page.
      search: "", //the search parameter.
      resultsCount: 0, //the number of results.
    }
    autoBind(this)
  }

  componentDidMount() {
    //calculate the view
    this.calculateView()
  }

  componentDidUpdate(prevProps) {
    if (this.props.render !== prevProps.render) {
      //reload the home view.
      this.calculateView()
    }
  }

  calculateView() {
    //set the data
    if (this.state.view === "Home") {
      //load the abilities for this user.
      this.getGeojis()
    }
  }

  /*
  * Submits the form
  */
  submitForm() {
    let results = this.checkForm(true)
    if (results.valid) {
      switch (this.state.view) {
        case "Home":
          this.changeSearch(results.data)
          break;
        default:
          console.error("Unhandled submitForm", this.state.view)
          break;
      }
    } else {
      console.log("Form not valid to submit")
      this.setState({
        forceCheck: true
      })
      //Shake the submit button as it is invalid to submit
      this.shakeTheButton()
    }
  }

  /*
  Checks the form to make sure it is valid.
  Returns {valid:Bool, data:{}}
  */
  checkForm(print = false) {
    //1) Make sure we have all of the data.
    let valid = true
    let requiredFields = []
    let requiredIf = []
    let requiredSometimes = [] //if set, then don't remove it from the data.
    let optionals = [] //the optional fields apart from the requiredFields.
    switch (this.state.view) {
      case "Home":
        requiredFields = []
        optionals = ["search"]
        requiredIf = []
        requiredSometimes = []
        break;
      default:
        break;
    }
    requiredFields.forEach((element) => {
      if (this.state.data[element] === undefined || this.state.data[element].value === undefined) {
        //the field is not filled out
        if (print) {
          console.log("required not filled out: ", element)
        }
        valid = false
      }
    })
    requiredIf.forEach((condition) => {
      if (this.state.data[condition.field] === undefined || this.state.data[condition.field].value === undefined) {
        //the condition field is not filled out
        if (print) {
          console.log("condition field is not filled out: ", condition)
        }
        valid = false
      } else if (condition.values !== undefined && condition.values.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          if (print) {
            console.log("rif field is required and is not filled out: ", condition)
          }
          valid = false
        }
      } else if (condition.not !== undefined && !condition.not.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          if (print) {
            console.log("rif field is required with not and is not filled out: ", condition)
          }
          valid = false
        }
      }
    })
    //2) Make sure every data element is valid.
    for (let key in this.state.data) {
      let forget = false
      //Make sure it is not a requiredSometimes
      if (!requiredSometimes.includes(key)) {
        // Check the requiredIf conditions
        for (let i = 0; i < requiredIf.length; i = i + 1) {
          let rif = requiredIf[i]
          if (rif.require === key) {
            if (!rif.values.includes(this.state.data[rif.field].value)) {
              if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                forget = true
              }
            }
            break
          }
        }
      }
      //Make sure this is a field we are looking for.
      if (optionals.includes(key) || requiredFields.includes(key)) {
        if (!forget && this.state.data[key].valid === false) {
          if (print) {
            console.log("data not valid", key)
          }
          valid = false
        }
      }
    }

    //3) If valid, return the data, else force check the fields for display.
    if (valid) {
      let data = {}
      for (let key in this.state.data) {
        let forget = false
        //Make sure it is not a requiredSometimes
        if (!requiredSometimes.includes(key)) {
          for (let i = 0; i < requiredIf.length; i = i + 1) {
            let rif = requiredIf[i]
            if (rif.require === key) {
              if (!rif.values.includes(this.state.data[rif.field].value)) {
                if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                  forget = true
                }
              }
              break
            }
          }
        }
        if (!forget) {
          if (optionals.includes(key) || requiredFields.includes(key)) {
            if (this.state.data[key].value !== false && this.state.data[key].value.toString().length > 0) {
              data[key] = this.state.data[key].value
              if (typeof data[key] === 'string') {
                data[key] = data[key].trim()
              }
            }
          }
        }
      }
      return {
        valid: true,
        data: data
      }
    } else {
      return {
        valid: false,
        data: "Form not valid to submit"
      }
    }
  }

  /*
  * Called when data in an input form changes.
  * This will update the state.data param with the
  * name and new value of the form element.
  */
  formChanged(name, value, valid) {
    //console.log(name, value, valid);
    this.setState((prevState) => {
      let d = prevState.data
      d[name] = {
        value: value,
        valid: valid
      }
      if (prevState.view === "Create" && name === "type") {
        let defaults = Helpers.getScrapingConfigs(value)
        //select the first default.
        let config = defaults[0]
        for (let key in config) {
          d[key] = {
            value: config[key],
            valid: true
          }
        }
      } else if (prevState.view === "Create" && name === "config") {
        //get the config
        let defaults = Helpers.getScrapingConfigs(d["type"]["value"])
        let config = false
        for (let i = 0; i < defaults.length; i = i + 1) {
          if (defaults[i]["config"] === value) {
            config = defaults[i]
            break
          }
        }
        if (config !== false) {
          for (let key in config) {
            d[key] = {
              value: config[key],
              valid: true
            }
          }
        }
      }
      return {
        data: d,
        forceCheck: false,
        changesMade: true,
      }
    }, () => {
      //check to see if the form is valid to submit
      let res = this.checkForm()
      this.setState({
        valid: res.valid
      })
    })
  }

  /*
  Shakes the button and then removes the class.
  */
  shakeTheButton() {
    this.setState({
      shakeButton: true
    }, () => {
      setTimeout(() => {
        this.setState({
          shakeButton: false
        })
      }, 1000)
    })
  }

  /*
  We have selected a geoji.
  */
  geojiSelected(geojiID) {
    //go to this Geojis page.
    window.open("https://geoji.com/g/" + geojiID, "_blank")
  }

  /*
  Changes the filter that is applied to the requests.
  */
  changeFilter(newFilter) {
    this.setState({
      filter: newFilter,
      page: 0,
    }, () => {
      this.getGeojis()
    })
  }

  /*
  Changes the page of results we are viewing.
  */
  changePage(newPage) {
    this.setState({
      page: newPage
    }, () => {
      this.getGeojis()
    })
  }

  /*
  Changes the sort that is applied to the requests.
  */
  changeSort(newSort) {
    if (!["Title", "Created", "Start"].includes(newSort)) {
      console.log("No Sort for ", newSort)
      return
    }
    this.setState((oldState) => {
      let newSortDirection = "DESC"
      if (oldState.sort === newSort) {
        //switch the direction
        newSortDirection = oldState.sortDirection
        if (newSortDirection === "DESC") {
          newSortDirection = "ASC"
        } else {
          newSortDirection = "DESC"
        }
      }
      return {
        sort: newSort,
        sortDirection: newSortDirection,
        page: 0
      }
    }, () => {
      this.getGeojis()
    })
  }

  /*
  Changes the search that is applied to the requests.
  */
  changeSearch(data) {
    console.log(data)
    this.setState(() => {
      return {
        search: data.search || "",
        page: 0
      }
    }, () => {
      this.getGeojis()
    })
  }

  /*
  Get the list of users we have sent.
  */
  getGeojis() {
    this.setState({
      loading: true
    }, () => {

      let data = {
        filter: this.state.filter,
        sort: this.state.sort,
        sortDirection: this.state.sortDirection,
        limit: this.state.limit,
        page: this.state.page,
        //positiveBalance: this.state.positiveBalance === true ? 1 : 0,
        //onlyCreators: this.state.onlyCreators === true ? 1 : 0,
        //contacts: this.state.contacts === true ? 1 : 0
      }
      if (this.state.search.length > 0) {
        data.search = this.state.search
      }
      console.log("search data", data)

      API.callDarwinAPI("GET", "manageGeojis", data, async (result) => {
        console.log("manageGeojis", result)
        if ("error" in result) {
          //Change the view to 404
          this.props.showPopup("Error", "Couldn't get the geojis", "Please try again. If this continues to occur, reach out to support@geoji.com")
          this.setState({
            loading: false
          })
          return
        }
        let pageMax = Math.ceil((result.data.totalCount * 1.0) / this.state.limit) - 1

        //format the geojis
        let uu = result.data.geojis
        for (let i = 0; i < uu.length; i = i + 1) {
          uu[i].title = uu[i].title && uu[i].title.length > 0 ? uu[i].title : "-"
          uu[i].creationDate = Helpers.formatDateObjectShort(uu[i].creationTimestamp)
          if (!uu[i].startDate) {
            uu[i].startDateObject = Helpers.formatDateObjectShort("1999-01-01 09:00:00.000000+00")
          } else {
            uu[i].startDateObject = Helpers.formatDateObjectShort(uu[i].startDate)
          }
          let now = new Date()
          if (uu[i].deleted === "1" || Helpers.parseSQLDate(uu[i].expirationDate) < now) {
            uu[i].signs = "🗑"
          } else {
            uu[i].signs = "✅"
          }
        }
        this.setState({
          loading: false,
          geojis: uu,
          pageMax: pageMax,
          resultsCount: result.data.totalCount,
        })
      })
    })
  }

  /*
  Changes the toggle for one of the filters and the re-searches for users.
  */
  onToggle(key) {
    this.setState((old) => {
      let obj = {}
      obj[key] = !old[key]
      console.log("obj", obj)
      if (key === "positiveBalance") {
        if (obj[key] === false) {
          //revert sort to the name field.
          obj.sort = "Name"
          obj.sortDirection = "ASC"
        } else {
          obj.sort = "Balance"
          obj.sortDirection = "DESC"
        }
      }
      return obj
    }, () => {
      this.getGeojis()
    })
  }

  render() {

    return (
      <div className="WebScrapings">
        {/* Manage Geojis */}
        { this.state.view === "Home" && this.props.userInfo && this.props.userInfo.user &&
          <div className="FormBlock">
            <div className="WebScrapingsTitle">
              Manage Geojis
            </div>
            { this.state.loading &&
              <div className="FormLoader" style={{height:"168px"}}>
                <Components.LoadingIndicator color="dark" />
              </div>
            }
            { !this.state.loading &&
              <div className="WebScrapingsContent">
                <div className="InputDiv">
                  <Components.InputBottomLine type="text" name="search" placeholder="" validation="text" required="false"
                    title="Search by title" maximum={100}
                    value={this.state.data.search ? this.state.data.search.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                </div>
                <div className="WebScrapingsLegend">
                  <div className="WebScrapingsLegendItem">
                    <div className="WebScrapingsLegendItemKey">
                      <span role="img" aria-label="trashcan">🗑</span>
                    </div>
                    <div className="WebScrapingsLegendItemValue">
                      Expired or Deleted
                    </div>
                  </div>
                  <div className="WebScrapingsLegendItemBorder"></div>
                  <div className="WebScrapingsLegendItem">
                    <div className="WebScrapingsLegendItemKey">
                      <span role="img" aria-label="green checkmark">✅</span>
                    </div>
                    <div className="WebScrapingsLegendItemValue">
                      Active
                    </div>
                  </div>
                </div>
                <div className="WebScrapingsFilters">
                  {/*<Components.SettingsToggle name="Only Creators" value={this.state.onlyCreators === true} onToggle={this.onToggle.bind(this, "onlyCreators")} />
                  <Components.SettingsToggle name="Positive Balance" value={this.state.positiveBalance === true} onToggle={this.onToggle.bind(this, "positiveBalance")} />
                  <Components.SettingsToggle name="Contacts" value={this.state.contacts === true} onToggle={this.onToggle.bind(this, "contacts")} />*/}
                </div>
                <Components.Table
                  tableInfo={Helpers.getManageGeojisTableInfo(this.state.geojis)}
                  noData={Helpers.getText("emptyGeojis")}
                  data={this.state.geojis}
                  onClick={this.geojiSelected}
                  onClickKey={"geojiID"}
                  onHeaderClick={this.changeSort}
                  sort={this.state.sort}
                  sortDirection={this.state.sortDirection}
                  >
                  <div className="TableTop">
                    <div className="TableTopDate">
                      <Components.PageIndicator page={this.state.page} pageMax={this.state.pageMax} pageSelected={this.changePage} count={this.state.resultsCount} />
                    </div>
                  </div>
                </Components.Table>
              </div>
            }
          </div>
        }
      </div>
    )
  }
}
