import React from 'react';
import autoBind from 'react-autobind';

import * as Components from '../Components.js';
//import * as Generation from '../Generation.js';
import * as Helpers from '../Helpers.js';

//import {DateObject} from "react-multi-date-picker";


//import the Darwin API classes
import API from '../API.js';

import ImageClose from '../images/Icons/Close.svg'
import ImageProfile from '../images/Icons/UserGray.svg'
import ImageLoading from '../images/Icons/loading.svg'
//import ImageCheckmark from '../images/Icons/Checkmark.svg'
//import ImageUncheckmark from '../images/Icons/Uncheckmark.svg'

export class ManageUsers extends React.Component {

  constructor(props) {
    super(props)

    let data = {}

    this.state = {
      loading: false,
      data: data,
      forceCheck: false,
      shakeButton: false,
      changesMade: false, //have any changes been made

      view: "Home",
      subview: "Home",
      prompt: false,

      users: [],
      filter: "All", //the filter to apply
      sort: "Name", //the sort to apply
      sortDirection: "ASC", //DESC or ASC
      limit: 30, //the number of results per page.
      page: 0, //the page of results.
      pageMax: 0, //the max page.
      search: "", //the search parameter.
      resultsCount: 0, //the number of results.
      requestedPayout: props.tertiaryView === "RequestedPayout" ? true : false,
    }
    autoBind(this)
  }

  componentDidMount() {
    //calculate the view
    this.calculateView()
  }

  componentDidUpdate(prevProps) {
    if (this.props.render !== prevProps.render) {
      //reload the home view.
      this.calculateView()
    }
  }

  calculateView() {
    //set the data
    if (this.state.view === "Home") {
      //load the abilities for this user.
      this.getUsers()
    }
  }

  /*
  * Submits the form
  */
  submitForm() {
    let results = this.checkForm(true)
    if (results.valid) {
      switch (this.state.view) {
        case "Home":
          this.changeSearch(results.data)
          break;
        default:
          console.error("Unhandled submitForm", this.state.view)
          break;
      }
    } else {
      console.log("Form not valid to submit")
      this.setState({
        forceCheck: true
      })
      //Shake the submit button as it is invalid to submit
      this.shakeTheButton()
    }
  }

  /*
  Checks the form to make sure it is valid.
  Returns {valid:Bool, data:{}}
  */
  checkForm(print = false) {
    //1) Make sure we have all of the data.
    let valid = true
    let requiredFields = []
    let requiredIf = []
    let requiredSometimes = [] //if set, then don't remove it from the data.
    let optionals = [] //the optional fields apart from the requiredFields.
    switch (this.state.view) {
      case "Home":
        requiredFields = []
        optionals = ["search"]
        requiredIf = []
        requiredSometimes = []
        break;
      default:
        break;
    }
    requiredFields.forEach((element) => {
      if (this.state.data[element] === undefined || this.state.data[element].value === undefined) {
        //the field is not filled out
        if (print) {
          console.log("required not filled out: ", element)
        }
        valid = false
      }
    })
    requiredIf.forEach((condition) => {
      if (this.state.data[condition.field] === undefined || this.state.data[condition.field].value === undefined) {
        //the condition field is not filled out
        if (print) {
          console.log("condition field is not filled out: ", condition)
        }
        valid = false
      } else if (condition.values !== undefined && condition.values.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          if (print) {
            console.log("rif field is required and is not filled out: ", condition)
          }
          valid = false
        }
      } else if (condition.not !== undefined && !condition.not.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          if (print) {
            console.log("rif field is required with not and is not filled out: ", condition)
          }
          valid = false
        }
      }
    })
    //2) Make sure every data element is valid.
    for (let key in this.state.data) {
      let forget = false
      //Make sure it is not a requiredSometimes
      if (!requiredSometimes.includes(key)) {
        // Check the requiredIf conditions
        for (let i = 0; i < requiredIf.length; i = i + 1) {
          let rif = requiredIf[i]
          if (rif.require === key) {
            if (!rif.values.includes(this.state.data[rif.field].value)) {
              if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                forget = true
              }
            }
            break
          }
        }
      }
      //Make sure this is a field we are looking for.
      if (optionals.includes(key) || requiredFields.includes(key)) {
        if (!forget && this.state.data[key].valid === false) {
          if (print) {
            console.log("data not valid", key)
          }
          valid = false
        }
      }
    }

    //3) If valid, return the data, else force check the fields for display.
    if (valid) {
      let data = {}
      for (let key in this.state.data) {
        let forget = false
        //Make sure it is not a requiredSometimes
        if (!requiredSometimes.includes(key)) {
          for (let i = 0; i < requiredIf.length; i = i + 1) {
            let rif = requiredIf[i]
            if (rif.require === key) {
              if (!rif.values.includes(this.state.data[rif.field].value)) {
                if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                  forget = true
                }
              }
              break
            }
          }
        }
        if (!forget) {
          if (optionals.includes(key) || requiredFields.includes(key)) {
            if (this.state.data[key].value !== false && this.state.data[key].value.toString().length > 0) {
              data[key] = this.state.data[key].value
              if (typeof data[key] === 'string') {
                data[key] = data[key].trim()
              }
            }
          }
        }
      }
      return {
        valid: true,
        data: data
      }
    } else {
      return {
        valid: false,
        data: "Form not valid to submit"
      }
    }
  }

  /*
  * Called when data in an input form changes.
  * This will update the state.data param with the
  * name and new value of the form element.
  */
  formChanged(name, value, valid) {
    //console.log(name, value, valid);
    this.setState((prevState) => {
      let d = prevState.data
      d[name] = {
        value: value,
        valid: valid
      }
      if (prevState.view === "Create" && name === "type") {
        let defaults = Helpers.getScrapingConfigs(value)
        //select the first default.
        let config = defaults[0]
        for (let key in config) {
          d[key] = {
            value: config[key],
            valid: true
          }
        }
      } else if (prevState.view === "Create" && name === "config") {
        //get the config
        let defaults = Helpers.getScrapingConfigs(d["type"]["value"])
        let config = false
        for (let i = 0; i < defaults.length; i = i + 1) {
          if (defaults[i]["config"] === value) {
            config = defaults[i]
            break
          }
        }
        if (config !== false) {
          for (let key in config) {
            d[key] = {
              value: config[key],
              valid: true
            }
          }
        }
      }
      return {
        data: d,
        forceCheck: false,
        changesMade: true,
      }
    }, () => {
      //check to see if the form is valid to submit
      let res = this.checkForm()
      this.setState({
        valid: res.valid
      })
    })
  }

  /*
  Shakes the button and then removes the class.
  */
  shakeTheButton() {
    this.setState({
      shakeButton: true
    }, () => {
      setTimeout(() => {
        this.setState({
          shakeButton: false
        })
      }, 1000)
    })
  }

  /*
  We have selected a scraping.
  */
  userSelected(userID, clickType, event) {
    //go to this web scraping's page.
    if (clickType === "rightclick") {
      //open a new tab
      event.preventDefault()
      console.log(userID, clickType, event)
      window.open("/user/" + userID, "_blank", "noreferrer")
    } else {
      this.props.changeView("ManageUser", userID)
    }
  }

  /*
  Changes the filter that is applied to the requests.
  */
  changeFilter(newFilter) {
    this.setState({
      filter: newFilter,
      page: 0,
    }, () => {
      this.getUsers()
    })
  }

  /*
  Changes the page of results we are viewing.
  */
  changePage(newPage) {
    this.setState({
      page: newPage
    }, () => {
      this.getUsers()
    })
  }

  /*
  Changes the sort that is applied to the requests.
  */
  changeSort(newSort) {
    if (!["Name", "Phone Number", "Created", "Balance"].includes(newSort)) {
      console.log("No Sort for ", newSort)
      return
    }
    this.setState((oldState) => {
      let newSortDirection = "DESC"
      if (oldState.sort === newSort) {
        //switch the direction
        newSortDirection = oldState.sortDirection
        if (newSortDirection === "DESC") {
          newSortDirection = "ASC"
        } else {
          newSortDirection = "DESC"
        }
      }
      return {
        sort: newSort,
        sortDirection: newSortDirection,
        page: 0
      }
    }, () => {
      this.getUsers()
    })
  }

  /*
  Changes the search that is applied to the requests.
  */
  changeSearch(data) {
    console.log(data)
    this.setState(() => {
      return {
        search: data.search || "",
        page: 0
      }
    }, () => {
      this.getUsers()
    })
  }

  /*
  Get the list of users we have sent.
  */
  getUsers() {
    this.setState({
      loading: true
    }, () => {

      let data = {
        filter: this.state.filter,
        sort: this.state.sort,
        sortDirection: this.state.sortDirection,
        limit: this.state.limit,
        page: this.state.page,
        positiveBalance: this.state.positiveBalance === true ? 1 : 0,
        onlyCreators: this.state.onlyCreators === true ? 1 : 0,
        contacts: this.state.contacts === true ? 1 : 0,
        requestedPayout: this.state.requestedPayout === true ? 1 : 0,
      }
      if (this.state.search.length > 0) {
        data.search = this.state.search
      }
      console.log("search data", data)

      API.callDarwinAPI("GET", "manageUsers", data, async (result) => {
        console.log("manageUsers", result)
        if ("error" in result) {
          //Change the view to 404
          this.props.showPopup("Error", "Couldn't get the users", "Please try again. If this continues to occur, reach out to support@geoji.com")
          this.setState({
            loading: false
          })
          return
        }
        let pageMax = Math.ceil((result.data.totalCount * 1.0) / this.state.limit) - 1

        //format the users
        let uu = result.data.users
        for (let i = 0; i < uu.length; i = i + 1) {
          uu[i].name = uu[i].name && uu[i].name.length > 0 ? uu[i].name : "-"
          uu[i].creationDate = Helpers.formatDateObjectShort(uu[i].creationTimestamp)
          uu[i].phoneNumberParsed = uu[i].phoneNumber ? Helpers.parsePhoneNumber(uu[i].phoneNumber) : "-"
        }
        this.setState({
          loading: false,
          users: uu,
          pageMax: pageMax,
          resultsCount: result.data.totalCount,
        })
      })
    })
  }

  /*
  Changes the toggle for one of the filters and the re-searches for users.
  */
  onToggle(key) {
    this.setState((old) => {
      let obj = {}
      obj[key] = !old[key]
      console.log("obj", obj)
      if (key === "positiveBalance") {
        if (obj[key] === false) {
          //revert sort to the name field.
          obj.sort = "Name"
          obj.sortDirection = "ASC"
        } else {
          obj.sort = "Balance"
          obj.sortDirection = "DESC"
        }
      }
      return obj
    }, () => {
      this.getUsers()
    })
  }

  render() {

    return (
      <div className="WebScrapings">
        {/* Manage Users */}
        { this.state.view === "Home" && this.props.userInfo && this.props.userInfo.user &&
          <div className="FormBlock">
            <div className="WebScrapingsTitle">
              Manage Users
            </div>
            { this.state.loading &&
              <div className="FormLoader" style={{height:"168px"}}>
                <Components.LoadingIndicator color="dark" />
              </div>
            }
            { !this.state.loading &&
              <div className="WebScrapingsContent">
                <div className="InputDiv">
                  <Components.InputBottomLine type="text" name="search" placeholder="" validation="text" required="false"
                    title="Search by name, email, or number" maximum={100}
                    value={this.state.data.search ? this.state.data.search.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                </div>
                <div className="WebScrapingsFilters">
                  <Components.SettingsToggle name="Requested Payout" value={this.state.requestedPayout === true} onToggle={this.onToggle.bind(this, "requestedPayout")} />
                  <Components.SettingsToggle name="Only Creators" value={this.state.onlyCreators === true} onToggle={this.onToggle.bind(this, "onlyCreators")} />
                  <Components.SettingsToggle name="Positive Balance" value={this.state.positiveBalance === true} onToggle={this.onToggle.bind(this, "positiveBalance")} />
                  <Components.SettingsToggle name="Contacts" value={this.state.contacts === true} onToggle={this.onToggle.bind(this, "contacts")} />
                </div>
                <Components.Table
                  tableInfo={Helpers.getManageUsersTableInfo(this.state.users)}
                  noData={Helpers.getText("emptyUsers")}
                  data={this.state.users}
                  onClick={this.userSelected}
                  onClickKey={"id"}
                  onHeaderClick={this.changeSort}
                  sort={this.state.sort}
                  sortDirection={this.state.sortDirection}
                  >
                  <div className="TableTop">
                    <div className="TableTopDate">
                      <Components.PageIndicator page={this.state.page} pageMax={this.state.pageMax} pageSelected={this.changePage} count={this.state.resultsCount} />
                    </div>
                  </div>
                </Components.Table>
              </div>
            }
          </div>
        }
      </div>
    )
  }
}

export class ManageUser extends React.Component {

  constructor(props) {
    super(props)

    let data = {}
    console.log(this.props)

    this.state = {
      loading: false,
      data: data,
      forceCheck: false,
      shakeButton: false,
      changesMade: false, //have any changes been made

      view: "Home",
      subview: "Home",
      prompt: false,

      user: false,
      subloadingSettings: false,
      subloadingPaymentSettings: false,

      payoutsExpanded: false,
      geojisExpanded: false,
      purchasesExpanded: false,
      possiblePurchasesExpanded: false,
      sessionsExpanded: false,
      loginAttemptsExpanded: false,
      creatingBalanceAdjustment: false,
    }
    autoBind(this)
  }

  componentDidMount() {
    //calculate the view
    this.calculateView()
  }

  componentDidUpdate(prevProps) {
    if (this.props.render !== prevProps.render) {
      //reload the home view.
      this.calculateView()
    }
  }

  calculateView() {
    //set the data
    if (this.state.view === "Home") {
      //load the abilities for this user.
      this.getUser()
    }
  }

  /*
  * Submits the form
  */
  submitForm() {
    let results = this.checkForm(true)
    if (results.valid) {
      switch (this.state.view) {
        case "Home":
          this.savePaymentSettings(results.data)
          break;
        default:
          console.error("Unhandled submitForm", this.state.view)
          break;
      }
    } else {
      console.log("Form not valid to submit")
      this.setState({
        forceCheck: true
      })
      //Shake the submit button as it is invalid to submit
      this.shakeTheButton()
    }
  }

  /*
  Checks the form to make sure it is valid.
  Returns {valid:Bool, data:{}}
  */
  checkForm(print = false) {
    //1) Make sure we have all of the data.
    let valid = true
    let requiredFields = []
    let requiredIf = []
    let requiredSometimes = [] //if set, then don't remove it from the data.
    let optionals = [] //the optional fields apart from the requiredFields.
    switch (this.state.view) {
      case "Home":
        requiredFields = ["payoutDaysAfterEvent", "plaidLinksLeft", "payoutMinimumCents", "maxTokenPriceCents", "automatedPayouts"]
        optionals = ["statementDescription"]
        requiredIf = []
        requiredSometimes = []
        break;
      default:
        break;
    }
    requiredFields.forEach((element) => {
      if (this.state.data[element] === undefined || this.state.data[element].value === undefined) {
        //the field is not filled out
        if (print) {
          console.log("required not filled out: ", element)
        }
        valid = false
      }
    })
    requiredIf.forEach((condition) => {
      if (this.state.data[condition.field] === undefined || this.state.data[condition.field].value === undefined) {
        //the condition field is not filled out
        if (print) {
          console.log("condition field is not filled out: ", condition)
        }
        valid = false
      } else if (condition.values !== undefined && condition.values.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          if (print) {
            console.log("rif field is required and is not filled out: ", condition)
          }
          valid = false
        }
      } else if (condition.not !== undefined && !condition.not.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          if (print) {
            console.log("rif field is required with not and is not filled out: ", condition)
          }
          valid = false
        }
      }
    })
    //2) Make sure every data element is valid.
    for (let key in this.state.data) {
      let forget = false
      //Make sure it is not a requiredSometimes
      if (!requiredSometimes.includes(key)) {
        // Check the requiredIf conditions
        for (let i = 0; i < requiredIf.length; i = i + 1) {
          let rif = requiredIf[i]
          if (rif.require === key) {
            if (!rif.values.includes(this.state.data[rif.field].value)) {
              if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                forget = true
              }
            }
            break
          }
        }
      }
      //Make sure this is a field we are looking for.
      if (optionals.includes(key) || requiredFields.includes(key)) {
        if (!forget && this.state.data[key].valid === false) {
          if (print) {
            console.log("data not valid", key)
          }
          valid = false
        }
      }
    }

    //3) If valid, return the data, else force check the fields for display.
    if (valid) {
      let data = {}
      for (let key in this.state.data) {
        let forget = false
        //Make sure it is not a requiredSometimes
        if (!requiredSometimes.includes(key)) {
          for (let i = 0; i < requiredIf.length; i = i + 1) {
            let rif = requiredIf[i]
            if (rif.require === key) {
              if (!rif.values.includes(this.state.data[rif.field].value)) {
                if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                  forget = true
                }
              }
              break
            }
          }
        }
        if (!forget) {
          if (optionals.includes(key) || requiredFields.includes(key)) {
            //console.log("value", this.state.data[key])
            if ((this.state.data[key].value !== false && this.state.data[key].value !== null) && this.state.data[key].value.toString().length > 0) {
              data[key] = this.state.data[key].value
              if (typeof data[key] === 'string') {
                data[key] = data[key].trim()
              }
            }
          }
        }
      }
      return {
        valid: true,
        data: data
      }
    } else {
      return {
        valid: false,
        data: "Form not valid to submit"
      }
    }
  }

  /*
  * Called when data in an input form changes.
  * This will update the state.data param with the
  * name and new value of the form element.
  */
  formChanged(name, value, valid) {
    this.setState((prevState) => {
      let d = prevState.data
      d[name] = {
        value: value,
        valid: valid
      }
      //check to see if the form is valid to submit
      return {
        data: d,
        forceCheck: false,
        changesMade: true,
      }
    }, () => {
      //check to see if the form is valid to submit
      let res = this.checkForm()
      this.setState({
        valid: res.valid
      }, () => {
        /*if (name === "title" || name === "details") {
          this.searchEmojis()
        } else if (name === "emoji") {
          this.searchLikeEmojis()
        }*/
      })
    })
  }

  /*
  Shakes the button and then removes the class.
  */
  shakeTheButton() {
    this.setState({
      shakeButton: true
    }, () => {
      setTimeout(() => {
        this.setState({
          shakeButton: false
        })
      }, 1000)
    })
  }

  /*
  Get the list of scrapings we have sent.
  */
  getUser(loadAllFraud = false) {
    this.setState({
      loading: true
    }, () => {
      let data = {
        "loadAllFraud": loadAllFraud ? 1 : 0,
      }
      API.callDarwinAPI("GET", "manageUser/" + this.props.tertiaryView, data, async (result) => {
        console.log("manageUser/" + this.props.tertiaryView, result)
        if ("error" in result) {
          //Change the view to 404
          this.props.showPopup("Error", "Couldn't get the user", "Please try again. If this continues to occur, reach out to support@geoji.com")
          this.setState({
            loading: false
          })
          return
        }
        console.log("manageUser", result.data.user)
        let newUser = result.data.user
        if (newUser.accountPermissions === "Admin") {
          newUser.admin = "1"
        } else {
          newUser.admin = "0"
        }

        let newData = {
          payoutDaysAfterEvent: {
            value: result.data.user.payoutDaysAfterEvent || "2",
            valid: true
          },
          plaidLinksLeft: {
            value: result.data.user.plaidLinksLeft || "3",
            valid: true
          },
          payoutMinimumCents: {
            value: (result.data.user.payoutMinimumCents || 2000) / 100.0 + "",
            valid: true
          },
          maxTokenPriceCents: {
            value: (result.data.user.maxTokenPriceCents || 200000) / 100.0 + "",
            valid: true
          },
          statementDescription: {
            value: (result.data.user.statementDescription || ""),
            valid: true
          },
          automatedPayouts: {
            value: (result.data.user.automatedPayouts || "0"),
            valid: true
          }
        }

        this.setState({
          loading: false,
          user: newUser,
          data: newData,
        })
      })
    })
  }

  onToggle(dataName) {

    this.setState((old) => {
      let newUser = old.user
      newUser[dataName] = newUser[dataName] === "1" ? "0" : "1"
      return {
        user: newUser,
        subloadingSettings: true
      }
    }, () => {
      //Now save the update in the API.
      let data = {
      }
      data[dataName] = this.state.user[dataName]
      API.callDarwinAPI("PUT", "manageUser/" + this.props.tertiaryView, data, async (result) => {
        console.log("PUT manageUser/" + this.props.tertiaryView, data, result)
        if ("error" in result) {
          //Change the view to 404
          this.props.showPopup("Error", "Couldn't update the user", "Please try again. If this continues to occur, reach out to support@geoji.com")
          this.setState({
            subloadingSettings: false
          })
          return
        }
        this.setState({
          subloadingSettings: false
        })
      })
    })
  }

  savePaymentSettings(data) {

    //multiply by 100 to get the cents.
    data.payoutMinimumCents = data.payoutMinimumCents * 100
    data.maxTokenPriceCents = data.maxTokenPriceCents * 100
    data.statementDescription = data.statementDescription || ""

    console.log("save payment settings", data)

    this.setState({
      subloadingPaymentSettings: true,
    }, () => {
      API.callDarwinAPI("PUT", "manageUser/" + this.props.tertiaryView, data, async (result) => {
        console.log("PUT manageUser/" + this.props.tertiaryView, data, result)
        if ("error" in result) {
          //Change the view to 404
          this.props.showPopup("Error", "Couldn't update the user", "Please try again. If this continues to occur, reach out to support@geoji.com")
          this.setState({
            subloadingPaymentSettings: false,
          })
          return
        }
        this.setState({
          subloadingPaymentSettings: false,
        })
      })
    })
  }

  viewGeojiLink(geoji) {
    console.log("view geoji", geoji)
    let link = "https://geoji.com/g/" + geoji.geojiID
    window.open(link, "_blank").focus()
  }

  viewGeojiPurchaseLink(geoji) {
    console.log("view geoji", geoji)
    let link = "https://geoji.com/g/" + geoji.geojiID + "/dashboard_" + geoji.purchaseID
    window.open(link, "_blank").focus()
  }

  getBankInfo() {
    //Load this user's bank account information.
    this.setState({
      loadingBankInfo: true
    }, () => {
      let data = {
      }
      API.callDarwinAPI("GET", "manageUserBankInfo/" + this.props.tertiaryView, data, async (result) => {
        console.log("manageUserBankInfo/" + this.props.tertiaryView, result)
        if ("error" in result) {
          //Change the view to 404
          if (result.error === "Plaid Not Linked") {
            this.props.showPopup("Error", "Plaid Not Linked", "This Bank Account Information was most likely collected manually.")
          } else {
            this.props.showPopup("Error", "Couldn't get the bank info", "Please try again. If this continues to occur, reach out to support@geoji.com")
          }
          this.setState({
            loadingBankInfo: false
          })
          return
        }
        this.setState({
          loadingBankInfo: false,
          bankInformation: result.data
        })
      })
    })
  }

  copyToClipboard(data) {
    Helpers.copyToClipboard(data)
    this.props.showToast("Copied Data", "'" + data + "' copied to the clipboard and ready to paste", "black")
  }

  requestBankPayout(method) {
    //1) Request the payout.
    let data = {
      "method": method
    }
    API.callDarwinAPI("POST", "requestBankPayout/" + this.props.tertiaryView, data, async (result) => {
      console.log("requestBankPayout/" + this.props.tertiaryView, result)
      if ("error" in result) {
        //Change the view to 404
        this.props.showPopup("Error", "Couldn't request bank payout.", "Please try again. If this continues to occur, reach out to support@geoji.com")
        return
      }
      //reload the user information.
      this.getUser()
    })
  }

  viewPayout(payoutID) {
    let pps = this.state.user.info.payouts
    for (let j = 0; j < pps.length; j = j + 1) {
      if (pps[j].id === payoutID) {
        if ((pps[j].geojis && pps[j].geojis.length > 0) || (pps[j].adjustments && pps[j].adjustments.length > 0)) {
          this.setState((old) => {
            let newData = old
            for (let i = 0; i < newData.user.info.payouts.length; i = i + 1) {
              if (newData.user.info.payouts[i].id === payoutID) {
                //found it.
                delete newData.user.info.payouts[i].geojis
                delete newData.user.info.payouts[i].adjustments
                delete newData.user.info.payouts[i].ach
                break;
              }
            }
            return newData
          })
          return
        }
        break
      }
    }
    //1) Load the payout information
    let data = {
    }
    API.callDarwinAPI("GET", "payout/" + payoutID, data, async (result) => {
      console.log("payout/" + payoutID, result)
      if ("error" in result) {
        //Change the view to 404
        this.props.showPopup("Error", "Couldn't get payout information.", "Please try again. If this continues to occur, reach out to support@geoji.com")
        return
      }
      //Update the UI.
      this.setState((old) => {
        let newData = old
        for (let i = 0; i < newData.user.info.payouts.length; i = i + 1) {
          if (newData.user.info.payouts[i].id === payoutID) {
            //found it.
            newData.user.info.payouts[i].geojis = result.data.geojis
            newData.user.info.payouts[i].adjustments = result.data.adjustments
            newData.user.info.payouts[i].cuts = result.data.cuts || []
            newData.user.info.payouts[i].ach = result.data.ach
            break;
          }
        }
        return newData
      })
    })
  }

  expandSection(section) {
    this.setState((old) => {
      let changes = {}
      changes[section] = !old[section]
      return changes
    })
  }

  duplicateGeoji(geoji, e) {
    e.stopPropagation()
    console.log("duplicate geoji", geoji.geojiID)
    this.props.showConfirmation("Duplicate Geoji", "Are you sure you want to duplicate - '" + geoji.title + "'?", "Duplicate", "Cancel", (dup) => {
      if (!dup) {
        return
      }
      //Call the API to duplicate this Geoji.
      this.setState({
        loading: true
      }, () => {
        let data = {
          "geojiID": geoji.geojiID
        }
        API.callDarwinAPI("POST", "duplicateGeoji", data, async (result) => {
          console.log("duplicateGeoji", result)
          if ("error" in result) {
            //Change the view to 404
            this.props.showPopup("Error", "Couldn't duplicate the geoji", "Please try again. If this continues to occur, reach out to support@geoji.com")
            this.setState({
              loading: false
            })
            return
          }
          //now get the user information and refresh.
          this.getUser()
        })
      })
    })
  }

  logoutAllDevices() {
    this.props.showConfirmation("Logout all devices", "Are you sure you want to logout all devices for this user?", "Logout Devices", "Cancel", (logout) => {
      if (!logout) {
        return
      }
      //Call the API to logout all devices.
      this.setState({
        loading: true
      }, () => {
        let data = {
        }
        API.callDarwinAPI("POST", "logoutAllDevices/" + this.props.tertiaryView, data, async (result) => {
          console.log("logoutAllDevices/" + this.props.tertiaryView, result)
          if ("error" in result) {
            //Change the view to 404
            this.props.showPopup("Error", "Couldn't logout all devices", "Please try again. If this continues to occur, reach out to support@geoji.com")
            this.setState({
              loading: false
            })
            return
          }
          //now get the user information and refresh.
          this.getUser()
        })
      })
    })
  }

  showCreateBalanceAdjustment() {
    this.setState((old) => {
      return {
        creatingBalanceAdjustment: !old.creatingBalanceAdjustment
      }
    })
  }

  createBalanceAdjustment() {
    if (!this.state.data.totalCents || !this.state.data.totalCents.valid) {
      return
    } else if (!this.state.data.note || !this.state.data.note.valid) {
      return
    }

    this.setState({
      creatingBalanceAdjustment: true
    }, () => {
      let data = {
        totalCents: this.state.data.totalCents.value,
        note: this.state.data.note.value
      }
      API.callDarwinAPI("POST", "balanceAdjustment/" + this.props.tertiaryView, data, async (result) => {
        console.log("balanceAdjustment/" + this.props.tertiaryView, result)
        if ("error" in result) {
          //Change the view to 404
          this.props.showPopup("Error", "Couldn't create the balance adjustment", "Please try again. If this continues to occur, reach out to support@geoji.com")
          this.setState({
            creatingBalanceAdjustment: false,
          })
          return
        }
        this.setState({
          creatingBalanceAdjustment: false,
        }, () => {
          this.getUser()
        })
      })
    })
  }

  openLink(link) {
    window.open(link, "_blank").focus()
  }

  loadAllFraudScores(event) {
    event.stopPropagation()
    //reload the user data.
    this.getUser(true)
  }

  render() {

    return (
      <div className="ManageUser">
        {/* Manage User */}
        { this.state.view === "Home" && this.props.userInfo && this.props.userInfo.user &&
          <div className="FormBlock">
            <div className="ManageUserTitle">
              <Components.DualImage image={ImageClose} onClick={this.props.changeView.bind(this, "ManageUsers")} />
              {(this.state.user && this.state.user.name && this.state.user.name.length > 0) ? this.state.user.name : "Manage User"}
            </div>
            { this.state.loading &&
              <div className="FormLoader" style={{height:"168px"}}>
                <Components.LoadingIndicator color="dark" />
              </div>
            }
            { !this.state.loading && this.state.user &&
              <div className="ManageUserContent">
                {/* User Information */}
                <div className="ManageUserContentTitle">
                  User Information
                </div>
                <div className="ManageUserContentUI">
                  <div className="ManageUserContentUILeft">
                    <img src={this.state.user.profilePicture || ImageProfile} alt="Profile" />
                  </div>
                  <div className="ManageUserContentUIRight">
                    <div className="ManageUserContentUIRightPhoneNumber" onClick={this.copyToClipboard.bind(this, this.state.user.phoneNumber)}>
                      {this.state.user.phoneNumber ? Helpers.parsePhoneNumber(this.state.user.phoneNumber) : "No Phone Number"}
                    </div>
                    <div className="ManageUserContentUIRightEmail" onClick={this.copyToClipboard.bind(this, this.state.user.email)}>
                      {this.state.user.email ? this.state.user.email : "No Email"}
                    </div>
                    <div className="ManageUserContentUIRightAddress" onClick={this.copyToClipboard.bind(this, this.state.user.address)}>
                      {this.state.user.address ? this.state.user.address : "No Address"}
                    </div>
                    <div className="ManageUserContentUIRightBreak">
                    </div>
                    <div className="ManageUserContentUIRightPushNotifications">
                      {this.state.user.pushNotificationToken ? "Push Notifications Enabled" : "Push Notifications Disabled"}
                    </div>
                    <div className="ManageUserContentUIRightBlock">
                      USER ID
                      <br/>
                      <span className="ManageUserContentUIRightBlockValue" onClick={this.copyToClipboard.bind(this, this.state.user.id)}>{this.state.user.id}</span>
                    </div>
                    { this.state.user.stripeCustomerID &&
                      <div className="ManageUserContentUIRightBlock">
                        STRIPE ID
                        <br/>
                        <span className="ManageUserContentUIRightBlockValue" onClick={this.copyToClipboard.bind(this, this.state.user.stripeCustomerID)}>{this.state.user.stripeCustomerID}</span>
                      </div>
                    }
                    { this.state.user.instagramUsername &&
                      <div className="ManageUserContentUIRightBlock">
                        INSTAGRAM
                        <br/>
                        <span className="ManageUserContentUIRightBlockValue" onClick={this.openLink.bind(this, "https://instagram.com/" + this.state.user.instagramUsername)}>{this.state.user.instagramUsername}</span>
                      </div>
                    }
                  </div>
                </div>

                {/* Settings */}
                <div className="ManageUserContentTitle">
                  Settings
                  { this.state.subloadingSettings &&
                    <div className="ManageUserContentSubloader">
                      <Components.DualImage image={ImageLoading} />
                    </div>
                  }
                </div>
                <div className="ManageUserContentSettings">
                  <Components.SettingsToggle name="Can Create Geojis & Tokens" value={this.state.user.canCreateTokens === "1"} onToggle={this.onToggle.bind(this, "canCreateTokens")} />
                  <Components.SettingsToggle name="Can Buy Tokens" value={this.state.user.canBuyTokens === "1"} onToggle={this.onToggle.bind(this, "canBuyTokens")} />
                  <Components.SettingsToggle name="Sandbox Mode" value={this.state.user.sandbox === "1"} onToggle={this.onToggle.bind(this, "sandbox")} />
                  <Components.SettingsToggle name="Admin" value={this.state.user.admin === "1"} onToggle={this.onToggle.bind(this, "admin")} />
                  <Components.SettingsToggle name="Can Link & Cashout Bank Account" value={this.state.user.canCashoutToBank === "1"} onToggle={this.onToggle.bind(this, "canCashoutToBank")} />
                </div>

                {/* Payment Settings */}
                <div className="ManageUserContentTitle">
                  Payment Settings
                  { this.state.subloadingPaymentSettings &&
                    <div className="ManageUserContentSubloader">
                      <Components.DualImage image={ImageLoading} />
                    </div>
                  }
                </div>
                <div className="ManageUserContentPaymentSettings">
                  <div className="InputDiv">
                    <Components.InputBottomLine type="text" name="payoutDaysAfterEvent" placeholder="" validation="positiveNumber" required="true"
                      title="Payout Days After Event" inputType="digits"
                      minimum={0} maximum={10000}
                      value={this.state.data.payoutDaysAfterEvent ? this.state.data.payoutDaysAfterEvent.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                  </div>
                  <div className="InputDiv">
                    <Components.InputBottomLine type="text" name="plaidLinksLeft" placeholder="" validation="positiveNumber" required="true"
                      title="Plaid Links Left" inputType="digits"
                      minimum={0} maximum={10}
                      value={this.state.data.plaidLinksLeft ? this.state.data.plaidLinksLeft.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                  </div>
                  <div className="InputDiv">
                    <Components.InputBottomLine type="text" name="payoutMinimumCents" placeholder="" validation="money" required="true"
                      title="Mimimum Payout"
                      minimum={0.00} maximum={10000.00}
                      value={this.state.data.payoutMinimumCents ? this.state.data.payoutMinimumCents.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                  </div>
                  <div className="InputDiv">
                    <Components.InputBottomLine type="text" name="maxTokenPriceCents" placeholder="" validation="money" required="true"
                      title="Max Token Price"
                      minimum={0.00} maximum={100000.00}
                      value={this.state.data.maxTokenPriceCents ? this.state.data.maxTokenPriceCents.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                  </div>
                  <div className="InputDiv">
                    <Components.InputBottomLine type="text" name="statementDescription" validation="text" required="false"
                      title="Statement Description"
                      minimum={0} maximum={22}
                      value={this.state.data.statementDescription ? this.state.data.statementDescription.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                  </div>
                  <div className="InputDiv">
                    <Components.InputBottomLine type="selection" options={[
                      {
                        value: "0",
                        label: "Off"
                      },
                      {
                        value: "1",
                        label: "Daily"
                      },
                      {
                        value: "7",
                        label: "Weekly"
                      },
                    ]} name="automatedPayouts" placeholder="" validation="text" required="true"
                      title="Automated Payouts"
                      value={this.state.data.automatedPayouts ? this.state.data.automatedPayouts.value : "0"} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                  </div>
                  <Components.GeojiButton type="Dark" onClick={this.submitForm} shake={this.state.shakeButton}>
                    Save Changes
                  </Components.GeojiButton>
                </div>

                {/* Bank Account Information */}
                <div className="ManageUserContentTitle">
                  Bank Information
                </div>
                <div className="ManageUserContentBank">
                  { this.state.user && this.state.user.bankAccountStatus === "1" &&
                    <span>
                      { !this.state.bankInformation &&
                        <Components.GeojiButton type="Dark" onClick={this.getBankInfo.bind(this)} shake={this.state.shakeButton}>
                          Load Bank Information
                        </Components.GeojiButton>
                      }
                      { this.state.bankInformation &&
                        <div className="ManageUserContentBankInfo">
                          { this.state.bankInformation.accounts.map((bank) => (
                            <div className="ManageUserContentBankInfoBank" key={"bank_" + bank.id}>
                              <div className="ManageUserContentBankInfoName">
                                {bank.name} - {bank.type} - {bank.subtype}
                              </div>
                              <div className="ManageUserContentBankInfoAccount">
                                Account #
                                <div className="ManageUserContentBankInfoAccountNumber" onClick={this.copyToClipboard.bind(this, bank.accountNumber)}>
                                  {bank.accountNumber}
                                </div>
                              </div>
                              <div className="ManageUserContentBankInfoRouting">
                                Routing #
                                <div className="ManageUserContentBankInfoRoutingNumber" onClick={this.copyToClipboard.bind(this, bank.routingNumber)}>
                                  {bank.routingNumber}
                                </div>
                              </div>
                              <div className="ManageUserContentBankInfoRouting">
                                Wire Routing #
                                <div className="ManageUserContentBankInfoRoutingNumber" onClick={this.copyToClipboard.bind(this, bank.wireRoutingNumber)}>
                                  {bank.wireRoutingNumber}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      }
                    </span>
                  }
                  { this.state.user && this.state.user.bankAccountStatus === "2" &&
                    <div className="ManageUserContentBankNotLinked">
                      Plaid Re-Login Required
                      <br/>
                      Plaid has expired our access to the bank details.
                      <br/>
                      Send user to the following link to update bank info:
                      <br/>
                      <a className="ManageUserContentBankNotLinkedDetails"
                        href="https://geoji.com/bank"
                        target="_blank" rel="noopener noreferrer">
                        https://geoji.com/bank
                      </a>
                    </div>
                  }
                  { (!this.state.user || this.state.user.bankAccountStatus === "0") &&
                    <div className="ManageUserContentBankNotLinked">
                      Bank Account Not Linked
                      <br/>
                      Send user to the following link:
                      <br/>
                      <a className="ManageUserContentBankNotLinkedDetails"
                        href="https://geoji.com/bank"
                        target="_blank" rel="noopener noreferrer">
                        https://geoji.com/bank
                      </a>
                    </div>
                  }
                </div>

                {/* Balances */}
                <div className="ManageUserContentTitle">
                  Balances
                </div>
                <div className="ManageUserContentInfo">
                  <div className="ManageUserContentInfoBalance ManageUserContentInfoBalanceAvailable">
                    Available Balance: <span className="ManageUserContentInfoBalanceValue">${Helpers.formatNumberDecimals(this.state.user.info.availableBalance.total / 100.0, 2)}</span>
                  </div>
                  <div className="ManageUserContentInfoBalance">
                    Future Balance: <span className="ManageUserContentInfoBalanceValue">${Helpers.formatNumberDecimals(this.state.user.info.futureBalance.total / 100.0, 2)}</span>
                  </div>
                  { this.state.user.info.balanceGeojis.length > 0 &&
                    <div className="ManageUserContentInfoBalanceGeojis">
                      { this.state.user.info.balanceGeojis.map((geo) => (
                        <div className="ManageUserContentInfoBalanceGeoji" key={"balance_geo_" + geo.geojiID} onClick={this.viewGeojiLink.bind(this, geo)}>
                          <div className="ManageUserContentInfoBalanceGeojiEmoji">
                            {geo.emoji}
                          </div>
                          <div className="ManageUserContentInfoBalanceGeojiRight">
                            <div className="ManageUserContentInfoBalanceGeojiRightTitle">
                              {geo.title}
                            </div>
                            <div className="ManageUserContentInfoBalanceGeojiRightAvailableRevenue">
                              Available <span className="ManageUserContentInfoBalanceGeojiRightRevenueValue">${Helpers.formatNumberDecimals(geo.current / 100, 2)}</span>
                            </div>
                            <div className="ManageUserContentInfoBalanceGeojiRightFutureRevenue">
                              Future <span className="ManageUserContentInfoBalanceGeojiRightRevenueValue">${Helpers.formatNumberDecimals(geo.future / 100, 2)}</span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  }
                  { this.state.user.info.balanceCuts && this.state.user.info.balanceCuts.length > 0 &&
                    /** UPDATE TO SHOW THIS IS A CUT! */
                    <div className="ManageUserContentInfoBalanceGeojis">
                      { this.state.user.info.balanceCuts.map((geo) => (
                        <div className="ManageUserContentInfoBalanceGeoji" key={"balance_cut_" + geo.geojiID} onClick={this.viewGeojiLink.bind(this, geo)}>
                          <div className="ManageUserContentInfoBalanceGeojiEmoji">
                            {geo.emoji}
                          </div>
                          <div className="ManageUserContentInfoBalanceGeojiRight">
                            <div className="ManageUserContentInfoBalanceGeojiRightTitle">
                              Revenue Split {geo.kickback}% - {geo.title}
                            </div>
                            <div className="ManageUserContentInfoBalanceGeojiRightAvailableRevenue">
                              Available <span className="ManageUserContentInfoBalanceGeojiRightRevenueValue">${Helpers.formatNumberDecimals(geo.current / 100, 2)}</span>
                            </div>
                            <div className="ManageUserContentInfoBalanceGeojiRightFutureRevenue">
                              Future <span className="ManageUserContentInfoBalanceGeojiRightRevenueValue">${Helpers.formatNumberDecimals(geo.future / 100, 2)}</span>
                            </div>
                            <div className="ManageUserContentInfoBalanceGeojiRightSalesRevenue">
                              Sales <span className="ManageUserContentInfoBalanceGeojiRightRevenueValue">{Helpers.formatNumberDecimals(geo.sales, 0)}</span>
                            </div>
                            <div className="ManageUserContentInfoBalanceGeojiRightSalesRevenue">
                              Total Revenue <span className="ManageUserContentInfoBalanceGeojiRightRevenueValue">${Helpers.formatNumberDecimals(geo.totalCents / 100, 0)}</span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  }
                  <hr/>
                  <div className="ManageUserContentInfoBalance">
                    Lifetime Revenue: <span className="ManageUserContentInfoBalanceValue">${Helpers.formatNumberDecimals(this.state.user.info.lifetimeRevenue.total / 100.0, 2)}</span>
                  </div>
                  { this.state.user.info.adjustments && this.state.user.info.adjustments.length > 0 &&
                    <div className="ManageUserContentSubTitle">
                      Current Balance Adjustments
                    </div>
                  }
                  { this.state.user.info.adjustments && this.state.user.info.adjustments.length > 0 && this.state.user.info.adjustments.map((adjustment, i) => (
                    <div className="ManageUserContentInfoAdjustment" key={"adjustment_" + adjustment.id}>
                      <div className="ManageUserContentInfoAdjustmentDate">
                        {Helpers.formatDateObjectShort(adjustment.creationTimestamp)}
                      </div>
                      <div className="ManageUserContentInfoAdjustmentTotal">
                        ${Helpers.formatNumberDecimals(adjustment.totalCents / 100.0, 2)}
                      </div>
                      <div className="ManageUserContentInfoAdjustmentNote">
                        {adjustment.note}
                      </div>
                    </div>
                  ))}

                  { this.state.creatingBalanceAdjustment &&
                    <div className="ManageUserContentInfoBA">
                      <div className="InputDiv">
                        <Components.InputBottomLine type="text" name="totalCents" placeholder="" validation="number" required="true"
                          title="Amount in Cents" inputType="digits"
                          minimum={-10000000} maximum={10000000}
                          value={this.state.data.totalCents ? this.state.data.totalCents.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                      </div>
                      <div className="InputDiv">
                        <Components.InputBottomLine type="text" name="note" placeholder="" validation="text" required="true"
                          title="Note"
                          value={this.state.data.note ? this.state.data.note.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                      </div>
                      <Components.GeojiButton type="Theme" onClick={this.createBalanceAdjustment.bind(this)}>
                        Create Balance Adjustment
                      </Components.GeojiButton>
                    </div>
                  }
                  { !this.state.creatingBalanceAdjustment &&
                    <Components.GeojiButton type="Theme" onClick={this.showCreateBalanceAdjustment.bind(this)}>
                      Create Balance Adjustment
                    </Components.GeojiButton>
                  }

                  <div className="ManageUserContentSubTitle">
                    Request Payout
                  </div>
                  <Components.GeojiButton type="Dark" onClick={this.requestBankPayout.bind(this, "ACH")}>
                    Request ACH Payout
                  </Components.GeojiButton>
                  <br/><br/>
                  <Components.GeojiButton type="Dark" onClick={this.requestBankPayout.bind(this, "ACHS")}>
                    Request Same Day ACH Payout
                  </Components.GeojiButton>
                  <br/><br/>
                  <Components.GeojiButton type="Dark" onClick={this.requestBankPayout.bind(this, "BankTransfer")}>
                    Request Wire Transfer Payout
                  </Components.GeojiButton>
                  <br/><br/>
                  <Components.GeojiButton type="Dark" onClick={this.requestBankPayout.bind(this, "PayPal")}>
                    Request PayPal Payout
                  </Components.GeojiButton>
                  <br/><br/>
                  <Components.GeojiButton type="Dark" onClick={this.requestBankPayout.bind(this, "Venmo")}>
                    Request Venmo Payout
                  </Components.GeojiButton>
                  <br/><br/>
                </div>

                {/* Payouts */}
                <div className="ManageUserContentTitle" onClick={this.expandSection.bind(this, "payoutsExpanded")}>
                  Payouts <span>({this.state.user.info.payouts.length})</span>
                  { this.state.payoutsExpanded &&
                    <span className="ManageUserContentTitleArrow" role="img" aria-label="down">
                      👇
                    </span>
                  }
                  { !this.state.payoutsExpanded &&
                    <span className="ManageUserContentTitleArrow" role="img" aria-label="right">
                      👉
                    </span>
                  }
                </div>
                { this.state.payoutsExpanded &&
                  <div className="ManageUserContentPayouts">
                    { this.state.user.info.payouts.length > 0 && this.state.user.info.payouts.map((payout, i) => (
                      <div className="ManageUserContentInfoPayout" key={"payout_" + payout.id}>
                        <div className="ManageUserContentInfoPayoutDate" onClick={this.viewPayout.bind(this, payout.id)}>
                          {Helpers.formatDateObjectShort(payout.creationTimestamp)}
                        </div>
                        { payout.payoutStatus === "Requested" &&
                          <div className="ManageUserContentInfoPayoutValue ManageUserContentInfoPayoutValueRequested">
                            ${Helpers.formatNumberDecimals(this.state.user.info.availableBalance.total / 100.0, 2)}
                          </div>
                        }
                        { payout.payoutStatus !== "Requested" &&
                          <div className="ManageUserContentInfoPayoutValue">
                            ${Helpers.formatNumberDecimals(payout.payoutTotal / 100.0, 2)}
                          </div>
                        }
                        <div className={"ManageUserContentInfoPayoutStatus" + (payout.payoutStatus === "Paid" ? " ManageUserContentInfoPayoutStatusPaid" : "")}>
                          {payout.payoutStatus}
                        </div>
                        <div className="ManageUserContentInfoPayoutType">
                          {payout.payoutType}
                        </div>
                        { payout.bankTransferID && payout.bankTransferID.length > 0 &&
                          <div className="ManageUserContentInfoPayoutACHID">
                            #{payout.bankTransferID}
                          </div>
                        }
                        { payout.achStatus && payout.achStatus.length > 0 &&
                          <div className="ManageUserContentInfoPayoutACHStatus">
                            {payout.achStatus}
                          </div>
                        }
                        { payout.achID && payout.achID.length > 0 &&
                          <div className="ManageUserContentInfoPayoutACHID">
                            #{payout.achID}
                          </div>
                        }
                        { payout.ach &&
                          <div className="ManageUserContentInfoPayoutACH">
                            <div className="ManageUserContentInfoPayoutACHEDate">
                              Effective Date {payout.ach.effective_date}
                            </div>
                            <div className="ManageUserContentInfoPayoutACHEService">
                              timing - {payout.ach.service}
                            </div>
                            <div className="ManageUserContentInfoPayoutACHEStatus">
                              status - {payout.ach.status}
                            </div>
                          </div>
                        }
                        { payout.geojis && payout.geojis.length > 0 &&
                          <div className="ManageUserContentInfoPayoutGeojis">
                            { payout.geojis.map((geo) => (
                              <div className="ManageUserContentInfoPayoutGeoji" key={"payout_geo_" + geo.geojiID} onClick={this.viewGeojiLink.bind(this, geo)}>
                                <div className="ManageUserContentInfoPayoutGeojiEmoji">
                                  {geo.emoji}
                                </div>
                                <div className="ManageUserContentInfoPayoutGeojiRight">
                                  <div className="ManageUserContentInfoPayoutGeojiRightTitle">
                                    {geo.title}
                                  </div>
                                  <div className="ManageUserContentInfoPayoutGeojiRightRevenue">
                                    Revenue <span className="ManageUserContentInfoPayoutGeojiRightRevenueValue">${Helpers.formatNumberDecimals(geo.money / 100 , 2)}</span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        }
                        { payout.adjustments && payout.adjustments.length > 0 &&
                          <div className="ManageUserContentInfoPayoutGeojis">
                            { payout.adjustments.map((geo) => (
                              <div className="ManageUserContentInfoPayoutGeoji ManageUserContentInfoPayoutGeojiAdjustment" key={"payout_adj_" + geo.id}>
                                <div className="ManageUserContentInfoPayoutGeojiEmoji">
                                  {geo.emoji ? geo.emoji : "💰"}
                                </div>
                                <div className="ManageUserContentInfoPayoutGeojiRight">
                                  <div className="ManageUserContentInfoPayoutGeojiRightTitle">
                                    {geo.title ? geo.title : "Balance Adjustment"}
                                  </div>
                                  { geo.note && geo.note.length > 0 &&
                                    <div className="ManageUserContentInfoPayoutGeojiRightNotes">
                                      {geo.note}
                                    </div>
                                  }
                                  <div className="ManageUserContentInfoPayoutGeojiRightRevenue">
                                    Revenue <span className="ManageUserContentInfoPayoutGeojiRightRevenueValue">${Helpers.formatNumberDecimals(geo.money / 100 , 2)}</span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        }
                        { payout.cuts && payout.cuts.length > 0 &&
                          <div className="ManageUserContentInfoPayoutGeojis">
                            { payout.cuts.map((geo) => (
                              <div className="ManageUserContentInfoPayoutGeoji ManageUserContentInfoPayoutGeojiAdjustment" key={"payout_adj_" + geo.id}>
                                <div className="ManageUserContentInfoPayoutGeojiEmoji">
                                  {geo.emoji ? geo.emoji : "💰"}
                                </div>
                                <div className="ManageUserContentInfoPayoutGeojiRight">
                                  <div className="ManageUserContentInfoPayoutGeojiRightTitle">
                                    Revenue Split {geo.kickback}% - {geo.title}
                                  </div>
                                  <div className="ManageUserContentInfoPayoutGeojiRightSales">
                                    Sales <span className="ManageUserContentInfoPayoutGeojiRightRevenueValue">{Helpers.formatNumberDecimals(geo.sales, 0)}</span>
                                  </div>
                                  <div className="ManageUserContentInfoPayoutGeojiRightSales">
                                    Total Revenue <span className="ManageUserContentInfoPayoutGeojiRightRevenueValue">${Helpers.formatNumberDecimals(geo.totalCents / 100 , 2)}</span>
                                  </div>
                                  <div className="ManageUserContentInfoPayoutGeojiRightRevenue">
                                    Cut <span className="ManageUserContentInfoPayoutGeojiRightRevenueValue">${Helpers.formatNumberDecimals(geo.money / 100 , 2)}</span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        }
                      </div>
                    ))}
                    { this.state.user.info.payouts.length === 0 &&
                      <div className="ManageUserContentInfoPayoutEmpty">
                        No Payouts for this User.
                      </div>
                    }
                  </div>
                }
                <br/>

                {/* Geojis */}
                <div className="ManageUserContentTitle" onClick={this.expandSection.bind(this, "geojisExpanded")}>
                  Geojis <span>({this.state.user.info.geojis.length})</span>
                  { this.state.geojisExpanded &&
                    <span className="ManageUserContentTitleArrow" role="img" aria-label="down">
                      👇
                    </span>
                  }
                  { !this.state.geojisExpanded &&
                    <span className="ManageUserContentTitleArrow" role="img" aria-label="right">
                      👉
                    </span>
                  }
                </div>
                { this.state.geojisExpanded &&
                  <div className="ManageUserContentGeojis">
                    { this.state.user.info.geojis.length > 0 && this.state.user.info.geojis.map((geoji, i) => (
                      <div className="ManageUserContentGeoji" key={"geoji_" + geoji.geojiID}>
                        <div className="ManageUserContentGeojiEmoji" onClick={this.viewGeojiLink.bind(this, geoji)}>
                          {geoji.emoji}
                        </div>
                        <div className="ManageUserContentGeojiRight">
                          <div className="ManageUserContentGeojiRightTitle">
                            {geoji.title}
                            {/* Button to duplicate the event */}
                            <div className="ManageUserContentGeojiRightDuplicate" onClick={this.duplicateGeoji.bind(this, geoji)}>
                              Duplicate
                            </div>
                          </div>
                          <div className="ManageUserContentGeojiRightRevenue">
                            Revenue <span className={"ManageUserContentGeojiRightRevenueValue" + ((geoji.revenue > 0 && geoji.profitCut === "1") ? " ManageUserContentGeojiRightRevenueValueGreen" : "")}>${Helpers.formatNumberDecimals(geoji.revenue / 100 , 2)}</span>
                          </div>
                          { geoji.profitCut !== "1" &&
                            <div className="ManageUserContentGeojiRightSplit">
                              Profit Share
                              <span className="ManageUserContentGeojiRightSplitValue">{Helpers.formatNumberDecimals(geoji.profitCut * 100 , 1)}%</span>
                              <span className="ManageUserContentGeojiRightSplitValue2">${Helpers.formatNumberDecimals(geoji.profitCut * geoji.revenue / 100 , 2)}</span>
                            </div>
                          }
                          { geoji.fraudScore &&
                            <div className="ManageUserContentGeojiRightFraud">
                              <div className="ManageUserContentGeojiRightFraudLeft">
                                <img src={Helpers.fraudScoreImage(geoji.fraudScore)} alt="FS" />
                              </div>
                              <div className="ManageUserContentGeojiRightFraudRight">
                                <div className="ManageUserContentGeojiRightFraudMetric">
                                    Failure: {Helpers.formatNumberDecimals(geoji.fraudScore.failureRate * 100, 2)}% ({geoji.fraudScore.failures} / {geoji.fraudScore.total})
                                </div>
                                <div className="ManageUserContentGeojiRightFraudMetric">
                                    Disputes: {Helpers.formatNumberDecimals(geoji.fraudScore.disputeRate * 100, 2)}% ({geoji.fraudScore.disputes} / {geoji.fraudScore.subtotal})
                                </div>
                              </div>
                            </div>
                          }
                          { !geoji.fraudScore &&
                            <div className="ManageUserContentGeojiRightNoFraud" onClick={this.loadAllFraudScores.bind(this)}>
                              Load Fraud Score
                            </div>
                          }
                        </div>
                      </div>
                    ))}
                    { this.state.user.info.geojis.length === 0 &&
                      <div className="ManageUserContentInfoPayoutEmpty">
                        No Geojis created by this user.
                      </div>
                    }
                  </div>
                }
                <br/>

                {/* Purchases */}
                <div className="ManageUserContentTitle" onClick={this.expandSection.bind(this, "purchasesExpanded")}>
                  Purchases <span>({this.state.user.info.purchases.length})</span>
                  { this.state.purchasesExpanded &&
                    <span className="ManageUserContentTitleArrow" role="img" aria-label="down">
                      👇
                    </span>
                  }
                  { !this.state.purchasesExpanded &&
                    <span className="ManageUserContentTitleArrow" role="img" aria-label="right">
                      👉
                    </span>
                  }
                </div>
                { this.state.purchasesExpanded &&
                  <div className="ManageUserContentGeojis">
                    { this.state.user.info.purchases.length > 0 && this.state.user.info.purchases.map((geoji, i) => (
                      <div className="ManageUserContentGeoji" key={"geoji_" + geoji.purchaseID} onClick={this.viewGeojiPurchaseLink.bind(this, geoji)}>
                        <div className="ManageUserContentGeojiEmoji">
                          {geoji.emoji}
                        </div>
                        <div className="ManageUserContentGeojiRight">
                          <div className="ManageUserContentGeojiRightTitle">
                            {geoji.title}
                          </div>
                          <div className="ManageUserContentGeojiRightDate">
                            {Helpers.formatDateObjectMid(geoji.purchaseTimestamp)}
                          </div>
                          <div className="ManageUserContentGeojiRightDescription">
                            {geoji.purchaseDescription}
                          </div>
                          { geoji.purchaseTotalCents === "0" &&
                            <div className="ManageUserContentGeojiRightRevenue">
                              Free
                            </div>
                          }
                          { geoji.purchaseTotalCents !== "0" &&
                            <div className="ManageUserContentGeojiRightRevenue">
                              ${Helpers.formatNumberDecimals(geoji.purchaseTotalCents / 100.0, 2)}
                            </div>
                          }
                        </div>
                      </div>
                    ))}
                    { this.state.user.info.purchases.length === 0 &&
                      <div className="ManageUserContentInfoPayoutEmpty">
                        No Purchases by this user.
                      </div>
                    }
                  </div>
                }
                <br/>

                {/* Possible Purchases */}
                <div className="ManageUserContentTitle" onClick={this.expandSection.bind(this, "possiblePurchasesExpanded")}>
                  In Person Purchases <span>({this.state.user.info.possiblePurchases.length})</span>
                  { this.state.possiblePurchasesExpanded &&
                    <span className="ManageUserContentTitleArrow" role="img" aria-label="down">
                      👇
                    </span>
                  }
                  { !this.state.possiblePurchasesExpanded &&
                    <span className="ManageUserContentTitleArrow" role="img" aria-label="right">
                      👉
                    </span>
                  }
                </div>
                { this.state.possiblePurchasesExpanded &&
                  <div className="ManageUserContentGeojis">
                    { this.state.user.info.possiblePurchases.length > 0 && this.state.user.info.possiblePurchases.map((geoji, i) => (
                      <div className="ManageUserContentGeoji" key={"geoji_" + geoji.purchaseID} onClick={this.viewGeojiPurchaseLink.bind(this, geoji)}>
                        <div className="ManageUserContentGeojiEmoji">
                          {geoji.emoji}
                        </div>
                        <div className="ManageUserContentGeojiRight">
                          <div className="ManageUserContentGeojiRightTitle">
                            {geoji.title}
                          </div>
                          <div className="ManageUserContentGeojiRightDate">
                            {Helpers.formatDateObjectMid(geoji.purchaseTimestamp)}
                          </div>
                          <div className="ManageUserContentGeojiRightDescription">
                            {geoji.purchaseDescription}
                          </div>
                          { geoji.purchaseTotalCents === "0" &&
                            <div className="ManageUserContentGeojiRightRevenue">
                              Free
                            </div>
                          }
                          { geoji.purchaseTotalCents !== "0" &&
                            <div className="ManageUserContentGeojiRightRevenue">
                              ${Helpers.formatNumberDecimals(geoji.purchaseTotalCents / 100.0, 2)}
                            </div>
                          }
                        </div>
                      </div>
                    ))}
                    { this.state.user.info.purchases.length === 0 &&
                      <div className="ManageUserContentInfoPayoutEmpty">
                        No Purchases by this user.
                      </div>
                    }
                  </div>
                }
                <br/>

                {/* Sessions */}
                <div className="ManageUserContentTitle" onClick={this.expandSection.bind(this, "sessionsExpanded")}>
                  Sessions <span>({this.state.user.info.sessions.length})</span>
                  { this.state.sessionsExpanded &&
                    <span className="ManageUserContentTitleArrow" role="img" aria-label="down">
                      👇
                    </span>
                  }
                  { !this.state.sessionsExpanded &&
                    <span className="ManageUserContentTitleArrow" role="img" aria-label="right">
                      👉
                    </span>
                  }
                </div>
                { this.state.sessionsExpanded &&
                  <div className="ManageUserContentGeojis">
                    { this.state.user.info.sessions.length > 0 && this.state.user.info.sessions.map((session, i) => (
                      <div className="ManageUserContentSession" key={"session_" + session.id}>
                        <div className="ManageUserContentSessionDate">
                          {Helpers.formatDateObjectLong(session.creationTimestamp)}
                        </div>
                        <br/>
                        <div className="ManageUserContentSessionBrowser">
                          {session.operatingSystem} {session.browser}
                        </div>
                        <br/>
                        <a href={"https://maps.google.com/?q=" + session.latitude + "," + session.longitude} target="_blank" rel="noopener noreferrer">
                          <div className="ManageUserContentSessionIPAddress">
                            {session.city}, {session.region} {session.zip} {session.country} – IP: {session.ipAddress}
                          </div>
                        </a>
                        <br/>
                        <div className="ManageUserContentSessionUserAgent">
                          {session.userAgent} <span>{session.refreshing === "1" ? "Refreshed" : "Original"}</span>
                        </div>
                      </div>
                    ))}
                    { this.state.user.info.sessions.length === 0 &&
                      <div className="ManageUserContentInfoPayoutEmpty">
                        No Sessions by this user. They probably need to update their app to capture sessions.
                      </div>
                    }
                  </div>
                }
                <br/>

                {/* Login Attempts */}
                <div className="ManageUserContentTitle" onClick={this.expandSection.bind(this, "loginAttemptsExpanded")}>
                  Login Attempts <span>({this.state.user.info.loginAttempts.length})</span>
                  { this.state.loginAttemptsExpanded &&
                    <span className="ManageUserContentTitleArrow" role="img" aria-label="down">
                      👇
                    </span>
                  }
                  { !this.state.loginAttemptsExpanded &&
                    <span className="ManageUserContentTitleArrow" role="img" aria-label="right">
                      👉
                    </span>
                  }
                </div>
                { this.state.loginAttemptsExpanded &&
                  <div className="ManageUserContentGeojis">
                    { this.state.user.info.loginAttempts.length > 0 && this.state.user.info.loginAttempts.map((session, i) => (
                      <div className="ManageUserContentSession" key={"session_" + session.id}>
                        <div className="ManageUserContentSessionDate">
                          {Helpers.formatDateObjectLong(session.creationTimestamp)}
                        </div>
                        <br/>
                        <div className="ManageUserContentSessionBrowser">
                          {session.operatingSystem} {session.browser}
                        </div>
                        <br/>
                        <a href={"https://maps.google.com/?q=" + session.latitude + "," + session.longitude} target="_blank" rel="noopener noreferrer">
                          <div className="ManageUserContentSessionIPAddress">
                            {session.city}, {session.region} {session.zip} {session.country} – IP: {session.ipAddress}
                          </div>
                        </a>
                        <br/>
                        <div className="ManageUserContentSessionUserAgent">
                          {session.userAgent}
                        </div>
                      </div>
                    ))}
                    { this.state.user.info.sessions.length === 0 &&
                      <div className="ManageUserContentInfoPayoutEmpty">
                        No Login Attempts by this user. They probably need to update their app to capture attempts.
                      </div>
                    }
                  </div>
                }
                <br/>

                <Components.GeojiButton type="Delete" onClick={this.logoutAllDevices.bind(this)}>
                  Logout All Devices
                </Components.GeojiButton>
                <br/><br/>
              </div>
            }
            { !this.state.loading && !this.state.user &&
              <div className="ManageUserContent">
                <div className="ManageUserContentError">
                  We could not find this user
                </div>
              </div>
            }
          </div>
        }
      </div>
    )
  }
}
