import React from 'react';
import autoBind from 'react-autobind';

import * as Components from '../Components.js';
//import * as Generation from '../Generation.js';
import * as Helpers from '../Helpers.js';

//import {DateObject} from "react-multi-date-picker";


//import the Darwin API classes
import API from '../API.js';

import ImageClose from '../images/Icons/Close.svg'
//import ImageCheckmark from '../images/Icons/Checkmark.svg'
//import ImageUncheckmark from '../images/Icons/Uncheckmark.svg'

export class CreateGeoji extends React.Component {

  constructor(props) {
    super(props)

    let data = {
      "emoji": {
        value: "🟥",
        valid: true
      }
    }

    this.state = {
      loading: false,
      data: data,
      forceCheck: false,
      shakeButton: false,
      changesMade: false, //have any changes been made

      view: "Create",
      subview: "Home",
      prompt: false,

      searchIndex: 0,
      searchLikeIndex: 0,
      emojis: [],
      likeEmojis: [],
    }
    autoBind(this)
  }

  componentDidMount() {
    //calculate the view
    this.calculateView()
  }

  componentDidUpdate(prevProps) {
    if (this.props.render !== prevProps.render) {
      //reload the home view.
      this.calculateView()
    }
  }

  calculateView() {
    //set the data
    if (this.state.view === "Create") {
      this.searchEmojis()
    }
  }

  /*
  * Create the new Geoji.
  */
  createGeoji(data) {
    //format the dates
    let dateString = ""
    for (let i = 0; i < data.dates.length; i = i + 1) {
      if (dateString.length > 0) {
        dateString = dateString + ","
      }
      //add the date
      dateString = dateString + data.dates[i].format("YYYY-MM-DD")
    }
    data.dates = dateString

    this.setState({
      loading: true
    }, () => {
      API.callDarwinAPI("POST", "geoji", data, async (result) => {
        console.log("POST geoji", result)
        if ("error" in result) {
          //Change the view to 404
          this.props.showPopup("Error", "Couldn't create the Geoji", "Please try again. If this continues to occur, reach out to support@geoji.com")
          this.setState({
            loading: false
          })
          return
        }
        this.setState({
          loading: false,
          view: "Created"
        })
      })
    })
  }

  /*
  * Submits the form
  */
  submitForm() {
    let results = this.checkForm(true)
    if (results.valid) {
      switch (this.state.view) {
        case "Create":
          this.createGeoji(results.data)
          break;
        default:
          console.error("Unhandled submitForm", this.state.view)
          break;
      }
    } else {
      console.log("Form not valid to submit")
      this.setState({
        forceCheck: true
      })
      //Shake the submit button as it is invalid to submit
      this.shakeTheButton()
    }
  }

  /*
  Checks the form to make sure it is valid.
  Returns {valid:Bool, data:{}}
  */
  checkForm(print = false) {
    //1) Make sure we have all of the data.
    let valid = true
    let requiredFields = []
    let requiredIf = []
    let requiredSometimes = [] //if set, then don't remove it from the data.
    let optionals = [] //the optional fields apart from the requiredFields.
    switch (this.state.view) {
      case "Create":
        requiredFields = ["address", "emoji", "title", "dates"]
        optionals = ["when", "photo", "website", "details"]
        requiredIf = []
        requiredSometimes = []
        break;
      default:
        break;
    }
    requiredFields.forEach((element) => {
      if (this.state.data[element] === undefined || this.state.data[element].value === undefined) {
        //the field is not filled out
        if (print) {
          console.log("required not filled out: ", element)
        }
        valid = false
      }
    })
    requiredIf.forEach((condition) => {
      if (this.state.data[condition.field] === undefined || this.state.data[condition.field].value === undefined) {
        //the condition field is not filled out
        if (print) {
          console.log("condition field is not filled out: ", condition)
        }
        valid = false
      } else if (condition.values !== undefined && condition.values.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          if (print) {
            console.log("rif field is required and is not filled out: ", condition)
          }
          valid = false
        }
      } else if (condition.not !== undefined && !condition.not.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          if (print) {
            console.log("rif field is required with not and is not filled out: ", condition)
          }
          valid = false
        }
      }
    })
    //2) Make sure every data element is valid.
    for (let key in this.state.data) {
      let forget = false
      //Make sure it is not a requiredSometimes
      if (!requiredSometimes.includes(key)) {
        // Check the requiredIf conditions
        for (let i = 0; i < requiredIf.length; i = i + 1) {
          let rif = requiredIf[i]
          if (rif.require === key) {
            if (!rif.values.includes(this.state.data[rif.field].value)) {
              if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                forget = true
              }
            }
            break
          }
        }
      }
      //Make sure this is a field we are looking for.
      if (optionals.includes(key) || requiredFields.includes(key)) {
        if (!forget && this.state.data[key].valid === false) {
          if (print) {
            console.log("data not valid", key)
          }
          valid = false
        }
      }
    }

    //3) If valid, return the data, else force check the fields for display.
    if (valid) {
      let data = {}
      for (let key in this.state.data) {
        let forget = false
        //Make sure it is not a requiredSometimes
        if (!requiredSometimes.includes(key)) {
          for (let i = 0; i < requiredIf.length; i = i + 1) {
            let rif = requiredIf[i]
            if (rif.require === key) {
              if (!rif.values.includes(this.state.data[rif.field].value)) {
                if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                  forget = true
                }
              }
              break
            }
          }
        }
        if (!forget) {
          if (optionals.includes(key) || requiredFields.includes(key)) {
            if (this.state.data[key].value !== false && this.state.data[key].value.toString().length > 0) {
              data[key] = this.state.data[key].value
              if (typeof data[key] === 'string') {
                data[key] = data[key].trim()
              }
            }
          }
        }
      }
      return {
        valid: true,
        data: data
      }
    } else {
      return {
        valid: false,
        data: "Form not valid to submit"
      }
    }
  }

  /*
  * Called when data in an input form changes.
  * This will update the state.data param with the
  * name and new value of the form element.
  */
  formChanged(name, value, valid) {
    //console.log(name, value, valid);
    this.setState((prevState) => {
      let d = prevState.data
      d[name] = {
        value: value,
        valid: valid
      }
      return {
        data: d,
        forceCheck: false,
        changesMade: true,
      }
    }, () => {
      //check to see if the form is valid to submit
      let res = this.checkForm()
      this.setState({
        valid: res.valid
      }, () => {
        if (name === "title" || name === "details") {
          this.searchEmojis()
        } else if (name === "emoji") {
          this.searchLikeEmojis()
        }
      })
    })
  }

  /*
  Shakes the button and then removes the class.
  */
  shakeTheButton() {
    this.setState({
      shakeButton: true
    }, () => {
      setTimeout(() => {
        this.setState({
          shakeButton: false
        })
      }, 1000)
    })
  }

  /*
  An emoji has been selected from the first list.
  */
  emojiSelected(emoji) {
    this.formChanged("emoji", emoji, true)
  }

  /*
  Searches for similar Emojis.
  */
  searchLikeEmojis() {
    let si = 0
    this.setState((old) => {
      si = old.searchLikeIndex + 1
      return {
        searchLikeIndex: si
      }
    }, () => {
      setTimeout(() => {
        this.searchLikeEmojis2(si)
      }, 300)
    })
  }

  /*
  Searches for similar Emojis.
  */
  searchLikeEmojis2(indi) {
    if (this.state.searchLikeIndex !== indi) {
      //console.log("Search Interupted")
      return
    }
    //console.log("Searching Emojis")
    let search = (this.state.data.emoji ? this.state.data.emoji.value : "")
    let emojis = Helpers.searchLikeEmojis(search, 30).map((emo) => {
      return emo[0]
    })
    this.setState({
      likeEmojis: emojis
    })
  }

  /*
  Starts the searching for the emojis.
  */
  searchEmojis() {
    let si = 0
    this.setState((old) => {
      si = old.searchIndex + 1
      return {
        searchIndex: si
      }
    }, () => {
      setTimeout(() => {
        this.searchEmojis2(si)
      }, 300)
    })
  }

  /*
  Actually searches for the emojis and returns the results.
  */
  searchEmojis2(indi) {
    if (this.state.searchIndex !== indi) {
      //console.log("Search Interupted")
      return
    }
    //console.log("Searching Emojis")
    let search = (this.state.data.title ? this.state.data.title.value : "") + " " + (this.state.data.details ? this.state.data.details.value : "")
    if (search === " ") {
      search = "bar event event party stadium music comedy"
    }
    let emojis = Helpers.searchEmojis(search, 30).map((emo) => {
      return emo[0]
    })
    this.setState({
      emojis: emojis
    })
  }

  /*
  * Goes to the create view again.
  */
  createAnother() {
    this.setState({
      data: {
        "emoji": {
          value: "🟥",
          valid: true
        }
      },
      view: "Create"
    })
  }

  /*
  * Goes back to the home page.
  */
  showHome() {
    this.props.changeView("Home")
  }

  /*
  A Google Address was selected, let's update the fields
  */
  googleAddressSelected(address) {
    console.log("addressSelected:", address)
    this.formChanged("address", address, true)
  }

  render() {

    return (
      <div className="CreateGeoji">
        {/* Create Geoji View */}
        { this.state.view === "Create" &&
          <div className="FormBlock">
            <div className="CreateGeojiTitle">
              <Components.DualImage image={ImageClose} onClick={this.showHome} />
              Create Geoji
            </div>
            { this.state.loading &&
              <div className="FormLoader" style={{height:"168px"}}>
                <Components.LoadingIndicator color="dark" />
              </div>
            }
            { !this.state.loading &&
              <div className="CreateGeojiContent">
                {/* Address */}
                <div className="InputDiv">
                  <Components.InputBottomLine type="googleAddress" name="address" placeholder="" validation="text" required="true"
                    title="Address" maximum={100} addressSelected={this.googleAddressSelected}
                    value={this.state.data.address ? this.state.data.address.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                </div>
                {/* Emoji */}
                <div className="InputDiv">
                  <Components.InputBottomLine type="text" name="emoji" validation="text" required="true" maximum={100} placeholder=""
                    title="Emoji - type to search"
                    value={this.state.data.emoji ? this.state.data.emoji.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                </div>
                <Components.EmojiList emojis={this.state.emojis} onSelect={this.emojiSelected} />
                { this.state.likeEmojis.length > 0 &&
                  <Components.EmojiList emojis={this.state.likeEmojis} onSelect={this.emojiSelected} style={{marginTop:"8px"}} title="Similar" />
                }

                {/* Title */}
                <div className="InputDiv">
                  <Components.InputBottomLine type="text" name="title" validation="text" required="true" maximum={255} placeholder=""
                    title="Title"
                    value={this.state.data.title ? this.state.data.title.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                </div>
                {/* Calendar */}
                <div className="InputDiv">
                  <Components.InputBottomLine type="multipledates" name="dates" validation="multipledates" required="true"
                    title="Event Dates"
                    value={this.state.data.dates ? this.state.data.dates.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                </div>
                {/* When */}
                <div className="InputDiv">
                  <Components.InputBottomLine type="text" name="when" validation="text" required="false" maximum={255} placeholder=""
                    title="When"
                    value={this.state.data.when ? this.state.data.when.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                </div>
                {/* Photo */}
                <div className="InputDiv">
                  <Components.InputBottomLine type="file" name="photo" placeholder="" validation="file" required="false"
                    title="Photo" description=""
                    APIName="userFiles" APIUploadName="userFiles" showPopup={this.props.showPopup} maxSize={1920}
                    value={this.state.data.photo ? this.state.data.photo.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                </div>
                {/* Website */}
                <div className="InputDiv">
                  <Components.InputBottomLine type="text" name="website" validation="text" required="false" maximum={255} placeholder=""
                    title="Website"
                    value={this.state.data.website ? this.state.data.website.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                </div>
                {/* Details */}
                <div className="InputDiv">
                  <Components.InputBottomLine type="textarea" name="details" validation="text" required="false" maximum={4000} placeholder=""
                    title="Details"
                    value={this.state.data.details ? this.state.data.details.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                </div>

                {/* Submit Button */}
                <div className="ButtonDiv">
                  <Components.GeojiButton type="Theme2" onClick={this.submitForm} shake={this.state.shakeButton}>
                    Create
                  </Components.GeojiButton>
                </div>
              </div>
            }
          </div>
        }
        {/* The Geoji has been created - show an interstitial screen. */}
        { this.state.view === "Created" &&
          <div className="CreatedGeoji">
            <div className="CreatedGeojiTitle">
              Geoji Created
            </div>
            <div className="CreatedGeojiDescription">
              <span role="img" aria-label="party">🎉</span> It is now visible on the map. <span role="img" aria-label="party">🎉</span>
            </div>
            <div className="ButtonDiv">
              <Components.GeojiButton type="Theme2" onClick={this.createAnother}>
                Create Another
              </Components.GeojiButton>
            </div>
          </div>
        }
      </div>
    )
  }
}
