import React from 'react';
import autoBind from 'react-autobind';

import * as Components from '../Components.js';
//import * as Generation from '../Generation.js';
import * as Helpers from '../Helpers.js';

import {DateObject} from "react-multi-date-picker";


//import the Darwin API classes
import API from '../API.js';

import ImageClose from '../images/Icons/Close.svg'
import ImageMagnifyingGlass from '../images/Icons/MagnifyingGlass.svg'
// import ImageCheckmark from '../images/Icons/Checkmark.svg'
// import ImageUncheckmark from '../images/Icons/Uncheckmark.svg'

export class WebScrapings extends React.Component {

  constructor(props) {
    super(props)

    let data = {
      "type": {
        value: "",
        valid: false
      },
      "config": {
        value: "",
        valid: false
      },
      "when": {
        value: "",
        valid: false
      },
      "nextPageIndex": {
        value: "1",
        valid: true
      },
      "finishIndex": {
        value: "50",
        valid: true
      },
    }

    this.state = {
      loading: false,
      data: data,
      forceCheck: false,
      shakeButton: false,
      changesMade: false, //have any changes been made

      view: "Home",
      subview: "Home",
      prompt: false,

      webScrapings: [],
      filter: "All", //the filter to apply
      sort: "ID", //the sort to apply
      sortDirection: "DESC", //DESC or ASC
      limit: 30, //the number of results per page.
      page: 0, //the page of results.
      pageMax: 0, //the max page.
      resultsCount: 0, //the number of results.

      scrapeSchedule: [],
      scrapeSchedulePage: 0,
      scrapeSchedulePageMax: 0,
      scrapeScheduleResultsCount: 0,
      scheduleSort: "ID",
      scheduleSortDirection: "DESC",

      emojiTagLimit: 500,
      emojiTagPage: 0,
      emojiTagPageMax: 1,
      emojiTagResultsCount: 1,
      emojiTags: [],
      emojiTagSort: "Count",
      emojiTagSortDirection: "DESC",
      emojiTagFilter: "All",
      emojiTagCountsUnset: 0,
      emojiTagCountsTotal: 0,
      emojiTagCountsEmpty: 0,
    }
    autoBind(this)
  }

  componentDidMount() {
    //calculate the view
    this.calculateView()
  }

  componentDidUpdate(prevProps) {
    if (this.props.render !== prevProps.render) {
      //reload the home view.
      this.calculateView()
    }
  }

  calculateView() {
    //set the data
    if (this.state.view === "Home") {
      //load the abilities for this user.
      this.getScrapings()
    }
  }

  createScraping(data) {
    this.setState({
      loading: true
    }, () => {
      API.callDarwinAPI("POST", "webScraping", data, async (result) => {
        console.log("POST webScraping", result)
        if ("error" in result) {
          //Change the view to 404
          this.props.showPopup("Error", "Couldn't create the web scraping", "Please try again. If this continues to occur, reach out to support@geoji.com")
          this.setState({
            loading: false
          })
          return
        }
        this.setState({
          loading: false,
          view: "Home"
        }, () => {
          this.getScrapings()
        })
      })
    })
  }

  createScrapingSchedule(data) {
    this.setState({
      loading: true
    }, () => {
      API.callDarwinAPI("POST", "scheduleWebScraping", data, async (result) => {
        console.log("POST scheduleWebScraping", result)
        if ("error" in result) {
          //Change the view to 404
          this.props.showPopup("Error", "Couldn't schedule the web scraping", "Please try again. If this continues to occur, reach out to support@geoji.com")
          this.setState({
            loading: false
          })
          return
        }
        this.showScheduleScrapes()
      })
    })
  }

  /*
  * Submits the form
  */
  submitForm() {
    let results = this.checkForm(true)
    if (results.valid) {
      switch (this.state.view) {
        case "Create":
          this.createScraping(results.data)
          break;
        case "NewSchedule":
          this.createScrapingSchedule(results.data)
          break;
        default:
          console.error("Unhandled submitForm", this.state.view)
          break;
      }
    } else {
      console.log("Form not valid to submit")
      this.setState({
        forceCheck: true
      })
      //Shake the submit button as it is invalid to submit
      this.shakeTheButton()
    }
  }

  /*
  Checks the form to make sure it is valid.
  Returns {valid:Bool, data:{}}
  */
  checkForm(print = false) {
    //1) Make sure we have all of the data.
    let valid = true
    let requiredFields = []
    let requiredIf = []
    let requiredSometimes = [] //if set, then don't remove it from the data.
    let optionals = [] //the optional fields apart from the requiredFields.
    switch (this.state.view) {
      case "Create":
        requiredFields = ["type", "baseURL", "finishIndex", "nextPageIndex"]
        optionals = []
        requiredIf = []
        requiredSometimes = []
        break;
      case "NewSchedule":
        requiredFields = ["type", "when", "baseURL", "finishIndex", "nextPageIndex"]
        optionals = []
        requiredIf = []
        requiredSometimes = []
        break;
      default:
        break;
    }
    requiredFields.forEach((element) => {
      if (this.state.data[element] === undefined || this.state.data[element].value === undefined) {
        //the field is not filled out
        if (print) {
          console.log("required not filled out: ", element)
        }
        valid = false
      }
    })
    requiredIf.forEach((condition) => {
      if (this.state.data[condition.field] === undefined || this.state.data[condition.field].value === undefined) {
        //the condition field is not filled out
        if (print) {
          console.log("condition field is not filled out: ", condition)
        }
        valid = false
      } else if (condition.values !== undefined && condition.values.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          if (print) {
            console.log("rif field is required and is not filled out: ", condition)
          }
          valid = false
        }
      } else if (condition.not !== undefined && !condition.not.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          if (print) {
            console.log("rif field is required with not and is not filled out: ", condition)
          }
          valid = false
        }
      }
    })
    //2) Make sure every data element is valid.
    for (let key in this.state.data) {
      let forget = false
      //Make sure it is not a requiredSometimes
      if (!requiredSometimes.includes(key)) {
        // Check the requiredIf conditions
        for (let i = 0; i < requiredIf.length; i = i + 1) {
          let rif = requiredIf[i]
          if (rif.require === key) {
            if (!rif.values.includes(this.state.data[rif.field].value)) {
              if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                forget = true
              }
            }
            break
          }
        }
      }
      //Make sure this is a field we are looking for.
      if (optionals.includes(key) || requiredFields.includes(key)) {
        if (!forget && this.state.data[key].valid === false) {
          if (print) {
            console.log("data not valid", key)
          }
          valid = false
        }
      }
    }

    //3) If valid, return the data, else force check the fields for display.
    if (valid) {
      let data = {}
      for (let key in this.state.data) {
        let forget = false
        //Make sure it is not a requiredSometimes
        if (!requiredSometimes.includes(key)) {
          for (let i = 0; i < requiredIf.length; i = i + 1) {
            let rif = requiredIf[i]
            if (rif.require === key) {
              if (!rif.values.includes(this.state.data[rif.field].value)) {
                if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                  forget = true
                }
              }
              break
            }
          }
        }
        if (!forget) {
          if (optionals.includes(key) || requiredFields.includes(key)) {
            if (this.state.data[key].value !== false && this.state.data[key].value.toString().length > 0) {
              data[key] = this.state.data[key].value
              if (typeof data[key] === 'string') {
                data[key] = data[key].trim()
              }
            }
          }
        }
      }
      return {
        valid: true,
        data: data
      }
    } else {
      return {
        valid: false,
        data: "Form not valid to submit"
      }
    }
  }

  /*
  * Called when data in an input form changes.
  * This will update the state.data param with the
  * name and new value of the form element.
  */
  formChanged(name, value, valid) {
    //console.log(name, value, valid);
    this.setState((prevState) => {
      let d = prevState.data
      d[name] = {
        value: value,
        valid: valid
      }
      if ((prevState.view === "Create" || prevState.view === "NewSchedule") && name === "type") {
        let defaults = Helpers.getScrapingConfigs(value)
        //select the first default.
        let config = defaults[0]
        for (let key in config) {
          d[key] = {
            value: config[key],
            valid: true
          }
        }
      } else if ((prevState.view === "Create" || prevState.view === "NewSchedule") && name === "config") {
        //get the config
        let defaults = Helpers.getScrapingConfigs(d["type"]["value"])
        let config = false
        for (let i = 0; i < defaults.length; i = i + 1) {
          if (defaults[i]["config"] === value) {
            config = defaults[i]
            break
          }
        }
        if (config !== false) {
          for (let key in config) {
            d[key] = {
              value: config[key],
              valid: true
            }
          }
        }
      }
      return {
        data: d,
        forceCheck: false,
        changesMade: true,
      }
    }, () => {
      //check to see if the form is valid to submit
      let res = this.checkForm()
      this.setState({
        valid: res.valid
      })
    })
  }

  /*
  Shakes the button and then removes the class.
  */
  shakeTheButton() {
    this.setState({
      shakeButton: true
    }, () => {
      setTimeout(() => {
        this.setState({
          shakeButton: false
        })
      }, 1000)
    })
  }

  /*
  We have selected a scraping.
  */
  scrapingSelected(scrapingID, clickType, event) {
    //go to this web scraping's page.
    if (clickType === "rightclick") {
      //open a new tab
      event.preventDefault()
      console.log(scrapingID, clickType, event)
      window.open("/webscraping/" + scrapingID, "_blank", "noreferrer")
    } else {
      //go to this web scraping's page.
      this.props.changeView("WebScraping", scrapingID)
    }
  }

  /*
  Changes the filter that is applied to the requests.
  */
  changeFilter(newFilter) {
    this.setState({
      filter: newFilter,
      page: 0,
    }, () => {
      this.getScrapings()
    })
  }

  /*
  Changes the page of results we are viewing.
  */
  changePage(newPage) {
    this.setState({
      page: newPage
    }, () => {
      this.getScrapings()
    })
  }

  /*
  Changes the page of the results we are viewing for scrape schedules.
  */
  scrapeScheduleChangePage(newPage) {
    this.setState({
      scrapeSchedulePage: newPage
    }, () => {
      this.getScheduleScrapes()
    })
  }

  /*
  Changes the sort that is applied to the requests.
  */
  changeSort(newSort) {
    if (!["ID", "Type", "Status", "Created"].includes(newSort)) {
      return
    }
    this.setState((oldState) => {
      let newSortDirection = "DESC"
      if (oldState.sort === newSort) {
        //switch the direction
        newSortDirection = oldState.sortDirection
        if (newSortDirection === "DESC") {
          newSortDirection = "ASC"
        } else {
          newSortDirection = "DESC"
        }
      }
      return {
        sort: newSort,
        sortDirection: newSortDirection,
        page: 0
      }
    }, () => {
      this.getScrapings()
    })
  }

  /*
  Changes the sort that is applied to the requests.
  */
  changeScheduleSort(newSort) {
    if (!["ID", "Type", "When", "Created", "Base URL"].includes(newSort)) {
      return
    }
    this.setState((oldState) => {
      let newSortDirection = "DESC"
      if (oldState.scheduleSort === newSort) {
        //switch the direction
        newSortDirection = oldState.scheduleSortDirection
        if (newSortDirection === "DESC") {
          newSortDirection = "ASC"
        } else {
          newSortDirection = "DESC"
        }
      }
      return {
        scheduleSort: newSort,
        scheduleSortDirection: newSortDirection,
        scrapeSchedulePage: 0
      }
    }, () => {
      this.getScheduleScrapes()
    })
  }

  /*
  Get the list of scheduled web scrapings.
  */
  getScheduleScrapes(delay = 0) {
    this.setState({
      loading: true
    }, () => {
      setTimeout(() => {
        let data = {
          //filter: this.state.filter,
          sort: this.state.scheduleSort,
          sortDirection: this.state.scheduleSortDirection,
          limit: this.state.limit,
          page: this.state.scrapeSchedulePage,
        }
  
        API.callDarwinAPI("GET", "scheduledWebScrapings", data, async (result) => {
          console.log("scheduledWebScrapings", result)
          if ("error" in result) {
            //Change the view to 404
            this.props.showPopup("Error", "Couldn't get the scheduled web scrapings", "Please try again. If this continues to occur, reach out to support@geoji.com")
            this.setState({
              loading: false
            })
            return
          }
          let pageMax = Math.ceil((result.data.totalCount * 1.0) / this.state.limit) - 1
          this.setState({
            loading: false,
            scrapeSchedule: result.data.webScrapings,
            scrapeSchedulePageMax: pageMax,
            scrapeScheduleResultsCount: result.data.totalCount,
          })
        })
      }, delay)
    })
  }

  /*
  Get the list of scrapings we have sent.
  */
  getScrapings() {
    this.setState({
      loading: true
    }, () => {

      let data = {
        filter: this.state.filter,
        sort: this.state.sort,
        sortDirection: this.state.sortDirection,
        limit: this.state.limit,
        page: this.state.page,
      }

      API.callDarwinAPI("GET", "webScrapings", data, async (result) => {
        console.log("webScrapings", result)
        if ("error" in result) {
          //Change the view to 404
          this.props.showPopup("Error", "Couldn't get the web scrapings", "Please try again. If this continues to occur, reach out to support@geoji.com")
          this.setState({
            loading: false
          })
          return
        }
        let pageMax = Math.ceil((result.data.totalCount * 1.0) / this.state.limit) - 1
        this.setState({
          loading: false,
          webScrapings: result.data.webScrapings,
          pageMax: pageMax,
          resultsCount: result.data.totalCount,
        })
      })
    })
  }

  showCreateScraping() {
    this.setState({
      view: "Create"
    })
  }

  showScheduleScrapes() {
    this.setState({
      view: "Schedule",
      scrapeSchedule: [],
      scrapeSchedulePage: 0,
      scrapeScheduleResultsCount: 0,
    }, () => {
      this.getScheduleScrapes()
    })
  }

  showNewScrapeSchedule() {
    this.setState({
      view: "NewSchedule",
    })
  }

  showHome() {
    this.setState({
      view: "Home"
    })
  }

  getEmojiTags() {
    this.setState({
      view: "EmojiTags",
    }, () => {
      //Load the API for the EmojiTag mappings
      let data = {
        filter: this.state.emojiTagFilter,
        sort: this.state.emojiTagSort,
        sortDirection: this.state.emojiTagSortDirection,
        limit: this.state.emojiTagLimit,
        page: this.state.emojiTagPage,
      }

      API.callDarwinAPI("GET", "emojiTags", data, async (result) => {
        console.log("emoijTags", result)
        if ("error" in result) {
          //Change the view to 404
          this.props.showPopup("Error", "Couldn't get the emoji tags", "Please try again. If this continues to occur, reach out to support@geoji.com")
          this.setState({
            loading: false
          })
          return
        }
        let pageMax = Math.ceil((result.data.totalCount * 1.0) / this.state.emojiTagLimit) - 1

        //now go through each emojiTags and fill out a list of possible emojis if there are any
        for (let ee = 0; ee < result.data.tags.length; ee = ee + 1) {
          //find the possible emojis
          result.data.tags[ee].possibleEmojis = Helpers.searchEmojis(result.data.tags[ee].tag, 21)
        }

        this.setState({
          loading: false,
          emojiTags: result.data.tags,
          emojiTagPageMax: pageMax,
          emojiTagResultsCount: result.data.totalCount,
          emojiTagCountsUnset: result.data.unsetEvents,
          emojiTagCountsTotal: result.data.totalEvents,
          emojiTagCountsEmpty: result.data.emptyEvents,
        })
      })
    })
  }

  changeEmoji(et, event) {
    let newValue = event.target.value
    this.setState((old) => {
      let nv = old.emojiTags
      for (let i = 0; i < nv.length; i = i + 1) {
        if (nv[i].tag === et.tag) {
          //found it
          nv[i].emoji = newValue
          break
        }
      }
      return {
        emojiTags: nv,
      }
    })
  }

  changeEmojiManual(et, ejj) {
    let newValue = ejj
    this.setState((old) => {
      let nv = old.emojiTags
      for (let i = 0; i < nv.length; i = i + 1) {
        if (nv[i].tag === et.tag) {
          //found it
          nv[i].emoji = newValue
          break
        }
      }
      return {
        emojiTags: nv,
      }
    })
  }

  saveChangeEmojiTag(et) {
    console.log("Save Changes", et)

    //Load the API for the EmojiTag mappings
    let data = {
      tag: et.tag,
      emoji: et.emoji,
    }
    if (et.emoji.trim().length === 0) {
      //set to the question mark
      data.emoji = "❓"
    }

    API.callDarwinAPI("PUT", "emojiTag", data, async (result) => {
      console.log("emojiTag Update", result)
      if ("error" in result) {
        //Change the view to 404
        this.props.showPopup("Error", "Couldn't update the emoji tags", "Please try again. If this continues to occur, reach out to support@geoji.com")
        return
      }
    })
  }

  onEmojiTagsToggle(key) {
    this.setState((old) => {
      let obj = {}
      if (key === "emojiTagFilter") {
        if (old[key] === "All") {
          //revert sort to the name field.
          obj[key] = "Unset"
        } else {
          obj[key] = "All"
        }
      } else if (key === "emojiTagSort") {
        if (old[key] === "Name") {
          //revert sort to the name field.
          obj[key] = "Count"
          obj.emojiTagSortDirection = "DESC"
        } else {
          obj[key] = "Name"
          obj.emojiTagSortDirection = "ASC"
        }
      }
      return obj
    }, () => {
      this.getEmojiTags()
    })
  }

  changeEmojiTagPage(newPage) {
    this.setState({
      emojiTagPage: newPage
    }, () => {
      this.getEmojiTags()
    })
  }

  recreateEmojiMapFile() {
    API.callDarwinAPI("PUT", "recreateEmojiMapFile", {}, async (result) => {
      console.log("recreate emoji map file Update", result)
      if ("error" in result) {
        //Change the view to 404
        this.props.showPopup("Error", "Couldn't recreate the emoji map file", "Please try again. If this continues to occur, reach out to support@geoji.com")
        return
      }
    })
  }

  scheduleScrapingSelected(id) {
    this.props.showConfirmation("Delete Scheduled Web Scrape", "Would you like to delete this scheduled web scraping?", "Delete", "Cancel", (dup) => {
      if (dup) {
        //1) Delete
        API.callDarwinAPI("DELETE", "scheduleWebScraping/" + id, {}, async (result) => {
          console.log("delete scheduleWebScraping/" + id, result)
          if ("error" in result) {
            //Change the view to 404
            this.props.showPopup("Error", "Couldn't delete the scheduled web scraping", "Please try again. If this continues to occur, reach out to support@geoji.com")
            return
          }
        })
        //2) Reload the data
        this.setState({
          scrapeSchedule: [],
          scrapeSchedulePage: 0,
          scrapeScheduleResultsCount: 0,
        }, () => {
          this.getScheduleScrapes(1000)
        })
      }
    })
  }

  render() {

    return (
      <div className="WebScrapings">
        {/* Web Scrapings */}
        { this.state.view === "Home" && this.props.userInfo && this.props.userInfo.user &&
          <div className="FormBlock">
            <div className="WebScrapingsTitle">
              Web Scraping
            </div>
            { this.state.loading &&
              <div className="FormLoader" style={{height:"168px"}}>
                <Components.LoadingIndicator color="dark" />
              </div>
            }
            { !this.state.loading &&
              <div className="WebScrapingsContent">
                <div className="WebScrapingsContentActions" style={{marginTop:"0px"}}>
                  <Components.GeojiButton type="Theme2" onClick={this.showCreateScraping}>
                    Create Web Scraping
                  </Components.GeojiButton>
                  <Components.GeojiButton type="Purple" onClick={this.showScheduleScrapes}>
                    Schedule Scrapes
                  </Components.GeojiButton>
                  <Components.GeojiButton type="Theme" onClick={this.getEmojiTags}>
                    Emoji Tags
                  </Components.GeojiButton>
                </div>
                <Components.Table
                  tableInfo={Helpers.getWebScrapingsTableInfo()}
                  noData={Helpers.getText("emptyScrapings")}
                  data={this.state.webScrapings}
                  onClick={this.scrapingSelected}
                  onClickKey={"id"}
                  onHeaderClick={this.changeSort}
                  sort={this.state.sort}
                  sortDirection={this.state.sortDirection}
                  >
                  <div className="TableTop">
                    <div className="TableTopDate">
                      <Components.PageIndicator page={this.state.page} pageMax={this.state.pageMax} pageSelected={this.changePage} count={this.state.resultsCount} />
                    </div>
                  </div>
                </Components.Table>
              </div>
            }
          </div>
        }
        { this.state.view === "Create" &&
          <div className="FormBlock">
            <div className="WebScrapingsTitle">
              <Components.DualImage image={ImageClose} onClick={this.showHome} />
              Create Web Scraping
            </div>
            { this.state.loading &&
              <div className="FormLoader" style={{height:"168px"}}>
                <Components.LoadingIndicator color="dark" />
              </div>
            }
            { !this.state.loading &&
              <div className="WebScrapingsContent">
                {/* Scrape Type */}
                <div className="InputBreakDiv">
                  <Components.InputBottomLine type="selection" options={Helpers.getScrapingTypes()} name="type" placeholder="" validation="text" required="true"
                    title="Web Scraping Type"
                    value={this.state.data.type ? this.state.data.type.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                </div>
                {/* Config */}
                <div className="InputBreakDiv">
                  <Components.InputBottomLine type="selection" options={Helpers.getScrapingConfigTypes(this.state.data.type ? this.state.data.type.value : "")} name="config" placeholder="" validation="text" required="false"
                    title="Configuration"
                    value={this.state.data.config ? this.state.data.config.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                </div>
                {/* Base URL */}
                <div className="InputDiv">
                  <Components.InputBottomLine type="text" name="baseURL" validation="text" required="true" maximum={255} placeholder=""
                    title="Base URL"
                    value={this.state.data.baseURL ? this.state.data.baseURL.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                </div>
                {/* NextPage Index */}
                <div className="InputDiv">
                  <Components.InputBottomLine type="text" name="nextPageIndex" validation="positiveNumber" required="true" minimum={1} placeholder=""
                    title="Start Index"
                    value={this.state.data.nextPageIndex ? this.state.data.nextPageIndex.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                </div>
                {/* Finish Index */}
                <div className="InputDiv">
                  <Components.InputBottomLine type="text" name="finishIndex" validation="positiveNumber" required="true" minimum={1} placeholder=""
                    title="Finish Index"
                    value={this.state.data.finishIndex ? this.state.data.finishIndex.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                </div>
                {/* Button */}
                <div className="ButtonDiv">
                  <Components.GeojiButton type="Theme2" onClick={this.submitForm} shake={this.state.shakeButton}>
                    Create
                  </Components.GeojiButton>
                </div>
              </div>
            }
          </div>
        }
        { this.state.view === "Schedule" &&
          <div className="FormBlock">
            <div className="WebScrapingsTitle">
              <Components.DualImage image={ImageClose} onClick={this.showHome} />
              Schedule Web Scrapings
            </div>
            { this.state.loading &&
              <div className="FormLoader" style={{height:"168px"}}>
                <Components.LoadingIndicator color="dark" />
              </div>
            }
            { !this.state.loading &&
              <div className="WebScrapingsContent">
                <div className="WebScrapingsContentText">
                  Click on a scheduled web scraping to delete it. You will be asked to confirm deletion.
                </div>
                <div className="WebScrapingsContentActions" style={{marginTop:"0px"}}>
                  <Components.GeojiButton type="Theme2" onClick={this.showNewScrapeSchedule}>
                    New Schedule
                  </Components.GeojiButton>
                </div>
                <Components.Table
                  tableInfo={Helpers.getScheduleWebScrapingsTableInfo()}
                  noData={Helpers.getText("emptySchedule")}
                  data={this.state.scrapeSchedule}
                  onClick={this.scheduleScrapingSelected}
                  onClickKey={"id"}
                  onHeaderClick={this.changeScheduleSort}
                  sort={this.state.scheduleSort}
                  sortDirection={this.state.scheduleSortDirection}
                  >
                  <div className="TableTop">
                    <div className="TableTopDate">
                      <Components.PageIndicator page={this.state.scrapeSchedulePage} pageMax={this.state.scrapeSchedulePageMax} pageSelected={this.scrapeScheduleChangePage} count={this.state.scrapeScheduleResultsCount} />
                    </div>
                  </div>
                </Components.Table>
              </div>
            }
          </div>
        }
        { this.state.view === "NewSchedule" &&
          <div className="FormBlock">
            <div className="WebScrapingsTitle">
              <Components.DualImage image={ImageClose} onClick={this.showScheduleScrapes} />
              Schedule Web Scraping
            </div>
            { this.state.loading &&
              <div className="FormLoader" style={{height:"168px"}}>
                <Components.LoadingIndicator color="dark" />
              </div>
            }
            { !this.state.loading &&
              <div className="WebScrapingsContent">
                {/* Scrape Type */}
                <div className="InputBreakDiv">
                  <Components.InputBottomLine type="selection" options={Helpers.getScrapingTypes()} name="type" placeholder="" validation="text" required="true"
                    title="Web Scraping Type"
                    value={this.state.data.type ? this.state.data.type.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                </div>
                {/* Config */}
                <div className="InputBreakDiv">
                  <Components.InputBottomLine type="selection" options={Helpers.getScrapingConfigTypes(this.state.data.type ? this.state.data.type.value : "")} name="config" placeholder="" validation="text" required="false"
                    title="Configuration"
                    value={this.state.data.config ? this.state.data.config.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                </div>
                {/* Timing */}
                <div className="InputBreakDiv">
                  <Components.InputBottomLine type="selection" options={Helpers.getScheduleWhenOptions()} name="when" placeholder="" validation="text" required="true"
                    title="When"
                    value={this.state.data.when ? this.state.data.when.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                </div>
                {/* Base URL */}
                <div className="InputDiv">
                  <Components.InputBottomLine type="text" name="baseURL" validation="text" required="true" maximum={255} placeholder=""
                    title="Base URL"
                    value={this.state.data.baseURL ? this.state.data.baseURL.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                </div>
                {/* NextPage Index */}
                <div className="InputDiv">
                  <Components.InputBottomLine type="text" name="nextPageIndex" validation="positiveNumber" required="true" minimum={1} placeholder=""
                    title="Start Index"
                    value={this.state.data.nextPageIndex ? this.state.data.nextPageIndex.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                </div>
                {/* Finish Index */}
                <div className="InputDiv">
                  <Components.InputBottomLine type="text" name="finishIndex" validation="positiveNumber" required="true" minimum={1} placeholder=""
                    title="Finish Index"
                    value={this.state.data.finishIndex ? this.state.data.finishIndex.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                </div>
                {/* Button */}
                <div className="ButtonDiv">
                  <Components.GeojiButton type="Theme2" onClick={this.submitForm} shake={this.state.shakeButton}>
                    Schedule
                  </Components.GeojiButton>
                </div>
              </div>
            }
          </div>
        }
        { this.state.view === "EmojiTags" &&
          <div className="FormBlock">
            <div className="WebScrapingsTitle">
              <Components.DualImage image={ImageClose} onClick={this.showHome} />
              Emoji Tags
            </div>
            { this.state.loading &&
              <div className="FormLoader" style={{height:"168px"}}>
                <Components.LoadingIndicator color="dark" />
              </div>
            }
            { !this.state.loading &&
              <div className="EmojiTags">
                {/* Events that will have an Emoji */}
                <div className="EmojiTagsCounts">
                  {Helpers.formatNumber(this.state.emojiTagCountsUnset)} events won't auto match to an emoji.
                  <br/>
                  {Helpers.formatNumber(this.state.emojiTagCountsEmpty)} events can't have a match because they have no tags.
                  <br/>
                  {Helpers.formatNumber(this.state.emojiTagCountsTotal)} total events.
                  <br/>
                  <b>You can match <span>{Helpers.formatNumber(this.state.emojiTagCountsUnset - this.state.emojiTagCountsEmpty)}</span> more events by setting emojis.</b>
                </div>
                {/* Actions */}
                <div className="EmojiTagsActions">
                  <Components.GeojiButton type="Theme2" onClick={this.recreateEmojiMapFile}>
                    Recreate Emoji Map File
                  </Components.GeojiButton>
                </div>
                {/* Filters & Sorts */}
                <div className="EmojiTagsFilters">
                  <Components.SettingsToggle name="Only Unset" value={this.state.emojiTagFilter === "Unset"} onToggle={this.onEmojiTagsToggle.bind(this, "emojiTagFilter")} />
                  <Components.SettingsToggle name="Sort by Count" value={this.state.emojiTagSort === "Count"} onToggle={this.onEmojiTagsToggle.bind(this, "emojiTagSort")} />
                </div>
                {/* Pagination at Top of Screen */}
                <div className="TableTop">
                  <div className="TableTopDate">
                    <Components.PageIndicator page={this.state.emojiTagPage} pageMax={this.state.emojiTagPageMax} pageSelected={this.changeEmojiTagPage} count={this.state.emojiTagResultsCount} />
                  </div>
                </div>
                {/* List of Tags */}
                { this.state.emojiTags.map((et, eti) => (
                  <div className="EmojiTag" key={et.tag}>
                    <div className={"EmojiTagEmoji " + (et.emoji === "❓" ? "EmojiTagEmojiUnfilled" : "")}>
                      <input tabIndex={eti + 5} name={"emoji_" + et.name} className="emojiInput" placeholder="❓" value={et.emoji}
                        autoComplete="off" onChange={this.changeEmoji.bind(this, et)} onBlur={this.saveChangeEmojiTag.bind(this, et)} />
                    </div>
                    <div className="EmojiTagName">
                      #{et.tag}
                    </div>
                    <div className="EmojiTagCount">
                      {Helpers.formatNumber(et.count) + (et.count === "1" ? " event" : " events")}
                    </div>
                    { et.possibleEmojis.length > 0 &&
                      <div className="EmojiTagPossibles">
                        {et.possibleEmojis.map((ejj, eji) => (
                          <div className="EmojiTagPossible" key={"emoji_tag_possible_" + eji} onClick={this.changeEmojiManual.bind(this, et, ejj[0])}>
                            {ejj[0]}
                          </div>
                        ))}
                      </div>
                    }
                  </div>
                ))}
                {/* Pagination at Bottom of Screen */}
                <div className="TableTop">
                  <div className="TableTopDate">
                    <Components.PageIndicator page={this.state.emojiTagPage} pageMax={this.state.emojiTagPageMax} pageSelected={this.changeEmojiTagPage} count={this.state.emojiTagResultsCount} />
                  </div>
                </div>
              </div>
            }
          </div>
        }
      </div>
    )
  }
}

export class WebScraping extends React.Component {

  constructor(props) {
    super(props)

    let data = {}

    //set the user info
    /*if (this.props.userInfo && this.props.userInfo.user) {
      let items = ["name"]
      items.forEach((item, i) => {
        if (this.props.userInfo.user[item]) {
          data[item] = {
            value: this.props.userInfo.user[item],
            valid: true
          }
        }
      });
    }*/
    console.log(data)

    this.state = {
      loading: false,
      data: data,
      forceCheck: false,
      shakeButton: false,
      changesMade: false, //have any changes been made

      view: "Home",
      subview: "Home",
      prompt: false,

      webScraping: {},
      focusedReview: false,

      searchIndex: 0,
      searchLikeIndex: 0,
      emojis: [],
      likeEmojis: [],

      createdEvents: [],
    }
    autoBind(this)
  }

  componentDidMount() {
    //calculate the view
    this.calculateView()
  }

  componentDidUpdate(prevProps) {
    if (this.props.render !== prevProps.render) {
      //reload the home view.
      this.calculateView()
    }
  }

  calculateView() {
    //set the data
    if (this.state.view === "Home") {
      //load the abilities for this user.
      this.getScraping()
    }
  }

  /*
  * Submits the form
  */
  submitForm() {
    let results = this.checkForm(true)
    if (results.valid) {
      switch (this.state.view) {
        case "Review":
          this.acceptEvent(results.data)
          break;
        default:
          console.error("Unhandled submitForm", this.state.view)
          break;
      }
    } else {
      console.log("Form not valid to submit")
      this.setState({
        forceCheck: true
      })
      //Shake the submit button as it is invalid to submit
      this.shakeTheButton()
    }
  }

  /*
  Checks the form to make sure it is valid.
  Returns {valid:Bool, data:{}}
  */
  checkForm(print = false) {
    //1) Make sure we have all of the data.
    let valid = true
    let requiredFields = []
    let requiredIf = []
    let requiredSometimes = [] //if set, then don't remove it from the data.
    let optionals = [] //the optional fields apart from the requiredFields.
    switch (this.state.view) {
      case "Review":
        requiredFields = ["emoji", "title", "description", "dates", "address"]
        optionals = ["description", "timeText", "website", "photo"]
        requiredIf = []
        requiredSometimes = []
        break;
      default:
        break;
    }
    requiredFields.forEach((element) => {
      if (this.state.data[element] === undefined || this.state.data[element].value === undefined) {
        //the field is not filled out
        if (print) {
          console.log("required not filled out: ", element)
        }
        valid = false
      }
    })
    requiredIf.forEach((condition) => {
      if (this.state.data[condition.field] === undefined || this.state.data[condition.field].value === undefined) {
        //the condition field is not filled out
        if (print) {
          console.log("condition field is not filled out: ", condition)
        }
        valid = false
      } else if (condition.values !== undefined && condition.values.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          if (print) {
            console.log("rif field is required and is not filled out: ", condition)
          }
          valid = false
        }
      } else if (condition.not !== undefined && !condition.not.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          if (print) {
            console.log("rif field is required with not and is not filled out: ", condition)
          }
          valid = false
        }
      }
    })
    //2) Make sure every data element is valid.
    for (let key in this.state.data) {
      let forget = false
      //Make sure it is not a requiredSometimes
      if (!requiredSometimes.includes(key)) {
        // Check the requiredIf conditions
        for (let i = 0; i < requiredIf.length; i = i + 1) {
          let rif = requiredIf[i]
          if (rif.require === key) {
            if (!rif.values.includes(this.state.data[rif.field].value)) {
              if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                forget = true
              }
            }
            break
          }
        }
      }
      //Make sure this is a field we are looking for.
      if (optionals.includes(key) || requiredFields.includes(key)) {
        if (!forget && this.state.data[key].valid === false) {
          if (print) {
            console.log("data not valid", key)
          }
          valid = false
        }
      }
    }

    //3) If valid, return the data, else force check the fields for display.
    if (valid) {
      let data = {}
      for (let key in this.state.data) {
        let forget = false
        //Make sure it is not a requiredSometimes
        if (!requiredSometimes.includes(key)) {
          for (let i = 0; i < requiredIf.length; i = i + 1) {
            let rif = requiredIf[i]
            if (rif.require === key) {
              if (!rif.values.includes(this.state.data[rif.field].value)) {
                if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                  forget = true
                }
              }
              break
            }
          }
        }
        if (!forget) {
          if (optionals.includes(key) || requiredFields.includes(key)) {
            //console.log("value", this.state.data[key])
            if ((this.state.data[key].value !== false && this.state.data[key].value !== null) && this.state.data[key].value.toString().length > 0) {
              data[key] = this.state.data[key].value
              if (typeof data[key] === 'string') {
                data[key] = data[key].trim()
              }
            }
          }
        }
      }
      return {
        valid: true,
        data: data
      }
    } else {
      return {
        valid: false,
        data: "Form not valid to submit"
      }
    }
  }

  /*
  * Called when data in an input form changes.
  * This will update the state.data param with the
  * name and new value of the form element.
  */
  formChanged(name, value, valid) {
    this.setState((prevState) => {
      let d = prevState.data
      d[name] = {
        value: value,
        valid: valid
      }
      //check to see if the form is valid to submit
      return {
        data: d,
        forceCheck: false,
        changesMade: true,
      }
    }, () => {
      //check to see if the form is valid to submit
      let res = this.checkForm()
      this.setState({
        valid: res.valid
      }, () => {
        if (name === "title" || name === "details") {
          this.searchEmojis()
        } else if (name === "emoji") {
          this.searchLikeEmojis()
        }
      })
    })
  }

  /*
  Shakes the button and then removes the class.
  */
  shakeTheButton() {
    this.setState({
      shakeButton: true
    }, () => {
      setTimeout(() => {
        this.setState({
          shakeButton: false
        })
      }, 1000)
    })
  }

  /*
  Get the list of scrapings we have sent.
  */
  getScraping() {
    this.setState({
      loading: true
    }, () => {

      let data = {
      }
      API.callDarwinAPI("GET", "webScraping/" + this.props.tertiaryView, data, async (result) => {
        console.log("webScraping/" + this.props.tertiaryView, result)
        if ("error" in result) {
          //Change the view to 404
          this.props.showPopup("Error", "Couldn't get the web scraping", "Please try again. If this continues to occur, reach out to support@geoji.com")
          this.setState({
            loading: false
          })
          return
        }
        this.setState({
          loading: false,
          webScraping: result.data.webScraping,
        })
      })
    })
  }

  cancelScraping() {
    this.props.showConfirmation("Cancel", "This action is final.", "Stop Scraping", "Back", (quit) => {
      if (quit) {
        this.setState({
          loading: true
        }, () => {
          let data = {
          }
          API.callDarwinAPI("DELETE", "webScraping/" + this.props.tertiaryView, data, async (result) => {
            console.log("DELETE webScraping/" + this.props.tertiaryView, result)
            if ("error" in result) {
              //Change the view to 404
              this.props.showPopup("Error", "Couldn't delete the web scraping", "Please try again. If this continues to occur, reach out to support@geoji.com")
              this.setState({
                loading: false
              })
              return
            }
            this.setState({
              loading: false,
            }, () => {
              this.props.changeView("WebScrapings")
            })
          })
        })
      }
    })
  }

  reprocessScraping() {
    this.props.showConfirmation("Reprocess", "This action is final and will revert all reviews and reprocess the events", "Reprocess", "Back", (repo) => {
      if (repo) {
        this.setState({
          loading: true
        }, () => {
          let data = {
          }
          API.callDarwinAPI("PUT", "webScrapingReprocess/" + this.props.tertiaryView, data, async (result) => {
            console.log("PUT webScrapingReprocess/" + this.props.tertiaryView, result)
            if ("error" in result) {
              //Change the view to 404
              this.props.showPopup("Error", "Couldn't reprocess the web scraping", "Please try again. If this continues to occur, reach out to support@geoji.com")
              this.setState({
                loading: false
              })
              return
            }
            this.setState({
              loading: true,
            }, () => {
              this.getScraping()
            })
          })
        })
      }
    })
  }

  turnEventsIntoGeoji() {
    this.props.showConfirmation("Turn Events into Geojis", "This will turn all approved events into Geojis", "Continue", "Back", (repo) => {
      if (repo) {
        this.setState({
          loading: true
        }, () => {
          let data = {
          }
          API.callDarwinAPI("POST", "webScrapingTurnEventsIntoGeoji/" + this.props.tertiaryView, data, async (result) => {
            console.log("POST webScrapingTurnEventsIntoGeoji/" + this.props.tertiaryView, result)
            if ("error" in result) {
              //Change the view to 404
              this.props.showPopup("Error", "Couldn't turn events into Geojis", "Please try again. If this continues to occur, reach out to support@geoji.com")
              this.setState({
                loading: false
              })
              return
            }
            this.setState({
              loading: true,
            }, () => {
              this.getScraping()
            })
          })
        })
      }
    })
  }

  reviewEvents() {
    this.setState({
      view: "Review"
    }, () => {
      this.loadReviewEvent()
    })
  }

  massReviewEvents() {
    this.setState({
      view: "MassReview"
    }, () => {
      this.loadMassReviewEvents()
    })
  }

  viewCreatedEvents() {
    this.setState({
      view: "ViewCreated"
    }, () => {
      this.loadCreatedEvents()
    })
  }

  exitReview() {
    this.setState({
      view: "Home"
    })
  }

  loadReviewEvent() {
    //Loads an event to review.
    this.setState({
      loading: true
    }, () => {
      let data = {
      }
      API.callDarwinAPI("GET", "webScrapingReview/" + this.props.tertiaryView, data, async (result) => {
        console.log("GET webScrapingReview/" + this.props.tertiaryView, result)
        if ("error" in result) {
          //Change the view to 404
          this.props.showPopup("Error", "Couldn't get the web scraping review", "Please try again. If this continues to occur, reach out to support@geoji.com")
          this.setState({
            loading: false
          })
          return
        }
        let rr = result.data.review
        let data = {}
        if (rr) {
          data.title = {
            value: rr.title || "",
            valid: true
          }
          data.description = {
            value: rr.description || "",
            valid: true
          }
          data.emoji = {
            value: rr.emoji || "",
            valid: true
          }
          data.address = {
            value: rr.address || "",
            valid: true
          }
          data.website = {
            value: rr.url || "",
            valid: true
          }
          data.photo = {
            value: rr.photoURL || "",
            valid: true
          }
          if (rr.timeText) {
            data.timeText = {
              value: rr.timeText || "",
              valid: true
            }
          }
          if (rr.sd) {
            //Parse the date and add it to the list of selected dates.
            let dd = new DateObject(rr.sd * 1000 + 60*60*12*1000) //add 12 hours to account for the time zones...
            data.dates = {
              value: [dd],
              valid: true
            }
          }
        }
        this.setState({
          loading: false,
          review: rr,
          data: data,
          forceCheck: false,
          shakeButton: false,
          changesMade: false,
          searchIndex: 0,
          emojis: [],
          likeEmojis: [],
        }, () => {
          this.searchEmojis()
        })
      })
    })
  }

  loadMassReviewEvents() {
    //Loads multiple events to mass review.
    this.setState({
      loading: true
    }, () => {
      let data = {
        limit: 100
      }
      API.callDarwinAPI("GET", "webScrapingMassReview/" + this.props.tertiaryView, data, async (result) => {
        console.log("GET webScrapingMassReview/" + this.props.tertiaryView, result)
        if ("error" in result) {
          //Change the view to 404
          this.props.showPopup("Error", "Couldn't get the mass web scraping review", "Please try again. If this continues to occur, reach out to support@geoji.com")
          this.setState({
            loading: false
          })
          return
        }

        let nr = result.data.reviews
        for (let i = 0; i < nr.length; i = i + 1) {
          //parse the data
          nr[i].data = JSON.parse(nr[i].data)
          if (!("accept" in nr[i])) {
            //default to true.
            nr[i].accept = true
            //check for conditions
            if (!nr[i].address) {
              nr[i].accept = false
            } else {
              //check for an online location.
              let addr = nr[i].address
              if (typeof addr === "string" && (addr.includes("Online") || addr.includes("online"))) {
                nr[i].accept = false
              }
            }
          }
        }

        this.setState({
          loading: false,
          reviews: nr,
          data: {},
          forceCheck: false,
          shakeButton: false,
          changesMade: false,
          focusedReview: false,
        })
      })
    })
  }

  loadCreatedEvents() {
    //Loads multiple events to mass review.
    this.setState({
      loading: true
    }, () => {
      let data = {
        //limit: 100
      }
      API.callDarwinAPI("GET", "webScrapingCreatedEvents/" + this.props.tertiaryView, data, async (result) => {
        console.log("GET webScrapingCreatedEvents/" + this.props.tertiaryView, result)
        if ("error" in result) {
          //Change the view to 404
          this.props.showPopup("Error", "Couldn't get the web scraping created events", "Please try again. If this continues to occur, reach out to support@geoji.com")
          this.setState({
            loading: false
          })
          return
        }

        this.setState({
          loading: false,
          createdEvents: result.data.events,
          data: {},
          forceCheck: false,
          shakeButton: false,
        })
      })
    })
  }

  toggleMassReviewEvent(eventID) {
    this.setState((old) => {
      let nr = old.reviews
      for (let i = 0; i < nr.length; i = i + 1) {
        if (nr[i].id === eventID) {
          nr[i].accept = !nr[i].accept
          break
        }
      }
      return {
        reviews: nr
      }
    })
  }

  toggleAll() {
    this.setState((old) => {
      let nr = old.reviews
      let val = 2
      for (let i = 0; i < nr.length; i = i + 1) {
        if (val === 2) {
          val = !nr[i].accept
        }
        nr[i].accept = val
      }
      return {
        reviews: nr
      }
    })
  }

  submitMassReview() {
    this.setState({
      loading: true
    }, () => {
      let nr = []
      for (let i = 0; i < this.state.reviews.length; i = i + 1) {
        nr.push({
          id: this.state.reviews[i].id,
          accept: this.state.reviews[i].accept ? 1 : 0
        })
      }
      let data = {
        reviews: JSON.stringify(nr)
      }
      console.log("POST data", data)
      API.callDarwinAPI("POST", "webScrapingMassReview/" + this.props.tertiaryView, data, async (result) => {
        console.log("POST webScrapingMassReview/" + this.props.tertiaryView, result)
        if ("error" in result) {
          //Change the view to 404
          this.props.showPopup("Error", "Couldn't post the mass web scraping review", "Please try again. If this continues to occur, reach out to support@geoji.com")
          this.setState({
            loading: false
          })
          return
        }
        this.loadMassReviewEvents()
      })
    })
  }

  acceptEvent(data) {
    //Loads an event to review.

    //format the dates
    let dateString = ""
    for (let i = 0; i < data.dates.length; i = i + 1) {
      if (dateString.length > 0) {
        dateString = dateString + ","
      }
      //add the date
      dateString = dateString + data.dates[i].format("YYYY-MM-DD")
    }
    data.dates = dateString

    this.setState({
      loading: true
    }, () => {
      console.log(data)
      API.callDarwinAPI("POST", "webScrapingEvent/" + this.state.review.id, data, async (result) => {
        console.log("POST webScrapingEvent/" + this.state.review.id, result)
        if ("error" in result) {
          //Change the view to 404
          this.props.showPopup("Error", "Couldn't turn into a Geoji.", "Please try again. If this continues to occur, reach out to support@geoji.com")
          this.setState({
            loading: false
          })
          return
        }
        //load the next review.
        this.loadReviewEvent()
      })
    })
  }

  rejectEvent() {
    //Loads an event to review.
    this.setState({
      loading: true
    }, () => {
      let data = {
      }
      API.callDarwinAPI("DELETE", "webScrapingEvent/" + this.state.review.id, data, async (result) => {
        console.log("DELETE webScrapingEvent/" + this.state.review.id, result)
        if ("error" in result) {
          //Change the view to 404
          this.props.showPopup("Error", "Couldn't reject the web scraping event.", "Please try again. If this continues to occur, reach out to support@geoji.com")
          this.setState({
            loading: false
          })
          return
        }
        this.loadReviewEvent()
      })
    })
  }

  focusMassEvent(eventID, ev) {
    ev.stopPropagation();

    //TODO: revert back to iframe and have a way of exiting.
    for (let i = 0; i < this.state.reviews.length; i = i + 1) {
      if (this.state.reviews[i].id === eventID) {
        //open link in a new tab
        window.open("https://eventbrite.com/e/" + this.state.reviews[i].eventID, '_blank').focus();
        /*this.setState({
          focusedReview: this.state.reviews[i]
        })*/
        break
      }
    }
  }

  calculateTimeRemaining() {
    let pagesPerScrape = 30
    let scrapingSpeed = pagesPerScrape / 5 //30 items scraped every 5 minutes
    let processingSpeed = (pagesPerScrape * 10) / 5 //300 items processed every 5 minutes

    //calculate how many minutes are remaining
    if (this.state.webScraping.status === "Meta") {
      let indexCount = Math.max(this.state.webScraping.finishIndex, 1)
      let eventCount = indexCount * pagesPerScrape

      //meta
      let remaining = indexCount / scrapingSpeed
      //pages
      remaining += eventCount / scrapingSpeed
      //processing
      remaining += eventCount / processingSpeed
      return "~" + Math.round(remaining) + " minutes remaining"
    } else if (this.state.webScraping.status === "Pages") {
      //5 minutes captures 30
      let remaining = Math.max(this.state.webScraping.remainingScrapeTotal, 1) / scrapingSpeed
      remaining += this.state.webScraping.eventTotal / processingSpeed
      return Math.round(remaining) + " minutes remaining"
    } else if (this.state.webScraping.status === "Processing") {
      let remaining = this.state.webScraping.eventTotal / processingSpeed
      return Math.round(remaining) + " minutes remaining"
    } else {
      return "Scraping Complete"
    }
  }

  /*
  An emoji has been selected from the first list.
  */
  emojiSelected(emoji) {
    this.formChanged("emoji", emoji, true)
  }

  /*
  Searches for similar Emojis.
  */
  searchLikeEmojis() {
    let si = 0
    this.setState((old) => {
      si = old.searchLikeIndex + 1
      return {
        searchLikeIndex: si
      }
    }, () => {
      setTimeout(() => {
        this.searchLikeEmojis2(si)
      }, 300)
    })
  }

  /*
  Searches for similar Emojis.
  */
  searchLikeEmojis2(indi) {
    if (this.state.searchLikeIndex !== indi) {
      //console.log("Search Interupted")
      return
    }
    //console.log("Searching Emojis")
    let search = (this.state.data.emoji ? this.state.data.emoji.value : "")
    let emojis = Helpers.searchLikeEmojis(search, 30).map((emo) => {
      return emo[0]
    })
    this.setState({
      likeEmojis: emojis
    })
  }

  /*
  Starts the searching for the emojis.
  */
  searchEmojis() {
    let si = 0
    this.setState((old) => {
      si = old.searchIndex + 1
      return {
        searchIndex: si
      }
    }, () => {
      setTimeout(() => {
        this.searchEmojis2(si)
      }, 500)
    })
  }

  /*
  Actually searches for the emojis and returns the results.
  */
  searchEmojis2(indi) {
    if (this.state.searchIndex !== indi) {
      //console.log("Search Interupted")
      return
    }
    //console.log("Searching Emojis")
    let search = (this.state.data.title ? this.state.data.title.value : "") + " " + (this.state.data.description ? this.state.data.description.value : "")
    if (search === " ") {
      search = "bar event event party stadium music comedy"
    }
    let emojis = Helpers.searchEmojis(search, 30).map((emo) => {
      return emo[0]
    })
    this.setState({
      emojis: emojis
    })
  }

  /*
  A Google Address was selected, let's update the fields
  */
  googleAddressSelected(address) {
    console.log("addressSelected:", address)
    this.formChanged("address", address, true)
  }

  clickedCreatedEvent(geojiID) {
    window.open("https://geoji.com/g/" + geojiID, '_blank').focus();
  }

  render() {

    return (
      <div className="WebScrapings">
        {/* Web Scrapings */}
        { this.state.view === "Home" && this.props.userInfo && this.props.userInfo.user &&
          <div className="FormBlock">
            <div className="WebScrapingsTitle">
              <Components.DualImage image={ImageClose} onClick={this.props.changeView.bind(this, "WebScrapings")} />
              Web Scraping
            </div>
            { this.state.loading &&
              <div className="FormLoader" style={{height:"168px"}}>
                <Components.LoadingIndicator color="dark" />
              </div>
            }
            { !this.state.loading && this.state.webScraping &&
              <div className="WebScrapingsContent">
                <div className="WebScrapingsContentHeader">
                  <div className="WebScrapingsContentHeaderType">
                    {this.state.webScraping.type} - {this.state.webScraping.id}
                  </div>
                  <a className="WebScrapingsContentHeaderBaseURL" href={this.state.webScraping.baseURL} target="_blank" rel="noopener noreferrer">
                    {this.state.webScraping.baseURL}
                  </a>
                  <div className={"WebScrapingsContentHeaderStatus tdStatus" + this.state.webScraping.status}>
                    Status - {this.state.webScraping.status}
                  </div>
                  <div className="WebScrapingsContentHeaderCount">
                    {this.state.webScraping.nextPageIndex} / {this.state.webScraping.finishIndex} Meta Scrapes Completed
                  </div>
                  { this.state.webScraping.status !== "Meta" &&
                    <div className="WebScrapingsContentHeaderTotalPages">
                      {this.state.webScraping.eventTotal - this.state.webScraping.remainingScrapeTotal} / {this.state.webScraping.eventTotal} Events Scraped
                    </div>
                  }
                  { this.state.webScraping.status !== "Meta" && this.state.webScraping.status !== "Pages" && this.state.webScraping.status !== "Processing" &&
                    <div className="WebScrapingsContentHeaderTotalReview">
                      {this.state.webScraping.remainingReviewTotal} Events Left to Review
                    </div>
                  }
                  { this.state.webScraping.status !== "Meta" && this.state.webScraping.status !== "Pages" && this.state.webScraping.status !== "Processing" &&
                    <div className="WebScrapingsContentHeaderRejected">
                      {this.state.webScraping.rejected} Rejected
                    </div>
                  }
                  { this.state.webScraping.status !== "Meta" && this.state.webScraping.status !== "Pages" && this.state.webScraping.status !== "Processing" &&
                    <div className="WebScrapingsContentHeaderReadyToCreate">
                      {this.state.webScraping.readyToCreate} Events Ready to Create
                    </div>
                  }
                  { this.state.webScraping.status !== "Meta" && this.state.webScraping.status !== "Pages" && this.state.webScraping.status !== "Processing" &&
                    <div className="WebScrapingsContentHeaderCreated">
                      {this.state.webScraping.created} Geojis Created
                    </div>
                  }
                  {/* Time Remaining */}
                  <div className="WebScrapingsContentHeaderTimeRemaining">
                    {this.calculateTimeRemaining()}
                  </div>
                  <div className="WebScrapingsContentHeaderStatusDescription">
                    { this.state.webScraping.status === "Meta" &&
                      <span>
                        We are currently scraping the meta pages that list the pages for the events.
                        Once this is complete, we will move onto the "Pages" step where we will scrape each event.
                      </span>
                    }
                    { this.state.webScraping.status === "Pages" &&
                      <span>
                        We are currently scraping each event page one by one.
                        Once this takes place we will move onto the "Processing" step and turn each event into a usable format.
                      </span>
                    }
                    { this.state.webScraping.status === "Processing" &&
                      <span>
                        We are currently processing the scrapings data and turning it into a usable format compatible with Geojis.
                        Once this process has completed, you'll be able to review the scrapings and turn them into Geojis.
                      </span>
                    }
                    { this.state.webScraping.status === "MassReviewing" &&
                      <span>
                        Now that the data has been scraped & processed, we need to mass review these events to get rid of the junk.
                        Once this process has completed you'll.
                      </span>
                    }
                    { this.state.webScraping.status === "Reviewing" &&
                      <span>
                        Now that the data has been scraped & processed, we need manual review to turn these events into Geojis.
                        Click the Mass Review button below to review the content for each event and turn it into a Geoji.
                      </span>
                    }
                    { this.state.webScraping.status === "Cancelled" &&
                      <span>
                        This scraping has been cancelled. There is nothing left to do.
                      </span>
                    }
                    { this.state.webScraping.status === "Done" &&
                      <span>
                        This scraping is fully finished and has been turned into Geojis. There is nothing left to do.
                      </span>
                    }
                  </div>
                </div>
                <div className="WebScrapingsContentActions">
                  { this.state.webScraping.status === "Reviewing" &&
                    <Components.GeojiButton type="Orange" onClick={this.massReviewEvents}>
                      Mass Review Events
                    </Components.GeojiButton>
                  }
                  {/* this.state.webScraping.status === "Reviewing" &&
                    <Components.GeojiButton type="Purple" onClick={this.reviewEvents}>
                      Review Events
                    </Components.GeojiButton>
                  */}
                  { this.state.webScraping.status !== "Done" && this.state.webScraping.status !== "Cancelled" &&
                    <Components.GeojiButton type="Delete" onClick={this.cancelScraping}>
                      Cancel Scraping
                    </Components.GeojiButton>
                  }
                  { this.state.webScraping.status !== "Done" && this.state.webScraping.status !== "Cancelled" &&
                    <Components.GeojiButton type="Purple" onClick={this.reprocessScraping}>
                      Reprocess
                    </Components.GeojiButton>
                  }
                  { this.state.webScraping.status === "Create" &&
                    <Components.GeojiButton type="Outline" onClick={this.turnEventsIntoGeoji}>
                      Turn Events into Geojis
                    </Components.GeojiButton>
                  }
                  { this.state.webScraping.status === "Done" &&
                    <Components.GeojiButton type="Outline" onClick={this.viewCreatedEvents}>
                      View Created Events
                    </Components.GeojiButton>
                  }
                </div>
              </div>
            }
            { !this.state.loading && !this.state.webScraping &&
              <div className="WebScrapingsContent">
                <div className="WebScrapingsContentError">
                  We could not find this web scraping.
                </div>
              </div>
            }
          </div>
        }
        { this.state.view === "Review" &&
          <div className="FormBlock">
            <div className="WebScrapingsTitle">
              <Components.DualImage image={ImageClose} onClick={this.exitReview} />
              Review
            </div>
            { this.state.loading &&
              <div className="FormLoader" style={{height:"168px"}}>
                <Components.LoadingIndicator color="dark" />
              </div>
            }
            { !this.state.loading && this.state.review &&
                <div className="WebScrapingsContent">
                { this.state.review.duplicateScrapeEventID &&
                  <div className="WebScrapingNote">
                    This event has already been created. There are updates to review below.
                  </div>
                }
                { this.state.review.possibleDuplicateID &&
                  <div className="WebScrapingNote WebScrapingNoteAlt">
                    This event is a possible duplicate. Check the map to make sure nothing like it exists.
                  </div>
                }
                <div className="WebScrapingsContentActions WebScrapingsContentActionsCentered">
                  <Components.GeojiButton type="Theme" onClick={this.submitForm} shake={this.state.shakeButton}>
                    { this.state.review.duplicateScrapeEventID &&
                      <span>Save Changes</span>
                    }
                    { !this.state.review.duplicateScrapeEventID &&
                      <span>Create Geoji</span>
                    }
                  </Components.GeojiButton>
                  <Components.GeojiButton type="Delete" onClick={this.rejectEvent}>
                    { this.state.review.duplicateScrapeEventID &&
                      <span>Reject Changes</span>
                    }
                    { !this.state.review.duplicateScrapeEventID &&
                      <span>Reject Event</span>
                    }
                  </Components.GeojiButton>
                </div>
                <div className="WebScrapingsContentReview">
                  <div className="WebScrapingsContentReviewLeft">
                    {/* Emoji */}
                    <div className="InputDiv">
                      <Components.InputBottomLine type="text" name="emoji" validation="text" required="true" maximum={40} minimum={1} placeholder=""
                        title="Emoji - type to search"
                        value={this.state.data.emoji ? this.state.data.emoji.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                    </div>
                    <Components.EmojiList emojis={this.state.emojis} onSelect={this.emojiSelected} />
                    { this.state.likeEmojis.length > 0 &&
                      <Components.EmojiList emojis={this.state.likeEmojis} onSelect={this.emojiSelected} style={{marginTop:"8px"}} title="Similar" />
                    }
                    {/* Title */}
                    <div className="InputDiv">
                      <Components.InputBottomLine type="text" name="title" validation="text" required="true" maximum={255} placeholder=""
                        title="Title"
                        value={this.state.data.title ? this.state.data.title.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                    </div>
                    {/* Dates */}
                    <div className="InputDiv">
                      <Components.InputBottomLine type="multipledates" name="dates" validation="multipledates" required="true"
                        title="Event Dates"
                        value={this.state.data.dates ? this.state.data.dates.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                    </div>
                    {/* Time */}
                    <div className="InputDiv">
                      <Components.InputBottomLine type="textarea" name="timeText" validation="text" required="true" maximum={255} placeholder=""
                        title="Time"
                        value={this.state.data.timeText ? this.state.data.timeText.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                    </div>
                    {/* Description */}
                    <div className="InputDiv">
                      <Components.InputBottomLine type="textarea" name="description" validation="text" required="true" maximum={65000} placeholder=""
                        title="Description"
                        value={this.state.data.description ? this.state.data.description.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                    </div>
                    {/* Address */}
                    <div className="InputDiv">
                      <Components.InputBottomLine type="googleAddress" name="address" placeholder="" validation="text" required="true"
                        title="Address" maximum={100} addressSelected={this.googleAddressSelected}
                        value={this.state.data.address ? this.state.data.address.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                    </div>
                    {/* Website */}
                    <div className="InputDiv">
                      <Components.InputBottomLine type="text" name="website" validation="text" required="false" maximum={255} placeholder=""
                        title="Website"
                        value={this.state.data.website ? this.state.data.website.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                    </div>
                    {/* Photo */}
                    <div className="InputDiv">
                      <Components.InputBottomLine type="file" name="photo" placeholder="" validation="file" required="false"
                        title="Photo" description=""
                        APIName="userFiles" APIUploadName="userFiles" showPopup={this.props.showPopup} maxSize={1920}
                        value={this.state.data.photo ? this.state.data.photo.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                    </div>
                  </div>
                  <div className="WebScrapingsContentReviewRight">
                    <iframe src={this.state.review.url} title="Event">
                    </iframe>
                  </div>
                </div>
                <div className="WebScrapingsContentActions WebScrapingsContentActionsCentered">
                  <Components.GeojiButton type="Theme" onClick={this.submitForm} shake={this.state.shakeButton}>
                    { this.state.review.duplicateScrapeEventID &&
                      <span>Save Changes</span>
                    }
                    { !this.state.review.duplicateScrapeEventID &&
                      <span>Create Geoji</span>
                    }
                  </Components.GeojiButton>
                  <Components.GeojiButton type="Delete" onClick={this.rejectEvent}>
                    { this.state.review.duplicateScrapeEventID &&
                      <span>Reject Changes</span>
                    }
                    { !this.state.review.duplicateScrapeEventID &&
                      <span>Reject Event</span>
                    }
                  </Components.GeojiButton>
                </div>
                { this.state.review.possibleDuplicateID &&
                  <div className="WebScrapingNote WebScrapingNoteAlt">
                    This event is a possible duplicate. Check the map to make sure nothing like it exists.
                  </div>
                }
              </div>
            }
            { !this.state.loading && !this.state.review &&
              <div className="WebScrapingsContent">
                <div className="WebScrapingsContentError">
                  No more events to review!
                </div>
              </div>
            }
          </div>
        }
        { this.state.view === "MassReview" &&
          <div className="FormBlock">
            <div className="WebScrapingsTitle">
              <Components.DualImage image={ImageClose} onClick={this.exitReview} />
              Mass Review
            </div>
            { this.state.loading &&
              <div className="FormLoader" style={{height:"168px"}}>
                <Components.LoadingIndicator color="dark" />
              </div>
            }
            { !this.state.loading && this.state.reviews && this.state.reviews.length > 0 &&
              <div className="WebScrapingsContent">
                <div className="WebScrapingsContentMassReviews">
                  <div className="WebScrapingsContentMassReviewsLeft">
                    {/* Toggle All */}
                    <div className="WebScrapingsContentMassReviewsAll">
                      <Components.GeojiButton type="Dark" size="Small" onClick={this.toggleAll} shake={this.state.shakeButton}>
                        Toggle All
                      </Components.GeojiButton>
                    </div>
                    { this.state.reviews.map((review, i) => (
                      <div className={"WebScrapingsContentMassReview " + (review.accept ? "WebScrapingsContentMassReviewAccepted" : "")} key={"massReview_" + review.id}>
                        {/*<div className="WebScrapingsContentMassReviewCheck" onClick={this.toggleMassReviewEvent.bind(this, review.id)}>
                          <Components.DualImage image={review.accept ? ImageCheckmark : ImageUncheckmark} />
                        </div>*/}
                        <div className="WebScrapingsContentMassReviewData" onClick={this.toggleMassReviewEvent.bind(this, review.id)}>
                          <img className="WebScrapingsContentMassReviewDataImage" src={review.photoURL ? review.photoURL : ""} alt="event" />
                          <div className="WebScrapingsContentMassReviewDataText">
                            <div className="WebScrapingsContentMassReviewDataTextLeft" onClick={this.focusMassEvent.bind(this, review.id)}>
                              <div className="WebScrapingsContentMassReviewDataTextLeftEmoji">
                                {review.emoji}
                              </div>
                              <Components.DualImage image={ImageMagnifyingGlass} />
                            </div>
                            <div className="WebScrapingsContentMassReviewDataTextRight">
                              <div className="WebScrapingsContentMassReviewDataTitle">
                                {review.title}
                              </div>
                              {/*<div className="WebScrapingsContentMassReviewDataWhen">
                                {review.startDate === review.endDate ? review.startDate + " from " + review.timeText : review.startDate + " to " + review.endDate}
                              </div>*/}
                              <div className="WebScrapingsContentMassReviewDataWhere">
                                {review.data.venue ? review.data.venue : (review.address ? review.address : "")}
                              </div>
                              <div className="WebScrapingsContentMassReviewDataPrice">
                                {review.data.ticketsDisplay ? review.data.ticketsDisplay : "Unknown Price"}
                              </div>
                              <div className="WebScrapingsContentMassReviewDataSummary">
                                {review.callToAction ? review.callToAction : (review.description ? review.description : "Unknown")}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="WebScrapingsContentMassReviewsRight">
                    { this.state.focusedReview &&
                      <div className="WebScrapingsContentMassReviewsRightFocused">
                        <iframe src={this.state.focusedReview.url} title="Event">
                        </iframe>
                      </div>
                    }
                  </div>
                </div>
                <div className="WebScrapingsContentActions WebScrapingsContentActionsCentered">
                  <Components.GeojiButton type="Theme" onClick={this.submitMassReview} shake={this.state.shakeButton}>
                    Next Batch
                  </Components.GeojiButton>
                </div>
              </div>
            }
            { !this.state.loading && this.state.reviews && this.state.reviews.length === 0 &&
              <div className="WebScrapingsContent">
                <div className="WebScrapingsContentError">
                  No more events to review!
                </div>
              </div>
            }
          </div>
        }
        { this.state.view === "ViewCreated" &&
          <div className="FormBlock">
            <div className="WebScrapingsTitle">
              <Components.DualImage image={ImageClose} onClick={this.exitReview} />
              Created Events
            </div>
            { this.state.loading &&
              <div className="FormLoader" style={{height:"168px"}}>
                <Components.LoadingIndicator color="dark" />
              </div>
            }
            { !this.state.loading && this.state.createdEvents && this.state.createdEvents.length > 0 &&
              <div className="WebScrapingsContent">
                <div className="WebScrapingsContentMassReviews">
                  <div className="WebScrapingsContentMassReviewsLeft">
                    { this.state.createdEvents.map((event, i) => (
                      <div className={"WebScrapingsContentMassReview"} key={"createdEvent_" + event.id}>
                        <div className="WebScrapingsContentMassReviewData" onClick={this.clickedCreatedEvent.bind(this, event.geojiID)}>
                          <img className="WebScrapingsContentMassReviewDataImage" src={event.photoURL ? event.photoURL : ""} alt="event" />
                          <div className="WebScrapingsContentMassReviewDataText">
                            <div className="WebScrapingsContentMassReviewDataTextLeft" onClick={this.focusMassEvent.bind(this, event.id)}>
                              <div className="WebScrapingsContentMassReviewDataTextLeftEmoji">
                                {event.emoji}
                              </div>
                              <Components.DualImage image={ImageMagnifyingGlass} />
                            </div>
                            <div className="WebScrapingsContentMassReviewDataTextRight">
                              <div className="WebScrapingsContentMassReviewDataTitle">
                                {event.title}
                              </div>
                              {/*<div className="WebScrapingsContentMassReviewDataWhen">
                                {event.startDate === event.endDate ? event.startDate + " from " + event.timeText : event.startDate + " to " + event.endDate}
                              </div>*/}
                              <div className="WebScrapingsContentMassReviewDataWhere">
                                {event.data.venue ? event.data.venue : (event.address ? event.address : "")}
                              </div>
                              <div className="WebScrapingsContentMassReviewDataPrice">
                                {event.data.ticketsDisplay ? event.data.ticketsDisplay : "Unknown Price"}
                              </div>
                              <div className="WebScrapingsContentMassReviewDataSummary">
                                {event.callToAction ? event.callToAction : (event.description ? event.description : "Unknown")}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="WebScrapingsContentMassReviewsRight">
                    { this.state.focusedReview &&
                      <div className="WebScrapingsContentMassReviewsRightFocused">
                        <iframe src={this.state.focusedReview.url} title="Event">
                        </iframe>
                      </div>
                    }
                  </div>
                </div>
              </div>
            }
            { !this.state.loading && this.state.createdEvents && this.state.createdEvents.length === 0 &&
              <div className="WebScrapingsContent">
                <div className="WebScrapingsContentError">
                  No created events!
                </div>
              </div>
            }
          </div>
        }
      </div>
    )
  }
}
