import React from 'react';
import autoBind from 'react-autobind';

import * as Components from '../Components.js';
//import * as Generation from '../Generation.js';
import * as Helpers from '../Helpers.js';

//import {DateObject} from "react-multi-date-picker";


//import the Darwin API classes
import API from '../API.js';

//import ImageCheckmark from '../images/Icons/Checkmark.svg'
//import ImageUncheckmark from '../images/Icons/Uncheckmark.svg'
// import ImageTable from '../images/Icons/Table.svg';
import ImageXButton from '../images/Icons/XButton.svg'

export class Payouts extends React.Component {

  constructor(props) {
    super(props)

    let data = {}

    this.state = {
      loading: false,
      data: data,
      forceCheck: false,
      shakeButton: false,
      changesMade: false, //have any changes been made

      view: "Home",
      subview: "Home",
      prompt: false,

      payouts: [],
      filter: "All", //the filter to apply
      sort: "Name", //the sort to apply
      sortDirection: "ASC", //DESC or ASC
      limit: 10, //the number of results per page.
      page: 0, //the page of results.
      pageMax: 0, //the max page.
      search: "", //the search parameter.
      resultsCount: 0, //the number of results.
      
      uploadPeople: [], //the csv file of uploads.
    }
    autoBind(this)

    this.csvFilePicker = React.createRef();
  }

  componentDidMount() {
    //calculate the view
    this.calculateView()
  }

  componentDidUpdate(prevProps) {
    if (this.props.render !== prevProps.render) {
      //reload the home view.
      this.calculateView()
    }
  }

  calculateView() {
    //set the data
    if (this.state.view === "Home") {
      //load the abilities for this user.
      this.getPayouts()
    }
  }

  /*
  * Submits the form
  */
  submitForm() {
    let results = this.checkForm(true)
    if (results.valid) {
      switch (this.state.view) {
        case "Home":
          this.changeSearch(results.data)
          break;
        default:
          console.error("Unhandled submitForm", this.state.view)
          break;
      }
    } else {
      console.log("Form not valid to submit")
      this.setState({
        forceCheck: true
      })
      //Shake the submit button as it is invalid to submit
      this.shakeTheButton()
    }
  }

  /*
  Checks the form to make sure it is valid.
  Returns {valid:Bool, data:{}}
  */
  checkForm(print = false) {
    //1) Make sure we have all of the data.
    let valid = true
    let requiredFields = []
    let requiredIf = []
    let requiredSometimes = [] //if set, then don't remove it from the data.
    let optionals = [] //the optional fields apart from the requiredFields.
    switch (this.state.view) {
      case "Home":
        requiredFields = []
        optionals = ["search"]
        requiredIf = []
        requiredSometimes = []
        break;
      default:
        break;
    }
    requiredFields.forEach((element) => {
      if (this.state.data[element] === undefined || this.state.data[element].value === undefined) {
        //the field is not filled out
        if (print) {
          console.log("required not filled out: ", element)
        }
        valid = false
      }
    })
    requiredIf.forEach((condition) => {
      if (this.state.data[condition.field] === undefined || this.state.data[condition.field].value === undefined) {
        //the condition field is not filled out
        if (print) {
          console.log("condition field is not filled out: ", condition)
        }
        valid = false
      } else if (condition.values !== undefined && condition.values.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          if (print) {
            console.log("rif field is required and is not filled out: ", condition)
          }
          valid = false
        }
      } else if (condition.not !== undefined && !condition.not.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          if (print) {
            console.log("rif field is required with not and is not filled out: ", condition)
          }
          valid = false
        }
      }
    })
    //2) Make sure every data element is valid.
    for (let key in this.state.data) {
      let forget = false
      //Make sure it is not a requiredSometimes
      if (!requiredSometimes.includes(key)) {
        // Check the requiredIf conditions
        for (let i = 0; i < requiredIf.length; i = i + 1) {
          let rif = requiredIf[i]
          if (rif.require === key) {
            if (!rif.values.includes(this.state.data[rif.field].value)) {
              if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                forget = true
              }
            }
            break
          }
        }
      }
      //Make sure this is a field we are looking for.
      if (optionals.includes(key) || requiredFields.includes(key)) {
        if (!forget && this.state.data[key].valid === false) {
          if (print) {
            console.log("data not valid", key)
          }
          valid = false
        }
      }
    }

    //3) If valid, return the data, else force check the fields for display.
    if (valid) {
      let data = {}
      for (let key in this.state.data) {
        let forget = false
        //Make sure it is not a requiredSometimes
        if (!requiredSometimes.includes(key)) {
          for (let i = 0; i < requiredIf.length; i = i + 1) {
            let rif = requiredIf[i]
            if (rif.require === key) {
              if (!rif.values.includes(this.state.data[rif.field].value)) {
                if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                  forget = true
                }
              }
              break
            }
          }
        }
        if (!forget) {
          if (optionals.includes(key) || requiredFields.includes(key)) {
            if (this.state.data[key].value !== false && this.state.data[key].value.toString().length > 0) {
              data[key] = this.state.data[key].value
              if (typeof data[key] === 'string') {
                data[key] = data[key].trim()
              }
            }
          }
        }
      }
      return {
        valid: true,
        data: data
      }
    } else {
      return {
        valid: false,
        data: "Form not valid to submit"
      }
    }
  }

  /*
  * Called when data in an input form changes.
  * This will update the state.data param with the
  * name and new value of the form element.
  */
  formChanged(name, value, valid) {
    //console.log(name, value, valid);
    this.setState((prevState) => {
      let d = prevState.data
      d[name] = {
        value: value,
        valid: valid
      }
      if (prevState.view === "Create" && name === "type") {
        let defaults = Helpers.getScrapingConfigs(value)
        //select the first default.
        let config = defaults[0]
        for (let key in config) {
          d[key] = {
            value: config[key],
            valid: true
          }
        }
      } else if (prevState.view === "Create" && name === "config") {
        //get the config
        let defaults = Helpers.getScrapingConfigs(d["type"]["value"])
        let config = false
        for (let i = 0; i < defaults.length; i = i + 1) {
          if (defaults[i]["config"] === value) {
            config = defaults[i]
            break
          }
        }
        if (config !== false) {
          for (let key in config) {
            d[key] = {
              value: config[key],
              valid: true
            }
          }
        }
      }
      return {
        data: d,
        forceCheck: false,
        changesMade: true,
      }
    }, () => {
      //check to see if the form is valid to submit
      let res = this.checkForm()
      this.setState({
        valid: res.valid
      })
    })
  }

  /*
  Shakes the button and then removes the class.
  */
  shakeTheButton() {
    this.setState({
      shakeButton: true
    }, () => {
      setTimeout(() => {
        this.setState({
          shakeButton: false
        })
      }, 1000)
    })
  }

  /*
  We have selected a scraping.
  */
  payoutSelected(userID, clickType, event) {
    console.log(userID, clickType, event)
    if (clickType === "rightclick") {
      //open the user in a new tab
      event.preventDefault()
      console.log(userID, clickType, event)
      window.open("/user/" + userID, "_blank", "noreferrer")
    } else {
      //find the payout
      let payout = {}
      for (let i = 0; i < this.state.payouts.length; i = i + 1) {
        if (this.state.payouts[i].id === userID) {
          //found it
          payout = this.state.payouts[i]
        }
      }
      this.props.showConfirmation("Payout User", "Are you sure you want to payout - '" + payout.name + "' for $" + Helpers.formatNumberDecimals(payout.amountCents), "Payout", "Cancel", (pay) => {
        if (!pay) {
          return
        }
        //Call the API to payout this person.
        this.setState({
          loading: true
        }, () => {
          let data = {
          }
          API.callDarwinAPI("POST", "payout/" + payout.id, data, async (result) => {
            console.log("payout/" + payout.id, result)
            if ("error" in result) {
              //Change the view to 404
              this.props.showPopup("Error", "Couldn't payout this user", result.error)
              this.setState({
                loading: false
              })
              return
            }
            //now get the user information and refresh.
            this.getPayouts()
          })
        })
      })
    }
  }

  /*
  Changes the filter that is applied to the requests.
  */
  changeFilter(newFilter) {
    this.setState({
      filter: newFilter,
      page: 0,
    }, () => {
      this.getPayouts()
    })
  }

  /*
  Changes the page of results we are viewing.
  */
  changePage(newPage) {
    this.setState({
      page: newPage
    }, () => {
      this.getPayouts()
    })
  }

  /*
  Changes the sort that is applied to the requests.
  */
  changeSort(newSort) {
    if (!["Name", "Phone Number"].includes(newSort)) {
      console.log("No Sort for ", newSort)
      return
    }
    this.setState((oldState) => {
      let newSortDirection = "DESC"
      if (oldState.sort === newSort) {
        //switch the direction
        newSortDirection = oldState.sortDirection
        if (newSortDirection === "DESC") {
          newSortDirection = "ASC"
        } else {
          newSortDirection = "DESC"
        }
      }
      return {
        sort: newSort,
        sortDirection: newSortDirection,
        page: 0
      }
    }, () => {
      this.getPayouts()
    })
  }

  /*
  Changes the search that is applied to the requests.
  */
  changeSearch(data) {
    console.log(data)
    this.setState(() => {
      return {
        search: data.search || "",
        page: 0
      }
    }, () => {
      this.getPayouts()
    })
  }

  /*
  Get the list of users we have sent.
  */
  getPayouts() {
    this.setState({
      loading: true
    }, () => {

      let data = {
        filter: this.state.filter,
        sort: this.state.sort,
        sortDirection: this.state.sortDirection,
        limit: this.state.limit,
        page: this.state.page,
      }
      if (this.state.search.length > 0) {
        data.search = this.state.search
      }
      console.log("search data", data)

      API.callDarwinAPI("GET", "payouts", data, async (result) => {
        console.log("payouts", result)
        if ("error" in result) {
          //Change the view to 404
          this.props.showPopup("Error", "Couldn't get the payouts", "Please try again. If this continues to occur, reach out to support@geoji.com")
          this.setState({
            loading: false
          })
          return
        }
        let pageMax = Math.ceil((result.data.totalCount * 1.0) / this.state.limit) - 1

        //format the users
        let uu = result.data.payouts
        for (let i = 0; i < uu.length; i = i + 1) {
          uu[i].name = uu[i].name && uu[i].name.length > 0 ? uu[i].name : "-"
          uu[i].phoneNumberParsed = uu[i].phoneNumber ? Helpers.parsePhoneNumber(uu[i].phoneNumber) : "-"
          uu[i].amountCents = uu[i].balances[1].total / 100.0
        }
        this.setState({
          loading: false,
          payouts: uu,
          pageMax: pageMax,
          resultsCount: result.data.totalCount,
        })
      })
    })
  }

  /*
  Changes the toggle for one of the filters and the re-searches for users.
  */
  onToggle(key) {
    this.setState((old) => {
      let obj = {}
      obj[key] = !old[key]
      console.log("obj", obj)
      if (key === "positiveBalance") {
        if (obj[key] === false) {
          //revert sort to the name field.
          obj.sort = "Name"
          obj.sortDirection = "ASC"
        } else {
          obj.sort = "Balance"
          obj.sortDirection = "DESC"
        }
      }
      return obj
    }, () => {
      this.getPayouts()
    })
  }

  /*
  A csv file was selected for payouts. Now parse its data.
  */
  csvFileSelected() {
    console.log("csv file selected", this.csvFilePicker.current)

    if (this.csvFilePicker.current.files.length === 0) {
      //no file selected
      return
    }
    let file = this.csvFilePicker.current.files[0]

    let reader = new FileReader();
		reader.addEventListener('load', (e) => {
      let text = e.target.result;
      //console.log(text)

      //check the format.
      var lines = text.split(/\r\n|\n/);
      var columns = lines[0].split(',');

      console.log("columns", columns)
      if (columns.length < 4) {
        this.showError("Invalid Format E.1", "Please use the format in the downloaded CSV template. You should have at least 4 columns of data with: 'First & Last Name', 'Phone Number', 'Balance Adjustment', & 'Note'")
        return
      }
      if (!columns.includes("First & Last Name") || !columns.includes("Phone Number") || !columns.includes("Balance Adjustment") || !columns.includes("Note")) {
        this.showError("Invalid Format E.2", "Please use the format in the downloaded CSV template. You should have at least 4 columns of data with: 'First & Last Name', 'Phone Number', 'Balance Adjustment', & 'Note'")
        return
      }
      //now get the index for these items
      let nameIndex = columns.indexOf("First & Last Name");
      let phoneIndex = columns.indexOf("Phone Number");
      let balanceIndex = columns.indexOf("Balance Adjustment");
      let noteIndex = columns.indexOf("Note");

      //now parse the data into people.
      let people = []
      for (let i = 1; i < lines.length; i = i + 1) {
        let parts = lines[i].split(',')
        //How to detect if it is a comma or an escaped comma!
        if (parts.length < columns.length) {
          if (parts.length === 1) {
            //empty line - skip
            continue;
          }
          //invalid format.
          console.log(parts)
          this.showError("Invalid Format", "This CSV file contains commas in the data. Please remove all commas from your data. For instance: replace '1,234' with '1234'.")
          return
        }

        //now grab the data properly
        let name = parts[nameIndex].trim()
        let phone = parts[phoneIndex].trim().replace(/\D/g, '');
        if (phone.length === 10) {
          phone = "1" + phone
        }
        let balance = parseFloat(parts[balanceIndex].trim().replace(/[$,]/g, ''));
        let note = parts[noteIndex].trim()

        if (name.length === 0 || phone.length === 0) {
          //empty name - skip this row.
          continue;
        }

        people.push({
          name: name,
          phoneNumber: phone,
          balance: balance,
          note: note,
          disabled: 0,
        })
      }

      console.log(people)
      //update the onscreen data.
      this.setState({
        uploadPeople: people,
        subview: "Upload",
        loading: true,
      }, () => {
        //Call the API to get information about these people and if they have user accounts.
        let data = {
          people: JSON.stringify(people),
        }
        API.callDarwinAPI("POST", "payoutInfo", data, async (result) => {
          console.log("payoutInfo", result)
          if ("error" in result) {
            //Change the view to 404
            this.props.showPopup("Error", "Couldn't get the payout info", "Please try again. If this continues to occur, reach out to support@geoji.com")
            this.setState({
              loading: false
            })
            return
          }
          //Now merge this information with our uploadPeople
          for (let i = 0; i < result.data.people.length; i = i + 1) {
            for (let j = 0; j < people.length; j = j + 1) {
              if (people[j].phoneNumber === result.data.people[i].phoneNumber) {
                //found a match
                people[j].id = result.data.people[i].id
                people[j].remoteName = result.data.people[i].remoteName
                people[j].issue = result.data.people[i].issue
                if (people[j].issue === "not found" || people[j].issue === "zero balance") {
                  people[j].disabled = 1
                }
                break;
              }
            }
          }
          this.setState({
            loading: false,
            uploadPeople: people,
          })
        })
      })
		})
		reader.readAsText(file);
  }

  /*
  Cancels upload a file for payouts.
  */
  cancelUpload() {
    this.setState({
      uploadPeople: [],
      subview: "Home",
    })
  }

  /*
  Actually upload the payouts
  */
  uploadPayouts() {
    this.props.showConfirmation("Submit Payouts", "Create the balance adjustments for these users and request payouts for each user.", "Request Payouts", "Cancel", (submit) => {
      if (!submit) {
        return
      }
      //actually upload the payouts.
      this.setState({
        loading: true
      }, () => {
        let data = {
          people: JSON.stringify(this.state.uploadPeople),
        }
        API.callDarwinAPI("POST", "payouts", data, async (result) => {
          console.log("payouts", result)
          if ("error" in result) {
            //Change the view to 404
            this.props.showPopup("Error", "Couldn't submit the payouts", "Please try again. If this continues to occur, reach out to support@geoji.com")
            this.setState({
              loading: false
            })
            return
          }
          //Now merge this information with our uploadPeople
          this.setState({
            loading: false,
            subview: "Home",
            uploadPeople: [],
          }, () => {
            this.getPayouts()
          })
        })
      })
    })
  }

  /*
  Pays out multiple people.
  */
  payoutMultiple(data) {
    console.log("Payout Multiple", data)
    this.props.showConfirmation("Payout " + data.length + " People", "Are you sure you want to payout these people?", "Payout", "Cancel", (submit) => {
      if (!submit) {
        return
      }
      //actually submit the payouts in a loop.
      this.setState({
        loading: true
      }, () => {
        for (let d = 0; d < data.length; d = d + 1) {
          let userID = data[d]
          //find the payout
          let payout = {}
          for (let i = 0; i < this.state.payouts.length; i = i + 1) {
            if (this.state.payouts[i].id === userID) {
              //found it
              payout = this.state.payouts[i]
            }
          }
          //Call the API to payout this person.
          API.callDarwinAPI("POST", "payout/" + payout.id, {}, async (result) => {
            console.log("payout/" + payout.id, result)
            if ("error" in result) {
              //Change the view to 404
              this.props.showPopup("Error", "Couldn't payout this user", result.error)
              this.setState({
                loading: false
              }, () => {
                this.getPayouts()
              })
              return
            }
            if (d === data.length - 1) {
              //refresh the page
              setTimeout(() => {
                this.getPayouts()
              }, 2000)
            }
          })
        }
      })
    })
  }

  disablePayout(person) {
    this.setState((old) => {
      let newPeople = Object.assign([], old.uploadPeople)
      for (let i = 0; i < newPeople.length; i = i + 1) {
        if (newPeople[i].phoneNumber === person.phoneNumber) {
          if (newPeople[i].issue !== "not found" && newPeople[i].issue !== "zero balance") {
            newPeople[i].disabled = (newPeople[i].disabled + 1) % 2
          }
          break;
        }
      }

      return {
        uploadPeople: newPeople
      }
    })
  }

  render() {

    return (
      <div className="WebScrapings">
        {/* Manage Users */}
        { this.state.view === "Home" && this.props.userInfo && this.props.userInfo.user &&
          <div className="FormBlock">
            <div className="WebScrapingsTitle">
              Payouts
            </div>
            { this.state.loading &&
              <div className="FormLoader" style={{height:"168px"}}>
                <Components.LoadingIndicator color="dark" />
              </div>
            }
            { !this.state.loading && this.state.subview === "Home" &&
              <div className="WebScrapingsContent">
                <div className="ButtonsDiv">
                  {/* Template CSV File */}
                  <a href="https://galaxy.darwincloud.com/Apps/fs/19/Resources/Templates/GeojiPayoutsTemplate.csv" target="_blank" rel="noreferrer noopener" download>
                    <Components.GeojiButton type="Outline" style={{"margin": "0px 12px"}}>
                      Download Template
                    </Components.GeojiButton>
                  </a>
                  {/* Upload CSV File */}
                  <div className="FileSelectorDiv">
                    <input id={"FileSelectorInputCSV"} className="FileSelectorUploadInput" type="file" ref={this.csvFilePicker} onChange={this.csvFileSelected.bind(this)} accept={"text/csv"} />
                    <label htmlFor="FileSelectorInputCSV" className="FileSelectorUploadInputLabel">
                      <Components.GeojiButton type="Dark" style={{"margin": "0px 12px"}}>
                        Upload Payouts
                      </Components.GeojiButton>
                    </label>
                  </div>
                </div>
                <div className="WebScrapingsFilters">
                  {/*<Components.SettingsToggle name="Requested Payout" value={this.state.requestedPayout === true} onToggle={this.onToggle.bind(this, "requestedPayout")} />
                  <Components.SettingsToggle name="Only Creators" value={this.state.onlyCreators === true} onToggle={this.onToggle.bind(this, "onlyCreators")} />
                  <Components.SettingsToggle name="Positive Balance" value={this.state.positiveBalance === true} onToggle={this.onToggle.bind(this, "positiveBalance")} />
                  <Components.SettingsToggle name="Contacts" value={this.state.contacts === true} onToggle={this.onToggle.bind(this, "contacts")} />*/}
                </div>
                <Components.Table
                  multi={true}
                  multiActions={[{"name":"Payout All Selected", "action":this.payoutMultiple}]}
                  tableInfo={Helpers.getManagePayoutsTableInfo(this.state.payouts)}
                  noData={Helpers.getText("emptyPayouts")}
                  data={this.state.payouts}
                  onClick={this.payoutSelected}
                  onClickKey={"id"}
                  onHeaderClick={this.changeSort}
                  sort={this.state.sort}
                  sortDirection={this.state.sortDirection}
                  >
                  <div className="TableTop">
                    <div className="TableTopDate">
                      <Components.PageIndicator page={this.state.page} pageMax={this.state.pageMax} pageSelected={this.changePage} count={this.state.resultsCount} />
                    </div>
                  </div>
                </Components.Table>
              </div>
            }
            { !this.state.loading && this.state.subview === "Upload" &&
              <div className="WebScrapingsContent">
                <div className="UploadPeopleTitle">
                  <img alt="Cancel" src={ImageXButton} onClick={this.cancelUpload} />
                  Payout Users
                </div>
                <div className="UploadPeople">
                  { this.state.uploadPeople.map((person, i) => (
                    <div key={"person"+i} className={"UploadPerson" + (person.disabled === 1 ? " UploadPersonDisabled" : "")} onClick={this.disablePayout.bind(this, person)}>
                      <div className="UploadPersonName">
                        {person.name}
                      </div>
                      <div className="UploadPersonPhoneNumber">
                        {Helpers.parsePhoneNumber(person.phoneNumber)}
                      </div>
                      <div className="UploadPersonBalance">
                        {"$" + Helpers.formatNumberDecimals(person.balance)}
                      </div>
                      <div className="UploadPersonNote">
                        {person.note}
                      </div>
                      { person.issue === "not found" &&
                        <div className="UploadPersonIssue UploadPersonIssueNotFound">
                          No Geoji Account Found
                        </div>
                      }
                      { person.issue === "zero balance" &&
                        <div className="UploadPersonIssue">
                          No balance
                        </div>
                      }
                      { person.issue === "name mismatch" &&
                        <div className="UploadPersonIssue">
                          Name Mismatch
                          <br/>
                          {person.remoteName}
                        </div>
                      }
                    </div>
                  ))}
                </div>
                <Components.GeojiButton type="Theme" onClick={this.uploadPayouts} style={{margin: "12px"}}>
                  Request Payouts
                </Components.GeojiButton>
              </div>
            }
          </div>
        }
      </div>
    )
  }
}