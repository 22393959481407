import React from 'react';
import autoBind from 'react-autobind';

import * as Components from '../Components.js';
//import * as Generation from '../Generation.js';
// import * as Helpers from '../Helpers.js';

//import {DateObject} from "react-multi-date-picker";


//import the Darwin API classes
import API from '../API.js';

import ImageClose from '../images/Icons/Close.svg'
import ImageTableBlack from '../images/Icons/TableBlack.svg';
//import ImageCheckmark from '../images/Icons/Checkmark.svg'
//import ImageUncheckmark from '../images/Icons/Uncheckmark.svg'

export class InstagramScraper extends React.Component {

  constructor(props) {
    super(props)

    let data = {
    }

    this.state = {
      loading: false,
      data: data,
      forceCheck: false,
      shakeButton: false,
      changesMade: false, //have any changes been made

      view: "Create",
      subview: "Home",
      prompt: false,

      resultsLink: "",
    }
    autoBind(this)

    this.csvFilePicker = React.createRef();
  }

  componentDidMount() {
    //calculate the view
    this.calculateView()
  }

  componentDidUpdate(prevProps) {
    if (this.props.render !== prevProps.render) {
      //reload the home view.
      this.calculateView()
    }
  }

  calculateView() {
    //set the data
  }

  /*
  * Create the new Geoji.
  */
  scrapeInstas(data) {

    //Make sure the data in csvHandles is set
    if (!this.state.csvHandles) {
      this.props.showPopup("Error", "CSV File Not Set", "Please pick the csv file and try again.")
      return
    }

    this.setState({
      loading: true
    }, () => {
      let data = {
        handles: JSON.stringify(this.state.csvHandles),
      }
      API.callDarwinAPI("POST", "scrapeInstagram", data, async (result) => {
        console.log("POST scrapeInstagram", result)
        if ("error" in result) {
          //Change the view to 404
          this.props.showPopup("Error", "Couldn't scrape the Instagram Handles", "Please try again. If this continues to occur, reach out to support@geoji.com")
          this.setState({
            loading: false
          })
          return
        }
        this.setState({
          loading: false,
          view: "Created",
          resultsLink: result.data.link
        })
      })
    })
  }

  /*
  * Submits the form
  */
  submitForm() {
    let results = this.checkForm(true)
    if (results.valid) {
      switch (this.state.view) {
        case "Create":
          this.scrapeInstas(results.data)
          break;
        default:
          console.error("Unhandled submitForm", this.state.view)
          break;
      }
    } else {
      console.log("Form not valid to submit")
      this.setState({
        forceCheck: true
      })
      //Shake the submit button as it is invalid to submit
      this.shakeTheButton()
    }
  }

  /*
  Checks the form to make sure it is valid.
  Returns {valid:Bool, data:{}}
  */
  checkForm(print = false) {
    //1) Make sure we have all of the data.
    let valid = true
    let requiredFields = []
    let requiredIf = []
    let requiredSometimes = [] //if set, then don't remove it from the data.
    let optionals = [] //the optional fields apart from the requiredFields.
    switch (this.state.view) {
      case "Create":
        requiredFields = []
        optionals = []
        requiredIf = []
        requiredSometimes = []
        break;
      default:
        break;
    }
    requiredFields.forEach((element) => {
      if (this.state.data[element] === undefined || this.state.data[element].value === undefined) {
        //the field is not filled out
        if (print) {
          console.log("required not filled out: ", element)
        }
        valid = false
      }
    })
    requiredIf.forEach((condition) => {
      if (this.state.data[condition.field] === undefined || this.state.data[condition.field].value === undefined) {
        //the condition field is not filled out
        if (print) {
          console.log("condition field is not filled out: ", condition)
        }
        valid = false
      } else if (condition.values !== undefined && condition.values.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          if (print) {
            console.log("rif field is required and is not filled out: ", condition)
          }
          valid = false
        }
      } else if (condition.not !== undefined && !condition.not.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          if (print) {
            console.log("rif field is required with not and is not filled out: ", condition)
          }
          valid = false
        }
      }
    })
    //2) Make sure every data element is valid.
    for (let key in this.state.data) {
      let forget = false
      //Make sure it is not a requiredSometimes
      if (!requiredSometimes.includes(key)) {
        // Check the requiredIf conditions
        for (let i = 0; i < requiredIf.length; i = i + 1) {
          let rif = requiredIf[i]
          if (rif.require === key) {
            if (!rif.values.includes(this.state.data[rif.field].value)) {
              if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                forget = true
              }
            }
            break
          }
        }
      }
      //Make sure this is a field we are looking for.
      if (optionals.includes(key) || requiredFields.includes(key)) {
        if (!forget && this.state.data[key].valid === false) {
          if (print) {
            console.log("data not valid", key)
          }
          valid = false
        }
      }
    }

    //3) If valid, return the data, else force check the fields for display.
    if (valid) {
      let data = {}
      for (let key in this.state.data) {
        let forget = false
        //Make sure it is not a requiredSometimes
        if (!requiredSometimes.includes(key)) {
          for (let i = 0; i < requiredIf.length; i = i + 1) {
            let rif = requiredIf[i]
            if (rif.require === key) {
              if (!rif.values.includes(this.state.data[rif.field].value)) {
                if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                  forget = true
                }
              }
              break
            }
          }
        }
        if (!forget) {
          if (optionals.includes(key) || requiredFields.includes(key)) {
            if (this.state.data[key].value !== false && this.state.data[key].value.toString().length > 0) {
              data[key] = this.state.data[key].value
              if (typeof data[key] === 'string') {
                data[key] = data[key].trim()
              }
            }
          }
        }
      }
      return {
        valid: true,
        data: data
      }
    } else {
      return {
        valid: false,
        data: "Form not valid to submit"
      }
    }
  }

  /*
  * Called when data in an input form changes.
  * This will update the state.data param with the
  * name and new value of the form element.
  */
  formChanged(name, value, valid) {
    //console.log(name, value, valid);
    this.setState((prevState) => {
      let d = prevState.data
      d[name] = {
        value: value,
        valid: valid
      }
      return {
        data: d,
        forceCheck: false,
        changesMade: true,
      }
    }, () => {
      //check to see if the form is valid to submit
      let res = this.checkForm()
      this.setState({
        valid: res.valid
      })
    })
  }

  /*
  Shakes the button and then removes the class.
  */
  shakeTheButton() {
    this.setState({
      shakeButton: true
    }, () => {
      setTimeout(() => {
        this.setState({
          shakeButton: false
        })
      }, 1000)
    })
  }

  /*
  * Goes to the create view again.
  */
  createAnother() {
    this.setState({
      data: {
      },
      csvHandles: false,
      resultsLink: "",
      view: "Create"
    })
  }

  /*
  * Goes back to the home page.
  */
  showHome() {
    this.props.changeView("Home")
  }

  /**
   * Downloads the results.
   */
  downloadResults() {
    window.open(this.state.resultsLink, '_blank').focus();
  }

  /**
  A CSV file was selected for upload, parse the data and turn it into a list of people.
  */
  csvFileSelected() {
    console.log("csv file selected", this.csvFilePicker.current)

    if (this.csvFilePicker.current.files.length === 0) {
      //no file selected
      return
    }
    let file = this.csvFilePicker.current.files[0]
    //file has been selected
    //console.log("file selected", file)

    let reader = new FileReader();
		reader.addEventListener('load', (e) => {
      let text = e.target.result;
      //console.log(text)

      //check the format.
      var lines = text.split(/\r\n|\n/);
      var columns = lines[0].split(',');

      console.log("columns", columns)
      if (columns.length < 1) {
        //error - undefined columns - return an error.
        this.showError("Invalid Format", "Please use the format in the downloaded CSV template. You should have 4 columns of data.")
        return
      }

      //now parse the data into people.
      let people = []
      for (let i = 1; i < lines.length; i = i + 1) {
        let parts = lines[i].split(',')
        //How to detect if it is a comma or an escaped comma!
        /*if (parts.length !== 1) {
          if (parts.length === 1) {
            //empty line - skip
            continue;
          }
          //invalid format.
          console.log(parts)
          this.showError("Invalid Format", "This CSV file contains commas in the data. Please remove all commas from your data. For instance: replace '1,234' with '1234'.")
          return
        }*/

        let handle = parts[0].trim()

        if (handle.length === 0) {
          //empty name - skip this row.
          continue;
        }

        people.push({
          handle: handle,
        })
      }

      console.log(people)
      //update the onscreen data.
      this.setState({
        csvHandles: people,
      })
		})
		reader.readAsText(file);
  }

  render() {

    return (
      <div className="CreateGeoji">
        {/* Instagram Scraper View */}
        { this.state.view === "Create" &&
          <div className="FormBlock">
            <div className="CreateGeojiTitle">
              <Components.DualImage image={ImageClose} onClick={this.showHome} />
              Scrape Instagram
            </div>
            { this.state.loading &&
              <div className="FormLoader" style={{height:"168px"}}>
                <Components.LoadingIndicator color="dark" />
              </div>
            }
            { !this.state.loading &&
              <div className="CreateGeojiContent">
                {/* Upload CSV Data */}
                <div className="FileSelectorDiv">
                  <input id={"FileSelectorInputCSV"} className="FileSelectorUploadInput" type="file" ref={this.csvFilePicker} onChange={this.csvFileSelected.bind(this)} accept={"text/csv"} />
                  <label htmlFor="FileSelectorInputCSV" className="FileSelectorUploadInputLabel">
                    <div className="GeojiCnAdminSendFreeTokensMultipleButton GeojiCnAdminSendFreeTokensMultipleButtonBlack">
                      <img src={ImageTableBlack} alt="CSV" />
                      <div>Upload CSV</div>
                    </div>
                  </label>
                </div>

                {/* Submit Button */}
                <div className="ButtonDiv">
                  <Components.GeojiButton type="Theme2" onClick={this.submitForm} shake={this.state.shakeButton}>
                    Submit
                  </Components.GeojiButton>
                </div>
              </div>
            }
          </div>
        }
        {/* The Geoji has been created - show an interstitial screen. */}
        { this.state.view === "Created" &&
          <div className="CreatedGeoji">
            <div className="CreatedGeojiTitle">
              Scraped
            </div>
            <div className="CreatedGeojiDescription">
              <span role="img" aria-label="party">🎉</span> Download your results below. <span role="img" aria-label="party">🎉</span>
            </div>
            <div className="ButtonDiv">
              <Components.GeojiButton type="Theme" onClick={this.downloadResults}>
                Download Results
              </Components.GeojiButton>
            </div>
            <div className="ButtonDiv">
              <Components.GeojiButton type="Theme2" onClick={this.createAnother}>
                Scrape Another
              </Components.GeojiButton>
            </div>
          </div>
        }
      </div>
    )
  }
}
